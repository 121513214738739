import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import AppHeader from '../../Header/AppHeader';
import ToolCard from '../Landing/ToolCard';
import renderIcon from '../../../util/renderIcon';

function Tools() {
  const navigate = useNavigate();

  return (
    <>
      <AppHeader />
      <div className={styles.container}>
        <ToolCard
          title='AI Content Refresh'
          subtitle='Performance-optimise legacy content at any stage of your conversion funnel.'
          primaryButtonName='Start new project'
          icon={renderIcon('refreshFlow')}
          primaryAction={() => navigate('/content-refresh')}
        />
        <ToolCard
          title='AI Content Creation'
          subtitle='Create unique, ownable content that cuts through with the top of your conversion funnel.'
          primaryButtonName='Start new project'
          icon={renderIcon('creationFlow')}
          primaryAction={() => navigate('/content-creation')}
        />
        <ToolCard
          title='Product Descriptions'
          subtitle='Create high quality product descriptions that perform at scale.'
          primaryButtonName='Coming soon'
          icon={renderIcon('volumeFlow')}
          primaryAction={() => navigate('/')}
          disabled
        />
      </div>
    </>
  );
}

export default Tools;
