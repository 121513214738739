import React, { useCallback } from 'react';
import { Field } from 'formik';
import Flag from 'react-world-flags';
import { SettingsPageProps } from './interfaces';
import Card from '../../../core/components/Card/Card';
import styles from './styles.module.scss';
import languages from '../../../../assets/json/languages.json';
import RadioButtonGroup from '../../../core/components/RadioButtonGroup/RadioButtonGroup';
import renderIcon from '../../../../util/renderIcon';
import QuestionMark from '../../../core/components/QuestionMark/QuestionMark';
import { Option } from '../../../core/components/RadioButtonGroup/interfaces';
import Select from '../../../core/components/Select/Select';

const SettingsPage: React.FC<SettingsPageProps> = ({ form }) => {
  const textLengthOptions = [
    {
      id: 1,
      icon: renderIcon('shortLengthText'),
      label: 'Short',
      description: '500 - 750 words',
    },
    {
      id: 2,
      icon: renderIcon('mediumLengthText'),
      label: 'Medium',
      description: '750 - 1250 words',
    },
    {
      id: 3,
      icon: renderIcon('longLengthText'),
      label: 'Long',
      description: '1250+ words',
    },
  ];

  const toneOfVoiceOptions = [
    {
      value: 1,
      label: 'Informal',
    },
    {
      value: 2,
      label: 'Formal',
    },
  ];

  const languageButtonContent = useCallback((option: Option) => (
    <>
      <Flag
        code={option.country_code}
        height={12}
        style={{ borderRadius: '3px' }}
      />
      {option.name}
    </>
  ), []);

  const textLengthButtonContent = useCallback((option: Option) => (
    <div className={styles.radioButtonWrapper}>
      <div className={styles.radioButtonHeader}>
        {option.icon}
        {option.label}
      </div>
      <div className={styles.radioButtonContent}>
        {option.description}
      </div>
    </div>
  ), []);

  return (
    <Card
      title='Let’s get started'
      content={(
        <div className={styles.wrapper}>
          <div className={styles.title}>
            In what language do you want to generate your blog?
          </div>
          <div className={styles.content}>
            <Field
              name='language'
              component={RadioButtonGroup}
              options={languages}
              buttonContent={languageButtonContent}
            />
          </div>
          <div className={styles.title}>
            Which tone of voice do you want to use?
            <QuestionMark
              tooltipContent='Tone of Voice info text'
            />
          </div>
          <div className={styles.content}>
            <Field
              name='toneOfVoice'
              component={Select}
              selectLabel='Options'
              searchable={false}
              width='310px'
              options={toneOfVoiceOptions}
            />
          </div>
          <div className={styles.title}>
            What text length do you prefer?
            <QuestionMark
              tooltipContent='Text Length info text'
            />
          </div>
          <div className={styles.content}>
            <Field
              name='textLength'
              component={RadioButtonGroup}
              options={textLengthOptions}
              buttonContent={textLengthButtonContent}
            />
          </div>
        </div>
      )}
    />
  );
};

export default SettingsPage;
