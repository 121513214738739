import * as types from '../constants/documents';
import { client } from '../util/api';
import { checkUserAuthenticated } from './auth';

const backendUrl = process.env.REACT_APP_API_URL;

export function listFinishedDocuments({
  sort, limit, currentPage, tools, statuses, search, navigate,
}) {
  return (dispatch) => {
    let myUrl = `${backendUrl}/finished_documents?per_page=${limit}&sort_by=${sort}&page=${currentPage}`;

    // TODO: Array joinati sa zarezom kada BE podrzi multiple vrijednosti

    if (tools.length > 0) {
      myUrl += `&tool=${tools[0]}`;
    }

    if (statuses.length > 0) {
      myUrl += `&status=${statuses[0]}`;
    }

    if (search.length > 0) {
      myUrl += `&search=${search}`;
    }

    client.get(myUrl)
      .then(data => {
        if (!checkUserAuthenticated(data, navigate, dispatch)) {
          return;
        }

        dispatch({
          type: types.LIST_FINISHED_DOCUMENTS,
          payload: data.data.documents || [],
        });
      });
  };
}

export function deleteDocument(id, documentStatus) {
  return () => {
    let myUrl;
    if (documentStatus === 'finished') {
      myUrl = `${backendUrl}/finished_documents/${id}`;
    } else if (documentStatus === 'in-progress') {
      myUrl = `${backendUrl}/continue-flow/delete-current-flow/${id}`;
    }

    return client.delete(myUrl);
  };
}

export function readDocument(id) {
  return () => {
    const myUrl = `${backendUrl}/finished_documents/${id}`;

    return client.get(myUrl);
  };
}

export function editDocument(id, text) {
  return () => {
    const myUrl = `${backendUrl}/finished_documents/${id}`;

    return client.put(myUrl, {
      text,
    });
  };
}

export const updatePage = (page) => ({
  type: types.UPDATE_PAGE,
  payload: page,
});
