import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ALTERNATIVES_MODAL } from '../../constants/modal';
import Modal from '../core/components/Modal';
import RadioGroup from '../core/components/RadioGroup/RadioGroup';
import { RootState } from '../../reducers';
import { Button } from '../core/components/Button/button';
import Separator from '../core/components/Separator/Separator';
import { closeModal } from '../../actions/modal';
import LoaderAI from '../core/components/LoaderAI/LoaderAI';

const AlternativesModal = () => {
  const {
    fieldLabel,
    fieldPlaceholder,
    modalTitle,
    modalSubtitle,
    loadAlternatives,
    onSave,
    alternativesModalLoader,
  } = useSelector((state: RootState) => state.modal.data);

  const modalName = useSelector((state: RootState) => state.modal.name);
  const creationLoader = useSelector((state: RootState) => state.contentCreationNew.loader);
  const refreshLoader = useSelector((state: RootState) => state.contentRefresh.loader);

  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState<any>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>(null);

  useEffect(() => {
    setSuggestions([]);

    modalName === ALTERNATIVES_MODAL && loadAlternatives().then(suggestionsList => setSuggestions(suggestionsList));
  }, [modalName]);

  const handleOnUpdateValue = useCallback((radioIndex, newValue) => {
    // Do nothing, if there are no suggestions
    if (!suggestions.length) {
      return;
    }

    const newSuggestions = [...suggestions];

    newSuggestions[radioIndex] = newValue;

    setSuggestions(newSuggestions);
  }, [suggestions]);

  return (
    <Modal
      name={ALTERNATIVES_MODAL}
      modalWidth='1090px'
    >
      <div className={styles.alternativesModalWrapper}>
        <div className={styles.header}>
          <div className={styles.heading}>
            <div className={styles.title}>{modalTitle}</div>
            {/* <div className={styles.subtitle}>{modalSubtitle}</div> */}
          </div>
          {(!creationLoader && !refreshLoader) && (
          <div className={styles.actions}>
            <Button
              actionName='cancel'
              action={() => dispatch(closeModal())}
              className={styles.greyButton}
            />
            <Button
              actionName='save'
              action={() => onSave(selectedSuggestion)}
              className={styles.confirmButton}
              disabled={!selectedSuggestion}
            />
          </div>
          )}
        </div>
        <Separator margin='32px 0 21px 0' />
        {(creationLoader === alternativesModalLoader || refreshLoader === alternativesModalLoader) ? (
          <LoaderAI
            componentLoader
            componentLoaderText='We are generating your alternatives...'
          />
        ) : (
          <div className={styles.radioGroupWrapper}>
            <RadioGroup
              label={fieldLabel}
              placeholder={fieldPlaceholder}
              onUpdateValue={handleOnUpdateValue}
              suggestions={suggestions}
              form={{
                setFieldValue: (x, newSuggestion) => setSelectedSuggestion(newSuggestion),
              }}
              field={{
                name: 'alternative',
                value: selectedSuggestion,
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AlternativesModal;
