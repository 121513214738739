import React from 'react';
import { Field, Formik } from 'formik';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import Input from '../core/components/Input';
import { Button } from '../core/components/Button/button';
import { validateEmail } from '../../util/helpers';
import Modal from '../core/components/Modal';
import { DOWNLOAD_FILE_MODAL } from '../../constants/modal';
import Separator from '../core/components/Separator/Separator';

function DownloadFileModal({
  action,
}) {
  return (
    <Modal name={DOWNLOAD_FILE_MODAL}>
      <Formik
        initialValues={{}}
      >
        {({ values }) => (
          <div className={styles.modal}>
            <div className={styles.image}>
              {renderIcon('envelopeIllustration')}
            </div>
            <span className={styles.title}>
              Download article
            </span>
            <Field
              component={Input}
              placeholder='Enter email'
              name='email'
              validate={(value) => {
                if (!validateEmail(value || '')) {
                  return 'Invalid email address';
                }
              }}
            />
            <Separator margin='20px 0' />
            <Button
              disabled={!validateEmail(values.email || '')}
              className={styles.downloadButton}
              actionName='download'
              leftIcon='download'
              action={() => action(values.email)}
            />
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default DownloadFileModal;
