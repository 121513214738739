import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import { Button } from '../core/components/Button/button';
import { closeModal } from '../../actions/modal';
import Modal from '../core/components/Modal';
import { SECTIONS_WARNING_MODAL } from '../../constants/modal';

function SectionsWarningModal() {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal);

  return (
    <Modal name={SECTIONS_WARNING_MODAL}>
      <div className={styles.modal}>
        <div className={styles.image}>
          {renderIcon('sectionsWarning')}
        </div>
        <span className={styles.title}>
          Are you sure you want to continue?
        </span>
        <span className={styles.text}>
          {/* eslint-disable-next-line max-len */}
          Please be aware that if your content consists of three or fewer headings and topics, there may be limited information to cover, leading to fewer amount of paragraphs.
          <br />
          <br />
          You may choose to revisit your content and add additional headings or continue with the existing structure.
        </span>
        <div className={styles.buttonsRow}>
          <Button
            actionName='edit content'
            action={() => dispatch(closeModal())}
          />
          <Button
            actionName='continue'
            action={() => {
              modal?.data?.continueAction && modal.data.continueAction();

              dispatch(closeModal());
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default SectionsWarningModal;
