import React, { useCallback, useEffect } from 'react';
import { Field } from 'formik';
import Flag from 'react-world-flags';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useMediaQuery } from '@material-ui/core';
import Card from '../../../core/components/Card/Card';
import styles from './styles.module.scss';
import languages from '../../../../assets/json/languages.json';
import RadioButtonGroup from '../../../core/components/RadioButtonGroup/RadioButtonGroup';
import renderIcon from '../../../../util/renderIcon';
import { Option } from '../../../core/components/RadioButtonGroup/interfaces';
import Select from '../../../core/components/Select/Select';
import Separator from '../../../core/components/Separator/Separator';
import {
  restorePreviousProgress,
  setActiveCard,
  setActiveStep,
  setPreviousProgressLoader,
  setStore,
} from '../../../../actions/content-creation-new';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import Layout from './Layout';
import { RootState } from '../../../../reducers';
import Mixpanel from '../../../../util/Mixpanel';

const SettingsPage = ({ form }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width:768px)');

  // Restore previous progress based on the continue_hash from the URL search params
  useEffect(() => {
    // Get the search parameters from the current URL
    const searchParams = new URLSearchParams(location.search);
    // Extract the continue_hash value from the search parameters
    const continueHash = searchParams.get('continue_hash');

    // If continueHash exists and isn't empty
    if (continueHash?.length) {
      // Restore the previous progress using the continueHash
      restorePreviousProgress(continueHash).then(response => {
        // Retrieve the progress data from the backend response
        const progress = response.data.data;

        // If there is either no Redux or Formik values in backend response, throw an error
        if (!progress.store || !progress.values) {
          throw new Error();
        }

        // Restore Redux from backend response
        dispatch(setStore(progress.store));

        // Restore Formik from backend response
        form.setValues(progress.values);
      }).catch(() => {
        // Add some error alert modal that will notify user
        // that the restore process did not complete, because of backend is down
      }).finally(() => {
        dispatch(setPreviousProgressLoader(false));
      });
    }
  }, []);

  const articleLengthOptions = [
    {
      id: 'short',
      icon: renderIcon('shortLengthText'),
      label: 'Short',
      description: '500 - 750 words',
    },
    {
      id: 'medium',
      icon: renderIcon('mediumLengthText'),
      label: 'Medium',
      description: '750 - 1250 words',
    },
    {
      id: 'long',
      icon: renderIcon('longLengthText'),
      label: 'Long',
      description: '1250+ words',
    },
  ];

  const toneOfVoiceOptions = [
    {
      value: 'default',
      label: 'Generic',
    },
    {
      value: 'formal',
      label: 'Formal',
    },
    {
      value: 'playful',
      label: 'Playful',
    },
    {
      value: 'casual',
      label: 'Casual',
    },
    {
      value: 'technical',
      label: 'Technical',
    },
    {
      value: 'academic',
      label: 'Academic',
    },
    {
      value: 'humorous',
      label: 'Humorous',
    },
    {
      value: 'professional',
      label: 'Professional',
    },
    {
      value: 'engaging',
      label: 'Engaging',
    },
    {
      value: 'inspirational',
      label: 'Inspirational',
    },
    {
      value: 'informative',
      label: 'Informative',
    },
  ];

  const languageButtonContent = useCallback((option: Option) => (
    <>
      <Flag
        code={option.country_code}
        height={12}
        style={{ borderRadius: '3px' }}
      />
      {option.name}
    </>
  ), []);

  const articleLengthButtonContent = useCallback((option: Option) => (
    <div className={styles.radioButtonWrapper}>
      <div className={styles.radioButtonHeader}>
        {option.icon}
        {option.label}
      </div>
      <div className={styles.radioButtonContent}>
        {option.description}
      </div>
    </div>
  ), []);

  return (
    <Layout columns={1}>
      <div>
        <Card
          title='Let’s get started'
          content={(
            <div className={styles.wrapper}>
              <div className={styles.title}>
                In what language do you want to generate your blog?
              </div>
              <div className={styles.content}>
                <Field
                  name='language'
                  component={RadioButtonGroup}
                  options={languages}
                  buttonContent={languageButtonContent}
                  buttonWidth={isMobile ? '150px' : '212px'}
                />
              </div>
              <div className={styles.title}>
                Which tone of voice do you want to use?
              </div>
              <div className={styles.content}>
                <Field
                  name='toneOfVoice'
                  component={Select}
                  selectLabel='Options'
                  searchable={false}
                  width='310px'
                  options={toneOfVoiceOptions}
                  dropdownPosition='left'
                />
              </div>
              <div className={styles.title}>
                What text length do you prefer?
              </div>
              <div className={styles.content}>
                <Field
                  name='articleLength'
                  component={RadioButtonGroup}
                  options={articleLengthOptions}
                  buttonContent={articleLengthButtonContent}
                  buttonWidth={isMobile ? '100%' : '212px'}
                />
              </div>
            </div>
            )}
        />
        <Separator margin='40px 0' />
        <StepperFooter
          nextStep={() => {
            dispatch(setActiveStep(2));
            dispatch(setActiveCard(1));
          }}
          previousDisabled
          actionName='next step'
          footerRightIcon='arrowNext'
        />
      </div>
    </Layout>
  );
};

export default SettingsPage;
