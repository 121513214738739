import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { range } from 'lodash';
import Pagination from 'pagination-object';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import styles from './Table.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { updatePage } from '../../../../actions/documents';

function Table({
  columns, data, limit, total, currentPage,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const dispatch = useDispatch();

  const pagination = useMemo(() => new Pagination({
    currentPage,
    totalItems: total || 1,
    itemsPerPage: limit,
    rangeLength: 5,
  }), [currentPage, total, limit]);

  return (
    <div className={styles.tableContainer}>
      <table
        {...getTableProps()}
        className={styles.table}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps({
                    style: { width: column.width },
                  })}
                  className={styles.header}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={styles.row}
              >
                {row.cells.map(cell => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length > 0 && (
        <div className={styles.pagination}>
          {pagination.currentPage !== pagination.firstPage && (
          <span
            tabIndex={0}
            role='button'
            className={styles.icon}
            onKeyDown={() => pagination.previousPage && dispatch(updatePage(pagination.previousPage))}
            onClick={() => pagination.previousPage && dispatch(updatePage(pagination.previousPage))}
          >
            {renderIcon('arrowPrevious')}
          </span>
          )}
          <div className={styles.numerous}>
            {range(pagination.rangeStart, pagination.rangeEnd + 1).map((pageNumber) => (
              <div
                tabIndex={0}
                role='button'
                key={pageNumber}
                className={classNames(styles.number, { [styles.active]: pagination.currentPage === pageNumber })}
                onKeyDown={() => dispatch(updatePage(pageNumber))}
                onClick={() => dispatch(updatePage(pageNumber))}
              >
                {pageNumber}
              </div>
            ))}
            {/* {pagination.lastPage - pagination.currentPage > 1 && ( */}
            {/*  <> */}
            {/*    <span className={styles.dots}>...</span> */}
            {/*    <span */}
            {/*      tabIndex={0} */}
            {/*      role='button' */}
            {/*      className={classNames(styles.number, { [styles.active]: pagination.currentPage === pagination.lastPage })} */}
            {/*      onKeyDown={() => pagination.nextPage && dispatch(updatePage(pagination.lastPage))} */}
            {/*      onClick={() => pagination.nextPage && dispatch(updatePage(pagination.lastPage))} */}
            {/*    > */}
            {/*      {pagination.lastPage} */}
            {/*    </span> */}
            {/*  </> */}
            {/* )} */}

          </div>
          {pagination.currentPage !== pagination.lastPage && (
            <span
              tabIndex={0}
              role='button'
              className={styles.icon}
              onKeyDown={() => pagination.nextPage && dispatch(updatePage(pagination.nextPage))}
              onClick={() => pagination.nextPage && dispatch(updatePage(pagination.nextPage))}
            >
              {renderIcon('arrowNext')}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default Table;
