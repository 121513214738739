import React from 'react';
import { Button, FooterButton } from '../../core/components/Button/button';
import styles from './styles.module.scss';
import { StepperFooterProps } from './interfaces';

const StepperFooter: React.FC<StepperFooterProps> = ({
  nextStepNumber,
  actionName,
  nextStep,
  previousStep,
  previousDisabled = false,
  nextDisabled = false,
  footerRightIcon,
  footerLeftIcon,
}) => (
  <div className={styles.container}>
    {!previousDisabled
      && (
      // <SecondaryButton
      //   onClick={() => !previousDisabled && (previousStep ? previousStep() : null)}
      //   name='previous step'
      //   isDisabled={previousDisabled}
      // />
        <Button
          actionName='previous step'
          action={() => !previousDisabled && (previousStep ? previousStep() : null)}
          disabled={previousDisabled}
          className={styles.previousButton}
        />
      )}
    <FooterButton
      action={() => !nextDisabled && (nextStep ? nextStep() : null)}
      nextStepNumber={nextStepNumber}
      actionName={actionName}
      disabled={nextDisabled}
      rightIcon={footerRightIcon}
      leftIcon={footerLeftIcon}
    />
  </div>
);

export default StepperFooter;
