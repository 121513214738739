import React from 'react';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import Modal from '../core/components/Modal';
import { URL_NOT_FOUND_ERROR_MODAL } from '../../constants/modal';

function UrlNotFoundErrorModal() {
  return (
    <Modal name={URL_NOT_FOUND_ERROR_MODAL}>
      <div className={styles.modal}>
        <div className={styles.image}>
          {renderIcon('urlNotFoundError')}
        </div>
        <span className={styles.title}>
          Oops, something went wrong
        </span>
        <span className={styles.text}>
          We&apos;re sorry, but it appears that the URL you&apos;re trying to access cannot be found.
          <br />
          <br />
          Additionally, you may want to try copying and pasting the text you wish to extract using the Copy/Paste functionality.
          <br />
          <br />
          If you still experience issues, please don&apos;t hesitate to contact our support team for further assistance.
          <br />
          Thank you.
        </span>
      </div>
    </Modal>
  );
}

export default UrlNotFoundErrorModal;
