import React, {
  useCallback, useMemo, useState,
} from 'react';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import Card from '../../../core/components/Card/Card';
import FormikInput from '../../../core/components/FormikInput/FormikInput';
import styles from './styles.module.scss';
import Tags from '../../../core/components/Tags/Tags';
import { NextButton, PreviousButton } from '../../../core/components/Button/button';
import { RootState } from '../../../../reducers';
import RadioGroup from '../../../core/components/RadioGroup/RadioGroup';
import RadioButtonGroup from '../../../core/components/RadioButtonGroup/RadioButtonGroup';
import renderIcon from '../../../../util/renderIcon';
import { Option } from '../../../core/components/RadioButtonGroup/interfaces';
import { resetSections } from '../../../../actions/content-refresh';
import Separator from '../../../core/components/Separator/Separator';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import {
  generateFullArticleTurbo,
  generateTitles,
  setActiveCard,
  setActiveStep,
  setSectionValue,
  setSerpContext, showLoader,
} from '../../../../actions/content-creation-new';
import languages from '../../../../assets/json/languages.json';
import Layout from './Layout';
import {
  showPreviousStepWarning,
} from '../../../../actions/content-creation';
import { GENERATE_OUTLINES_LOADER } from '../../../../constants/loaders-new';
import Mixpanel from '../../../../util/Mixpanel';

const BriefingPage = ({ form }) => {
  const {
    titles, activeCard,
  } = useSelector((state: RootState) => state.contentCreationNew);
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const { errors, touched } = form;

  const blogGenerationOptions = [
    {
      id: 'turbo',
      icon: renderIcon('thunder'),
      label: 'Turbo',
      description: 'We use our AI magic to automatically generate your content.',
      caption: 'Takes only 1 min.',
    },
    {
      id: 'advanced',
      icon: renderIcon('advanced'),
      label: 'Advanced',
      description: 'Manually control the content and order of your paragraphs',
      caption: 'Takes only 3 min.',
    },
  ];

  const blogGenerationButtonContent = useCallback((option: Option) => (
    <div className={styles.radioButtonWrapper}>
      <div className={styles.radioButtonHeader}>
        <div className={styles.icon}>{option.icon}</div>
        {option.label}
      </div>
      <div className={styles.radioButtonContent}>
        {option.description}
        <div className={styles.radioButtonCaption}>
          {option.caption}
        </div>
      </div>
    </div>
  ), []);

  // Reset the form sections and values based on the given card number
  const resetToCard = cardNumber => {
    dispatch(setActiveCard(cardNumber));

    if (cardNumber <= 1) {
      dispatch(resetSections('title'));
      form.setFieldValue('title', '');
    }
  };

  // Find the language object in the 'languages' array that matches the language ID from the form values
  const selectedLanguage: any = useMemo(() => {
    const lang = languages.find((language) => language.id === form.values.language) || {};
    return lang;
  }, [form.values.language]);

  // Retrieve language code and Google domain for the selected language
  const { crowdin_code: languageCode, google_domain: googleDomain } = selectedLanguage;

  return (
    <Layout columns={1}>
      <div style={{ rowGap: '30px' }}>
        <Element
          name='step2_card1'
          className={styles.element}
        >
          <Card
            title='What is your blog about?'
            completed={activeCard > 1}
            editAction={() => resetToCard(1)}
            content={(
              <>
                {activeCard === 1 && (
                  <>
                    <div className={styles.title}>Tell us more about the subject</div>
                    <Field
                      name='subject'
                      component={FormikInput}
                      label='Please describe it in one sentence'
                      placeholder='Enter main subject'
                      type='text'
                      error={errors.subject && touched.subject}
                    />
                    {(errors.subject && touched.subject) && (
                      <div className={styles.error}>{errors.subject}</div>
                    )}
                    <div className={styles.title}>What is the primary (SEO) keyword?</div>
                    <Field
                      name='primarySEO'
                      component={Tags}
                      limit={1}
                      label='We will use this to optimise your blog'
                      placeholder='Enter main keyword'
                    />
                    <div className={styles.title}>Do you have any secondary (SEO) keywords that you want to cover?</div>
                    <Field
                      name='secondarySEO'
                      component={Tags}
                      limit={5}
                      label='This helps us to further shape your blog'
                      placeholder='Enter secondary keywords'
                    />
                  </>
                )}
                {activeCard > 1 && (
                  <div className={styles.completed}>
                    <div className={styles.fieldBlock}>
                      <span className={styles.fieldTitle}>Subject</span>
                      <span className={styles.value}>{form.values.subject}</span>
                    </div>
                    <div className={styles.fieldBlock}>
                      <span className={styles.fieldTitle}>Primary (SEO) keyword</span>
                      <div className={styles.value}>
                        {form.values.primarySEO?.map((tag) => (
                          <span className={styles.pill}>{tag}</span>
                        ))}
                      </div>
                    </div>

                    <div className={styles.fieldBlock}>
                      {(form.values.secondarySEO?.length > 0) && <div className={styles.fieldTitle}>Secondary (SEO) keywords</div>}
                      <div className={styles.value}>
                        {form.values.secondarySEO?.map((tag) => (
                          <span className={styles.pill}>{tag}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            actionBlock={(activeCard === 1) && (
              <NextButton
                disabled={activeCard !== 1 || (form.values.subject.length < 6 || !form.values.primarySEO?.length)}
                action={() => {
                  dispatch(setActiveCard(2));
                  setLoader(true);
                  dispatch(generateTitles(
                    languageCode,
                    form.values.subject,
                    form.values.secondarySEO,
                    form.values.primarySEO[0],
                    setLoader,
                  ));
                  Mixpanel.track('Content Creation - Briefing - Enter main subject and keywords');
                }}
              />
            )}
          />
        </Element>
        <Element
          name='step2_card2'
          className={styles.element}
        >
          <Card
            completed={activeCard > 2}
            disabled={activeCard < 2}
            title={activeCard > 2 ? 'Title' : 'Choose your title'}
            subtitle='Choose one of the generated title’s or write your own.'
            editAction={() => resetToCard(2)}
            isLoading={activeCard === 2 && loader}
            loaderText='We’re generating your title...'
            content={(
              <>
                {(activeCard === 2 && titles.length > 0) && (
                  <Field
                    name='title'
                    component={RadioGroup}
                    label='Select (and edit) a title'
                    suggestions={titles}
                    placeholder='Enter your own title'
                    onUpdateValue={(radioIndex, newValue) => {
                      const newTitles = [...titles];

                      newTitles[radioIndex] = newValue;

                      dispatch(setSectionValue('titles', newTitles));
                    }}
                  />
                )}
                {activeCard > 2 && (<div>{form.values.title}</div>)}
              </>
            )}
            actionBlock={
              activeCard === 2
              && (
                <>
                  <PreviousButton
                    action={() => {
                      dispatch(setActiveCard(1));
                      form.setFieldValue('title', null);
                    }}
                  />
                  <NextButton
                    disabled={activeCard !== 2 || !form.values.title}
                    action={() => {
                      dispatch(setActiveCard(3));
                      Mixpanel.track('Content Creation - Briefing - Select title');
                    }}
                  />
                </>
              )
            }
          />
        </Element>
        <Element
          name='step2_card3'
          className={styles.element}
        >
          <Card
            disabled={activeCard < 3}
            title='How would you like to generate your blog?'
            content={activeCard === 3 && (
              <div className={styles.content}>
                <Field
                  name='blogGeneration'
                  component={RadioButtonGroup}
                  options={blogGenerationOptions}
                  buttonContent={blogGenerationButtonContent}
                  buttonWidth='329px'
                />
              </div>
            )}
            actionBlock={
              activeCard === 3
              && (
                <PreviousButton
                  action={() => {
                    dispatch(setActiveCard(2));
                  }}
                />
              )
            }
          />
        </Element>
        <Separator margin='20px 0' />
        <StepperFooter
          nextDisabled={
            activeCard < 3
          }
          nextStep={() => {
            if (form.values.blogGeneration === 'advanced') {
              dispatch(showLoader(GENERATE_OUTLINES_LOADER));
              dispatch(setSerpContext(form.values.subject, googleDomain, [
                languageCode,
                form.values.subject,
                form.values.primarySEO[0],
                form.values.secondarySEO,
                form.values.title,
                form.values.articleLength,
              ]));
              dispatch(setActiveStep(3));
            } else {
              dispatch(setActiveStep(4));
              dispatch(generateFullArticleTurbo(
                languageCode,
                form.values.subject,
                form.values.primarySEO[0],
                form.values.secondarySEO,
                form.values.title,
                form.values.toneOfVoice,
                form.values.articleLength,
              ));
            }
            Mixpanel.track('Content Creation - Briefing - Blog generation options');
          }}
          previousStep={() => {
            dispatch(showPreviousStepWarning(() => {
              dispatch(setActiveStep(1));
              dispatch(setActiveCard(1));
              dispatch(resetSections('subject', 'primarySEO', 'secondarySEO', 'title', 'blogGeneration'));
              form.setFieldValue('subject', '');
              form.setFieldValue('primarySEO', []);
              form.setFieldValue('secondarySEO', []);
              form.setFieldValue('title', '');
              form.setFieldValue('blogGeneration', 'turbo');
            }));
          }}
          actionName={form.values.blogGeneration === 'turbo' ? 'generate blog' : 'set paragraphs'}
          footerLeftIcon='magicWand'
        />
      </div>
    </Layout>
  );
};

export default BriefingPage;
