import React, { useState } from 'react';
import { uniqBy } from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import styles from './Tags.module.scss';
import renderIcon from '../../../../util/renderIcon';

function Tags(props) {
  const {
    label, description, placeholder, limit, field, form,
  } = props;

  const isMobile = useMediaQuery('(max-width: 768px)');

  const value = field.value || [];

  const [prompt, setPrompt] = useState('');

  const handleOnKeyDown = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
    }
  };

  // Function to split keywords from the text input and update the field value
  const splitKeywords = (text) => {
    // Split the text by commas and filter out any empty tags
    const tags = text.split(',').filter((tag) => tag.length);

    // Check if the last tag has a length less than 2 characters
    if (tags[tags.length - 1]?.length < 2) {
      return;
    }

    // Create a new array of unique tags by combining the existing value and the new tags
    let newTags = uniqBy([
      ...value,
      ...tags,
    ], (tag) => tag.toLowerCase());

    // Limit the number of tags if a limit is specified
    if (limit) {
      newTags = newTags.slice(0, limit);
    }

    // Update the field value with the new tags
    form.setFieldValue(field.name, newTags);

    // Clear the prompt
    setPrompt('');
  };

  const handleOnKeyUp = (e) => {
    // Split keywords if the key pressed is comma or Enter
    if (['Comma', 'Enter'].includes(e.code)) {
      splitKeywords(prompt);
    }
  };

  // Function to handle the change event of the text input
  const handleOnChange = (e) => {
    // Get the last character of the input value
    const lastCharacter = e.target.value[e.target.value.length - 1];

    // Check if the last character is a comma
    if (lastCharacter === ',') {
      // Split the prompt by commas and filter out any empty tags
      const tags = prompt.split(',').filter((tag) => tag.length);

      // Check if the last tag has a length less than 2 characters
      if (tags[tags.length - 1]?.length < 2) {
        return;
      }
    }

    // Update the prompt value
    setPrompt(e.target.value);
  };

  const handleDeleteTag = (tag) => {
    // Create a new array of tags by filtering out the tag to be deleted
    const newTags = value.filter((item) => item !== tag);

    // Update the field value with the new tags
    form.setFieldValue(field.name, newTags);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>{label}</div>
      {description && (<div className={styles.description}>{description}</div>)}
      <div className={styles.input}>
        {value.map((tag) => (
          <div
            className={styles.pill}
            key={tag}
          >
            {tag}
            <span
              tabIndex={0}
              role='button'
              onClick={() => handleDeleteTag(tag)}
              onKeyDown={() => handleDeleteTag(tag)}
            >
              {renderIcon('close')}
            </span>
          </div>
        ))}
        {(!limit || value.length < limit) && (
          <input
            type='text'
            placeholder={placeholder}
            value={prompt}
            onKeyDown={handleOnKeyDown}
            onKeyUp={handleOnKeyUp}
            onChange={handleOnChange}
            onBlur={() => splitKeywords(prompt)}
            onPaste={(event) => {
              splitKeywords(event.clipboardData.getData('text'));

              event.preventDefault();
            }}
          />
        )}
        {(value.length < limit && isMobile) && (
        <div
          role='button'
          tabIndex={0}
          className={styles.mobilePlusIcon}
          onClick={() => splitKeywords(prompt)}
          onKeyDown={() => splitKeywords(prompt)}
        >
          {renderIcon('plus')}
        </div>
        )}
      </div>
    </div>
  );
}

export default Tags;
