import React from 'react';
import classNames from 'classnames';
import { LayoutProps } from './interfaces';
import styles from './Layout.module.scss';

const Layout: React.FC<LayoutProps> = ({ children, columns }) => (
  <div className={classNames(columns === 1 && styles.oneColumnWrapper, columns === 2 && styles.twoColumnWrapper)}>
    {children}
  </div>
);

export default Layout;
