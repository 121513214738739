import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Field } from 'formik';
import styles from './Paragraph.module.scss';
import FormikInput from '../FormikInput/FormikInput';
import TextEditor from '../TextEditorAI/TextEditor';
import { ParagraphProps } from './interfaces';
import renderIcon from '../../../../util/renderIcon';
import { KEY_MESSAGES_MODAL } from '../../../../constants/modal';
import { openModal } from '../../../../actions/modal';

const Paragraph: React.FC<ParagraphProps> = ({
  label,
  placeholder,
  actions,
  hasTitle,
  hasText,
  form,
  field,
  showAlternatives,
}) => {
  const dispatch = useDispatch();

  const [showEditor, setShowEditor] = useState(false);
  const contentEditableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showEditor && contentEditableRef.current && contentEditableRef.current.focus) {
      contentEditableRef.current.focus();
    }
  }, [showEditor]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <div className={styles.leftSide}>
          <span>{label}</span>
        </div>
        <div
          className={styles.rightSide}
        >
          {actions?.map(({
            onClick, disabled, tooltip, icon,
          }) => (
            <div
              tabIndex={0}
              role='button'
              onClick={!disabled ? onClick : undefined}
              onKeyDown={!disabled ? onClick : undefined}
              className={classNames(styles.icon, { [styles.disabled]: disabled })}
              data-tooltip-id='tooltip'
              data-tooltip-content={tooltip}
            >
              {icon}
            </div>
          ))}
        </div>
      </div>
      {hasTitle && (
        <Field
          component={FormikInput}
          name={`${field.name}.title`}
          additionalStyle={{
            fontSize: '17px',
            fontFamily: '"GTWalsheimMedium", sans-serif',
          }}
          placeholder={placeholder}
        />
      )}
      {hasText && (
        <>
          { /* We need to rerender text editor whenever we add, move or delete Paragraph's inside the FieldArray's */ }
          <Fragment key={field.value?.title}>
            <TextEditor
              allowedTags={['Normal', 'H3']}
              onChange={(subValue) => form.setFieldValue(`${field.name}.text`, subValue)}
              noWrapper
              initialText={field.value?.text}
              onFocus={() => setShowEditor(true)}
              onBlur={() => setShowEditor(false)}
              toolbarHidden={!showEditor}
              border
              blockType
            />
          </Fragment>
          <div className={styles.footerActions}>
            <div className={styles.button}>
              {renderIcon('plus')}
              <span>
                generate in-depth text
              </span>
            </div>
            <div
              tabIndex={0}
              role='button'
              className={styles.button}
              onClick={showAlternatives}
              onKeyDown={showAlternatives}
            >
              {renderIcon('plus')}
              <span>show alternatives</span>
            </div>
            <div
              tabIndex={0}
              role='button'
              className={styles.button}
              onClick={() => dispatch(openModal(KEY_MESSAGES_MODAL, {
                messages: [
                  { message: '', deletable: true, selected: false },
                ],
                title: 'asdasdsad',
                outlineIndex: 0,
              }))}
              onKeyDown={() => dispatch(openModal(KEY_MESSAGES_MODAL, {
                messages: [
                  { message: '', deletable: true, selected: false },
                ],
                title: 'asdasdsad',
                outlineIndex: 0,
              }))}
            >
              {renderIcon('plus')}
              <span>
                edit topics
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Paragraph;
