import axios from 'axios';
import { toast } from 'react-toastify';
import { client, removeToken, setToken } from '../util/api';
import * as types from '../constants/auth';
import renderIcon from '../util/renderIcon';

const url = process.env.REACT_APP_AUTH_URL;
const apiUrl = process.env.REACT_APP_API_URL;

export const setLoggedIn = (user) => ({
  type: types.LOGGED_IN,
  payload: {
    user,
  },
});

export const setLoggedOut = () => ({
  type: types.NOT_LOGGED_IN,
});

const setErrors = (errorMessage, validationErrors) => ({
  type: types.SET_ERRORS,
  payload: {
    validationErrors,
    errorMessage,
  },
});

export const resetErrors = () => ({
  type: types.RESET_ERRORS,
});

export function login(email, password, navigate) {
  return (dispatch) => {
    const myUrl = `${url}/login/`;

    axios.post(myUrl, {
      email, password,
    })
      .then(data => {
        const authToken = data.data.token;
        setToken(authToken);
        dispatch(setLoggedIn(data.data.user)); // Set `loggedIn` to LOGGED_IN, in `auth` redux

        navigate('/welcome');

        localStorage.setItem('token', authToken);
      }).catch((error) => {
      // console.error(error);
        dispatch(setErrors(error.response.data.message, error.response.data.errors));
        removeToken();
      });
  };
}

export function logout() {
  return (dispatch) => {
    // Clear Redux
    dispatch(setLoggedOut());
    // Clear Authorization header in Axios client
    removeToken();
    // Clear token from local storage
    localStorage.removeItem('token');
  };
}

export function forgotPassword(email, navigate) {
  return (dispatch) => {
    const myUrl = `${url}/forgot-password/`;

    axios.post(myUrl, {
      email,
    })
      .then(() => {
        // console.info('SUCCESS RESET', data);
        navigate('/check-reset-email');
      }).catch((error) => {
      // console.error(error);
        dispatch(setErrors(error.response.data.message, error.response.data.errors));
      });
  };
}

export function resetPassword(email, password, passwordConfirmation, token, navigate) {
  return (dispatch) => {
    const myUrl = `${url}/reset-password/`;

    axios.post(myUrl, {
      email,
      password,
      password_confirmation: passwordConfirmation,
      token,
    })
      .then((data) => {
        if (data.data.status === 'This password reset token is invalid.') {
          dispatch(setErrors('The URL you have provided is invalid.', {}));
        } else {
          navigate('/login');
        }
      }).catch((error) => {
        dispatch(setErrors(error.response.data.message, error.response.data.errors));
      });
  };
}

export function register(email, firstName, lastName, password, passwordConfirmation, navigate) {
  return (dispatch) => {
    const myUrl = `${url}/register/`;

    axios.post(myUrl, {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      password_confirmation: passwordConfirmation,
    })
      .then(response => {
        // Handle the response if needed
        console.info('SUCCESS REGISTER', response.data);
        navigate('/login');
      })
      .catch(error => {
        // Handle the error if needed
        // console.error(error);
        dispatch(setErrors(error.response.data.message, error.response.data.errors));
      });
  };
}

export function whoami(app_token) {
  return (dispatch) => {
    let myUrl = `${apiUrl}/users/whoami`;

    const config = {};

    if (app_token) {
      config.headers = {
        'X-Contentoo-Token': 1,
      };
      myUrl += `?main_app_token=${app_token}`; // Add main_app_token to myUrl
    }

    client.get(myUrl, config).then(({ data }) => {
      if (data?.id) {
        dispatch(setLoggedIn(data)); // Set `loggedIn` to LOGGED_IN, in `auth` redux
      } else {
        dispatch(setLoggedOut()); // Set `loggedIn` to NOT_LOGGED_IN, in `auth` redux
        removeToken(); // Remove token from axios client
        localStorage.removeItem('token'); // Remove token from localStorage
      }
    }).catch(() => {
      dispatch(setLoggedOut()); // Set `loggedIn` to NOT_LOGGED_IN, in `auth` redux
    });
  };
}

export function checkUserAuthenticated(data, navigate, dispatch) {
  const isLoggedIn = data?.data?.data?.status_code !== 403;

  if (!isLoggedIn) {
    const warnIcon = {
      icon: renderIcon('warning'),
    };

    setTimeout(() => navigate('/login'), 50);
    dispatch(logout());
    toast.warn('Your session has expired. Please log in again to continue using the application.', warnIcon);
  }

  return isLoggedIn;
}
