import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import styles from './Card.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { Button } from '../Button/button';
import { openModal } from '../../../../actions/modal';
import { hideLoader } from '../../../../actions/loader';
import { EDIT_WARNING_MODAL } from '../../../../constants/modal';
import { CardProps } from './interfaces';
import LoaderAI from '../LoaderAI/LoaderAI';

const Card: React.FC<CardProps> = ({
  subtitle,
  title,
  content,
  disabled,
  completed,
  isLoading,
  loaderText,
  actionBlock = null,
  className = '',
  editAction,
}) => {
  const dispatch = useDispatch();

  // Function to display an edit warning and open a modal
  const showEditWarning = () => {
    dispatch(openModal(EDIT_WARNING_MODAL, {
      editAction,
    }));

    dispatch(hideLoader());
  };

  return (
    <div
      className={classNames(styles.card, className, { [styles.disabled]: disabled }, { [styles.completed]: completed })}
    >
      <div className={styles.row}>
        {completed ? (
          <>
            <div className={styles.checked}>
              {renderIcon('greenChecked')}
            </div>
            <div className={styles.title}>{title}</div>
            <Button
              actionName='edit'
              className={styles.editButton}
              action={showEditWarning}
            />
          </>
        ) : (
          <div className={styles.title}>{title}</div>
        )}
      </div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      {(!!content && <div className={classNames({ [styles.content]: completed })}>{content}</div>)}
      {/* {isLoading && ( */}
      {/*  <div className={styles.loader}> */}
      {/*    <div className={styles.spinner} /> */}
      {/*    <span> */}
      {/*      {loaderText || ( */}
      {/*      <> */}
      {/*        We’re generating the content, would you like some fries while waiting? &#127839; */}
      {/*      </> */}
      {/*      )} */}
      {/*    </span> */}
      {/*  </div> */}
      {/* )} */}
      {isLoading && (
      <LoaderAI
        componentLoader
        componentLoaderText={loaderText}
      />
      )}
      {!!actionBlock && <div className={styles.actionBlock}>{actionBlock}</div>}
    </div>
  );
};

export default Card;
