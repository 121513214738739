import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import { xor } from 'lodash';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';

export interface MultiSelectProps {
  label: string;
  options: any[];
  onChangeSelectedValues: (updatedValues: any[]) => void;
  selectedValues: any[];
}

function MultiSelect({
  label, options, onChangeSelectedValues, selectedValues,
}: MultiSelectProps) {
  const [multiSelectActive, setMultiSelectActive] = useState(false);

  const handleCloseMultiSelect = () => {
    setMultiSelectActive(false);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.button}
        onMouseDown={() => setMultiSelectActive(!multiSelectActive)}
        onKeyDown={() => setMultiSelectActive(!multiSelectActive)}
        role='button'
        tabIndex={0}
      >
        <span>{label}</span>
        <span>{renderIcon('arrowDown')}</span>
      </div>
      <OutsideClickHandler
        onOutsideClick={handleCloseMultiSelect}
        disabled={!multiSelectActive}
      >
        {(multiSelectActive && options) && (
          <div className={styles.options}>
            {options.map((option) => (
              <div
                tabIndex={0}
                role='button'
                className={styles.option}
                onClick={() => onChangeSelectedValues(xor(selectedValues, [option.value]))}
                onKeyDown={() => onChangeSelectedValues(xor(selectedValues, [option.value]))}
              >
                <div className={classNames(styles.checkbox, { [styles.active]: selectedValues.includes(option.value) })} />
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
}

export default MultiSelect;
