/* eslint-disable max-len */
import { convert } from 'html-to-text';

export const projectTypes = {
  1: 'project',
  2: 'product',
};

const urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,6}(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)?(?:#\S*)?$/gi;

export const validateUrl = (url) => urlPattern.test(url);
export const validateEmail = (email) => email.match(new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));

export const htmlToText = (html) => convert(html, {
  wordwrap: false,
  preserveNewlines: true,
  selectors: [
    {
      selector: 'a',
      options: {
        linkBrackets: false,
        ignoreHref: true,
      },
    },
    {
      selector: 'img',
      format: 'skip',
    },
  ],
}).replace(/\n+/g, '\n').trim();

export const htmlToSections = (html) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, 'text/html');

  const sections = [];
  let currentSection = '';

  for (let i = 0; i < htmlDoc.body.children.length; i += 1) {
    const child = htmlDoc.body.children[i];

    if (child.tagName === 'H3') {
      if (currentSection !== '') {
        sections.push({ head: 0, txt: htmlToText(currentSection).split('\n').filter(line => line.length >= 3) });
      }

      currentSection = '';
    }

    currentSection += child.outerHTML;
  }

  if (currentSection !== '') {
    const txt = htmlToText(currentSection).split('\n').filter(line => line.length >= 3);
    if (txt.length) {
      sections.push({ head: 0, txt });
    }
  }

  return sections;
};

export const getHTMLWordCount = (html) => {
  // Remove HTML tags and decode HTML entities
  let strippedString = html?.replace(/<\/?[^>]+(>|$)/g, '');

  // Remove double newline characters and replace them with a space
  strippedString = strippedString?.replace(/\n\n/g, ' ');

  // Replace dots and commas right next to each other with a space
  strippedString = strippedString?.replace(/(\w)[.,](\w)/g, '$1 $2');

  // Remove hyphens and replace with spaces
  strippedString = strippedString?.replace(/-/g, ' ');

  return strippedString?.split(' ').filter((text) => text !== '').length;
};

// Transforming outlines to its previous shape, which we are getting from BE
export const revertOutlines = (myOutlines) => myOutlines.reduce((outputData, item) => {
  if (item.type === 'h2') {
    if (outputData.length > 0) {
      const currentH2 = outputData[outputData.length - 1];
      if (currentH2.h2_title) {
        outputData.push({
          h2_title: item.title,
          h3_subheadings: [],
        });
      } else {
        currentH2.h2_title = item.title;
      }
    } else {
      outputData.push({
        h2_title: item.title,
        h3_subheadings: [],
      });
    }
  } else if (item.type === 'h3') {
    if (outputData.length > 0) {
      const currentH2 = outputData[outputData.length - 1];
      if (currentH2.h2_title) {
        if (!currentH2.h3_subheadings) {
          currentH2.h3_subheadings = [];
        }
        currentH2.h3_subheadings.push({
          h3_title: item.title,
          h4_subheadings: [],
        });
      }
    }
  } else if (item.type === 'h4') {
    if (outputData.length > 0) {
      const currentH2 = outputData[outputData.length - 1];
      if (currentH2.h2_title && currentH2.h3_subheadings && currentH2.h3_subheadings.length > 0) {
        const currentH3 = currentH2.h3_subheadings[currentH2.h3_subheadings.length - 1];
        if (!currentH3.h4_subheadings) {
          currentH3.h4_subheadings = [];
        }
        currentH3.h4_subheadings.push({
          h4_title: item.title,
          content: item.text,
        });
      }
    }
  }
  return outputData;
}, []);

// Transforming paragraphs to its previous shape, which we are getting from BE
export const revertParagraphs = (myParagraphs) => myParagraphs.reduce((outputData, item) => {
  if (item.type === 'h2') {
    if (outputData.length > 0) {
      const currentH2 = outputData[outputData.length - 1];
      if (currentH2.h2_title) {
        outputData.push({
          h2_title: item.title,
          h2_content: item.text,
          h3_subheadings: [],
        });
      } else {
        currentH2.h2_title = item.title;
        currentH2.h2_content = item.text;
      }
    } else {
      outputData.push({
        h2_title: item.title,
        h2_content: item.text,
        h3_subheadings: [],
      });
    }
  } else if (item.type === 'h3') {
    if (outputData.length > 0) {
      const currentH2 = outputData[outputData.length - 1];
      if (currentH2.h2_title) {
        if (!currentH2.h3_subheadings) {
          currentH2.h3_subheadings = [];
        }
        currentH2.h3_subheadings.push({
          h3_title: item.title,
          h3_content: item.text,
          h4_subheadings: [],
        });
      }
    }
  } else if (item.type === 'h4') {
    if (outputData.length > 0) {
      const currentH2 = outputData[outputData.length - 1];
      if (currentH2.h2_title && currentH2.h3_subheadings && currentH2.h3_subheadings.length > 0) {
        const currentH3 = currentH2.h3_subheadings[currentH2.h3_subheadings.length - 1];
        if (!currentH3.h4_subheadings) {
          currentH3.h4_subheadings = [];
        }
        currentH3.h4_subheadings.push({
          h4_title: item.title,
          h4_content: item.text,
        });
      }
    }
  }
  return outputData;
}, []);
