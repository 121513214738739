import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import Header from '../../Header';
import StepperHeader from '../../Header/StepperHeader/StepperHeader';
import Stepper from '../../core/components/Stepper';
import styles from './styles.module.scss';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import FourthStep from './Steps/FourthStep';
import {
  resetReducer,
  setActiveCard,
  setActiveStep,
  setPreviousProgressLoader,
  storeProgressAndSendBackendErrorEmail,
} from '../../../actions/content-refresh';
import FinalStep from '../FinalStep';
import ContentPreview from '../../core/components/ContentPreview/ContentPreview';
import ContentPreviewToolbar from '../../core/components/ContentPreviewToolbar/ContentPreviewToolbar';
import AIErrorModal from '../../Modals/AIErrorModal';
import ExtractingUrlErrorModal from '../../Modals/ExtractingUrlErrorModal';
import ExitFlowModal from '../../Modals/ExitFlowModal';
import EditWarningModal from '../../Modals/EditWarningModal';
import PreviousStepWarning from '../../Modals/PreviousStepWarning';
import NotFoundErrorModal from '../../Modals/NotFoundErrorModal';
import InternetConnectionError from '../../Modals/InternetConnectionError';
import CharactersWarningModal from '../../Modals/CharactersWarningModal';
import SectionsWarningModal from '../../Modals/SectionsWarningModal';
import SectionsErrorModal from '../../Modals/SectionsErrorModal';
import UrlNotFoundErrorModal from '../../Modals/UrlNotFoundErrorModal';
import ThirdStep from './Steps/ThirdStep';
import Mixpanel from '../../../util/Mixpanel';
import { EXIT_FLOW_WARNING_MODAL } from '../../../constants/modal';
import { openModal } from '../../../actions/modal';
import { LOGGED_IN } from '../../../constants';

function ContentRefresh() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const store = useSelector(state => state.contentRefresh);

  const {
    activeStep,
    activeCard,
    downloadRequested,
    loader,
  } = store;

  const [showMessage, setShowMessage] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [formVals, setFormVals] = useState({});
  const [emailSent, setEmailSent] = useState(false);

  const loggedIn = useSelector(state => state.auth.loggedIn) === LOGGED_IN;

  // Parse the query parameters from the current location URL.
  const searchParams = new URLSearchParams(location.search);
  // Get the value of the 'continue_hash' parameter from the query parameters.
  const continueHash = searchParams.get('continue_hash');

  useEffect(() => {
    // Reset the relevant reducer state using the 'resetReducer' action.
    dispatch(resetReducer());

    // Set 'previousProgressLoader' state to true if 'continueHash' has a truthy value.
    if (continueHash?.length) {
      dispatch(setPreviousProgressLoader(true));
    }

    // Return a cleanup function to be executed when the component unmounts.
    return () => {
      // Reset the 'activeStep' state to 1 using the 'setActiveStep' action.
      dispatch(setActiveStep(1));
      // Reset the 'activeCard' state to 1 using the 'setActiveCard' action.
      dispatch(setActiveCard(1));
      // Reset the relevant reducer state using the 'resetReducer' action.
      dispatch(resetReducer());
    };
  }, []);

  // Scroll to a specific card within a step.
  const scrollToCard = (stepNumber, cardNumber) => {
    scroller.scrollTo(`step${stepNumber}_card${cardNumber}`, {
      offset: -90,
      duration: 0,
      smooth: false,
      containerId: 'scrollerContainer',
    });
  };

  // Scrolling to a specific card when the 'activeCard' or 'activeStep' dependencies change.
  useEffect(() => {
    if (activeStep < 5) {
      scrollToCard(activeStep, activeCard);
    }

    setShowMessage(false);
  }, [activeCard, activeStep]);

  return (
    <div
      id='scrollerContainer'
      className={styles.scroller}
    >
      <AIErrorModal
        action={(values) => storeProgressAndSendBackendErrorEmail(values.email, store, formVals, continueHash).then(() => {
          setShowMessage(true);
          setEmailSent(true);
        })}
        showMessage={showMessage}
      />
      <ExtractingUrlErrorModal />
      {!downloadRequested && (
        <ExitFlowModal
          showMessage={showMessage}
          cardLoader={loader}
          saveAction={(email) => {
            Mixpanel.track('Content Refresh - Finalize - Click continue later');
            storeProgressAndSendExitEmail(email, store, formVals, continueHash).then(() => {
              setShowMessage(true);
            });
          }}
          exitWithoutSaveAction={() => Mixpanel.track('Content Refresh - Finalize - Click to exit flow')}
        />
      )}
      <EditWarningModal />
      <PreviousStepWarning />
      <NotFoundErrorModal />
      <InternetConnectionError />
      <CharactersWarningModal />
      <SectionsWarningModal />
      <SectionsErrorModal />
      <UrlNotFoundErrorModal />
      <Header>
        <StepperHeader
          title='Content Refresh'
          stepper={(
            <Stepper
              steps={[
                { id: 1, title: 'Input' },
                { id: 2, title: 'Briefing' },
                { id: 3, title: 'Outlines' },
                { id: 4, title: 'Generate' },
                { id: 5, title: (loggedIn ? 'Result' : 'Finalize') },
              ]}
              activeStep={activeStep}
            />
          )}
          closeAction={() => {
            if ((activeStep === 1 && activeCard === 1) || (downloadRequested && !loader) || emailSent) {
              navigate('/');
            } else {
              dispatch(openModal(EXIT_FLOW_WARNING_MODAL));
            }
          }}
        />
      </Header>
      <ContentPreviewToolbar
        preview={activeStep !== 5 && activeStep !== 1}
        previewAction={() => setShowPreview(!showPreview)}
        previewValue={showPreview}
      />
      <div
        className={classNames(
          styles.container,
          { [styles.withPreview]: showPreview && activeStep !== 5 && activeStep !== 1 },
        )}
      >
        <Formik
          initialValues={{
            subject: '',
          }}
        >
          {form => {
            setFormVals(form.values);

            return (
              <>
                {activeStep === 1 && (
                  <FirstStep
                    form={form}
                    showPreview={() => setShowPreview(true)}
                  />
                )}
                {activeStep === 2 && (
                  <SecondStep form={form} />
                )}
                {activeStep === 3 && (
                  <ThirdStep form={form} />
                )}
                {activeStep === 4 && (
                  <FourthStep form={form} />
                )}
                {activeStep === 5 && (
                  <FinalStep
                    form={form}
                    documentType='refresh'
                  />
                )}
              </>
            );
          }}
        </Formik>
      </div>
      {showPreview && activeStep !== 5 && activeStep !== 1
        && (
        <div className={styles.previewContainer}>
          <ContentPreview contentValues={formVals} />
        </div>
        )}
    </div>
  );
}

export default ContentRefresh;
