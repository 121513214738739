import React from 'react';
import Flag from 'react-world-flags';
import { useMediaQuery } from '@material-ui/core';
import { OptionButton } from '../Button/button';
import Select from '../Select/Select';
import { RadioButtonGroupProps } from './interfaces';
import styles from './RadioButtonGroup.module.scss';

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  options, form, field, buttonContent, buttonWidth = '212px', label,
}) => {
  const defaultOptions = options.slice(0, 5);
  const additionalOptions = options.slice(5);

  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <>
      {label && (<div className={styles.label}>{label}</div>)}
      <div className={styles.wrapper}>
        {defaultOptions.map((option) => (
          <OptionButton
            key={option.id}
            selected={field.value === option.id}
            onSelect={(value) => form.setFieldValue(field.name, value)}
            value={option.id}
            content={buttonContent ? buttonContent(option) : ''}
            width={buttonWidth}
          />
        ))}
        {additionalOptions.length > 0 && (
          <Select
            selectLabel='Other'
            width={isMobile ? '150px' : '212px'}
            dropdownPosition={isMobile ? 'right' : 'left'}
            outline={additionalOptions.map((option) => option.id).includes(field.value)}
            form={form}
            field={field}
            options={additionalOptions.map((option) => ({
              value: option.id,
              label: option.name,
              icon: <Flag
                code={option.country_code}
                height={12}
                style={{ borderRadius: '3px' }}
              />,
            }))}
          />
        )}
      </div>
    </>
  );
};

export default RadioButtonGroup;
