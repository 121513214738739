import React, { useEffect, useMemo } from 'react';
import { Field, FieldArray } from 'formik';
import { Element } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueId } from 'lodash';
import styles from './styles.module.scss';
import Card from '../../../core/components/Card/Card';
import KeyMessages from '../../../core/components/KeyMessages/KeyMessages';
import { NextButton, PreviousButton } from '../../../core/components/Button/button';
import {
  generateIntroduction, generateKeyMessages,
  resetSections,
  setActiveCard,
  setActiveStep,
  showPreviousStepWarning,
} from '../../../../actions/content-refresh';
import Footer from '../../../Footer';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import languages from '../../../../assets/json/languages.json';
import Outlines from '../../../core/components/Outlines/Outlines';
import Mixpanel from '../../../../util/Mixpanel';
import { closeModal, openModal } from '../../../../actions/modal';
import { KEY_MESSAGES_MODAL } from '../../../../constants/modal';
import KeyMessagesModal from '../../../core/components/KeyMessages/KeyMessagesModal';
import * as types from '../../../../constants/content-refresh';

function ThirdStep({ form }) {
  const dispatch = useDispatch();

  const {
    loader,
    outlines,
    outlinesSerp,
    keyMessages,
    activeCard,
    serpContext,
  } = useSelector(state => state.contentRefresh);

  const languageCode = useMemo(() => {
    const value = languages.find((language) => form.values.language === language.id)?.crowdin_code;
    return value;
  }, [languages, form.values.language]);

  useEffect(() => {
    // Check if the 'keyMessages' array is not empty and if the 'keyMessages' form field is either null or an empty array.
    if (keyMessages.length && (!form.values.keyMessages || form.values.keyMessages.length === 0)) {
      // Update 'keyMessages' form field by filtering out non-selected messages and extracting 'message' property.
      form.setFieldValue('keyMessages', keyMessages.map(arr => arr.filter(x => x.selected).map(x => x.message)));
    }
  }, [keyMessages]);

  // Update the form field values for 'outlines' based on the 'outlines' redux value
  useEffect(() => {
    // Check if 'outlines' exists and has a length
    if (outlines?.length) {
      // Create new outlines array by mapping over 'outlines' and assigning unique IDs, sentences, and checked values
      const newOutlines = outlines.map((outline, index) => ({
        id: uniqueId(),
        sentence: outline,
        checked: index < 5,
      }));

      // Create new outlinesSerp array by mapping over 'outlinesSerp' and assigning unique IDs, sentences, and unchecked values
      const newOutlinesSerp = outlinesSerp.map((outline) => ({
        id: uniqueId(),
        sentence: outline,
        checked: false,
      }));

      // Update the form field value for 'outlines' by combining the new outlines and new outlinesSerp arrays
      form.setFieldValue('outlines', [...newOutlines, ...newOutlinesSerp]);
    }
  }, [outlines]);

  // Reset the form sections and values based on the given card number
  const resetToCard = cardNumber => {
    dispatch(setActiveCard(cardNumber));

    if (cardNumber <= 2) {
      dispatch(resetSections('keyMessages'));
      form.setFieldValue('keyMessages', []);
    }
  };

  // Function used to open a modal dialog for displaying key messages.
  const openKeyMessagesModal = (messages, title, index) => {
    dispatch(
      openModal(
        KEY_MESSAGES_MODAL,
        {
          messages: keyMessages[index],
          selectedMessages: messages,
          title,
          outlineIndex: index,
        },
      ),
    );
  };

  const saveKeyMessagesChanges = (allMessages, index) => {
    dispatch(closeModal(KEY_MESSAGES_MODAL));

    // Create a copy of the 'form.values.keyMessages' array and the 'keyMessages' array.
    const selectedMessages = [...form.values.keyMessages];
    const reducerMessages = [...keyMessages];

    // Update the 'selectedMessages' array at the specified 'index' with the selected messages from 'allMessages'.
    selectedMessages[index] = allMessages.filter(msg => msg.selected).map(msg => msg.message);

    // Update the 'reducerMessages' array at the specified 'index' with the modified 'allMessages' array.
    reducerMessages[index] = allMessages;

    // Set the form field 'keyMessages' to the updated 'selectedMessages' array.
    form.setFieldValue('keyMessages', selectedMessages);

    // Dispatch an action of type 'SET_KEY_MESSAGES' to update the state with the modified 'reducerMessages' array.
    dispatch({
      type: types.EXTRACT_KEY_MESSAGES,
      payload: reducerMessages,
    });
  };

  // Retrieve the checked outlines from the form values and extract the corresponding sentences
  const checkedOutlines = useMemo(() => {
    const value = form.values.outlines?.filter(outline => outline.checked).map((outline) => outline.sentence);
    return value;
  }, [form.values.outlines]);

  return (
    <>
      <KeyMessagesModal saveChanges={saveKeyMessagesChanges} />
      <Element
        name='step3_card1'
        className={styles.element}
      >
        <Card
          isLoading={activeCard === 1 && loader}
          completed={activeCard > 1}
          editAction={() => resetToCard(1)}
          title='Outlines'
          subtitle='Set the outlines of your article'
          content={(activeCard === 1 && outlines.length > 0) ? (
            <Field
              name='outlines'
              component={Outlines}
            />
          ) : (outlines.length ? (<span>View your outlines in the preview panel on the right</span>) : '')}
          actionBlock={(activeCard === 1) && (
            <NextButton
              disabled={!outlines.length}
              action={() => {
                dispatch(setActiveCard(2));
                dispatch(generateKeyMessages(
                  languageCode,
                  // form.values.input_source,
                  form.values.title,
                  form.values.summary,
                  checkedOutlines,
                  serpContext,
                ));
                Mixpanel.track('Content Refresh - Outlines - Select Paragraphs');
              }}
            />
          )}
        />
      </Element>
      <Element
        name='step3_card2'
        className={styles.element}
      >
        <Card
          isLoading={activeCard === 2 && loader}
          disabled={activeCard < 2}
          title='Key Messages'
          subtitle='Set key messages per paragraph.'
          content={(activeCard === 2 && keyMessages.length > 0 && !loader) && (
            <FieldArray
              name='keyMessages'
              render={() => (
                <div className={styles.paragraphs}>
                  {form.values.keyMessages && form.values.keyMessages.map((messages, index) => (
                    <Field
                      key={form.values.outlines[index].sentence}
                      name={`keyMessages.${index}`}
                      component={KeyMessages}
                      messages={messages}
                      title={`Paragraph ${index + 1}`}
                      label={form.values.outlines[index].sentence}
                      placeholder='Add more...'
                      info='Please note that the maximum number of characters for key sentences is 100.'
                      openModal={() => openKeyMessagesModal(messages, form.values.outlines[index].sentence, index)}
                    />
                  ))}
                </div>
              )}
            />
          )}
          actionBlock={
            activeCard === 2
            && (
              <PreviousButton
                action={() => {
                  dispatch(setActiveCard(1));
                  dispatch(resetSections('keyMessages'));
                  form.setFieldValue('keyMessages', []);
                }}
              />
            )
          }
        />
      </Element>
      <Footer>
        <StepperFooter
          nextDisabled={activeCard !== 2 || loader}
          nextStep={() => {
            dispatch(generateIntroduction(
              languageCode,
              form.values.subject,
              form.values.keywords,
              form.values.title,
              form.values.summary,
              checkedOutlines,
            ));
            dispatch(setActiveStep(4));
            dispatch(setActiveCard(1));
            Mixpanel.track('Content Refresh - Outlines - Select key messages');
          }}
          previousDisabled={false}
          previousStep={() => {
            dispatch(showPreviousStepWarning(() => {
              dispatch(setActiveStep(2));
              dispatch(setActiveCard(2));
              dispatch(resetSections('outlines'));
              form.setFieldValue('outlines', []);
            }));
          }}
          nextStepNumber={4}
          actionName='generate content'
        />
      </Footer>
    </>
  );
}

export default ThirdStep;
