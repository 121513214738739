import React from 'react';
import styles from './styles.module.scss';

function AuthPage({ children }) {
  return (
    <div className={styles.authPageContainer}>
      <div className={styles.widthContainer}>
        {children}
      </div>
    </div>
  );
}

export default AuthPage;
