import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';
import ProfilePictureWoman from '../../../../assets/images/profile-picture-woman.png';
import { Button } from '../../../core/components/Button/button';
import ReviewsBox from '../ReviewsBox';
import AuthPage from '../index';
import Separator from '../../../core/components/Separator/Separator';

function Welcome() {
  const navigate = useNavigate();

  return (
    <AuthPage>
      <div className={styles.largeLogo}>
        {renderIcon('contentooLogo')}
      </div>
      <ReviewsBox />
      <div className={styles.wrapper}>
        {/* Change image */}
        <img
          src={ProfilePictureWoman}
          alt='Profile'
        />
        <div className={styles.title}>
          Welcome!
        </div>
        <div className={styles.text}>
          Hi, my name is Lindsay. If you have any questions, please feel free to contact me!
        </div>
        <div className={styles.contactWrapper}>
          <div className={styles.contact}>
            +31 6 81 14 39 04
          </div>
          <div className={styles.contact}>
            lindsay@contentoo.com
          </div>
        </div>
        <Separator margin='40px 0' />
        <Button
          actionName='Open your dashboard'
          className={styles.button}
          action={() => navigate('/documents')}
        />
      </div>
    </AuthPage>
  );
}

export default Welcome;
