import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { toggleDropdown } from '../../../../actions/dropdown';

function Dropdown({
  buttonContent,
  buttonLabel,
  buttonIcon,
  buttonBorder,
  buttonHeight = '40px',
  content,
  position = 'left',
  maxHeight,
  minWidth,
  buttonBackgroundColor,
  className,
}) {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const dropdownActive = useSelector(state => state.dropdown.opened);

  const toggleLocalDropdown = () => {
    dispatch(toggleDropdown(!dropdownActive));
    setActive(!dropdownActive);
  };

  const closeDropdown = () => {
    dispatch(toggleDropdown(false));
    setActive(false);
  };

  useEffect(() => {
    if (!dropdownActive) {
      setActive(false);
    }
  }, [dropdownActive]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      {buttonContent
        ? (
          <span
            onMouseDown={toggleLocalDropdown}
            onKeyDown={toggleLocalDropdown}
            tabIndex='0'
            role='button'
          >
            {buttonContent}
          </span>
        ) : (
          <div
            className={styles.button}
            style={{ backgroundColor: buttonBackgroundColor, height: buttonHeight, border: buttonBorder }}
            onMouseDown={toggleLocalDropdown}
            onKeyDown={toggleLocalDropdown}
            tabIndex='0'
            role='button'
          >
            <span>{buttonLabel}</span>
            <span>{renderIcon(buttonIcon)}</span>
          </div>
        )}
      <OutsideClickHandler
        onOutsideClick={closeDropdown}
        disabled={!dropdownActive || !active}
      >
        {(dropdownActive && active) && (
          <div
            className={styles.options}
            style={{
              [position]: 0,
              maxHeight: maxHeight ? `${maxHeight}px` : undefined,
              minWidth: minWidth ? `${minWidth}px` : '220px',
            }}
          >
            {content}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
}

export default Dropdown;
