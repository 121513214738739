import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import styles from './styles.module.scss';

import LogoImage from '../../../assets/images/contentoo-logo-dark.png';
import { CloseButton } from '../../core/components/Button/button';

function StepperHeader({
  title, stepper, closeAction,
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className={styles.container}>
      <div
        role='button'
        tabIndex={0}
        className={styles.titleContainer}
        onClick={() => isMobile && closeAction()}
        onKeyDown={() => isMobile && closeAction()}
      >
        <img
          src={LogoImage}
          alt='contentoo'
          className={styles.logoImage}
        />
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.stepper}>{stepper}</div>
      <div className={styles.buttons}>
        <CloseButton onCloseClick={closeAction} />
      </div>
    </div>
  );
}

export default StepperHeader;
