import React from 'react';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import Modal from '../core/components/Modal';
import { NOT_FOUND_ERROR_MODAL } from '../../constants/modal';

function NotFoundErrorModal() {
  return (
    <Modal name={NOT_FOUND_ERROR_MODAL}>
      <div className={styles.modal}>
        <div className={styles.imageLarge}>
          {renderIcon('technicalError')}
        </div>
        <span className={styles.title}>
          Oops, something went wrong
        </span>
        <span className={styles.text}>
          I&apos;m sorry, but it seems that a technical error has occurred.
          Our team is currently working to resolve the issue as quickly as possible.
          <br />
          <br />
          Please try again later, or contact our customer support team if the problem persists.
          We apologize for any inconvenience this may have caused, and thank you for your patience.
        </span>
      </div>
    </Modal>
  );
}

export default NotFoundErrorModal;
