export const GENERATE_TITLES = 'GENERATE_TITLES_CONTENT_CREATION_NEW';
export const GENERATE_OUTLINES = 'GENERATE_OUTLINES_CONTENT_CREATION_NEW';
export const GENERATE_KEY_MESSAGES = 'GENERATE_KEY_MESSAGES_CONTENT_CREATION_NEW';
export const GENERATE_SUMMARY = 'GENERATE_SUMMARY_CONTENT_CREATION_NEW';
export const GENERATE_FULL_ARTICLE = 'GENERATE_FULL_ARTICLE_CONTENT_CREATION_NEW';
export const GENERATE_IN_DEPTH_INTRODUCTION = 'GENERATE_IN_DEPTH_INTRODUCTION_CONTENT_CREATION_NEW';
export const GENERATE_IN_DEPTH_PARAGRAPH = 'GENERATE_IN_DEPTH_PARAGRAPH_CONTENT_CREATION_NEW';
export const GENERATE_IN_DEPTH_CONCLUSION = 'GENERATE_IN_DEPTH_CONCLUSION_CONTENT_CREATION_NEW';
export const REGENERATE_PARAGRAPH = 'REGENERATE_PARAGRAPH_CONTENT_CREATION_NEW';
export const RESET_SECTIONS = 'RESET_SECTIONS_CONTENT_CREATION_NEW';
export const RESET_REDUCER = 'RESET_REDUCER_CONTENT_CREATION_NEW';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP_CONTENT_CREATION_NEW';
export const SET_SECTION_VALUE = 'SET_SECTION_VALUE_CONTENT_CREATION_NEW';
export const SET_ACTIVE_CARD = 'SET_ACTIVE_CARD_CONTENT_CREATION_NEW';
export const SHOW_LOADER = 'SHOW_LOADER_CONTENT_CREATION_NEW';
export const HIDE_LOADER = 'HIDE_LOADER_CONTENT_CREATION_NEW';
export const SET_PARAGRAPH = 'SET_PARAGRAPH_CONTENT_CREATION_NEW';
export const DELETE_PARAGRAPHS = 'DELETE_PARAGRAPHS_CONTENT_CREATION_NEW';
export const CHANGE_PARAGRAPH_TYPES = 'CHANGE_PARAGRAPH_TYPES_CONTENT_CREATION_NEW';
export const SET_INTRODUCTION = 'SET_INTRODUCTION_CONTENT_CREATION_NEW';
export const SET_CONCLUSION = 'SET_CONCLUSION_CONTENT_CREATION_NEW';
export const SET_SERP_CONTEXT = 'SET_SERP_CONTEXT_CONTENT_CREATION_NEW';
export const ADD_PARAGRAPH = 'ADD_PARAGRAPH_CONTENT_CREATION_NEW';
export const SET_PREVIOUS_PROGRESS_LOADER = 'SET_PREVIOUS_PROGRESS_LOADER_CONTENT_CREATION_NEW';
export const SET_STORE = 'SET_STORE_CONTENT_CREATION_NEW';
export const SET_DOWNLOAD_REQUESTED = 'SET_DOWNLOAD_REQUESTED_CONTENT_CREATION_NEW';
export const CONTINUE_HASH = 'CONTINUE_HASH_CONTENT_CREATION_NEW';
