import React from 'react';
import styles from './styles.module.scss';

function AuthFooter() {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.supportWrapper}>
        <span>Need help?</span>
        <span>support@contentoo.com</span>
      </div>
      <div className={styles.footerLogo}>
        contentoo
      </div>
    </div>
  );
}

export default AuthFooter;
