import React from 'react';
import classNames from 'classnames';
import { TooltipSettingsProps } from './interfaces';
import renderIcon from '../../../../util/renderIcon';
import styles from './TooltipSettings.module.scss';

export const TooltipSettings: React.FC<TooltipSettingsProps> = ({
  tag, upFunction, downFunction, deleteFunction, h2,
}) => (
  <div className={styles.toolTipWrapper}>
    <button
      type='button'
      className={classNames(styles.firstTagButton, {
        [styles.disabled]: !h2,
        [styles.active]: tag === 'H2',
      })}
      onClick={h2}
    >
      H2
    </button>
    <button
      type='button'
      className={classNames(styles.secondTagButton, { [styles.disabled]: !upFunction })}
    >
      H3
    </button>
    <button
      type='button'
      className={classNames(styles.thirdTagButton)}
    >
      H4
    </button>
    <button
      type='button'
      onClick={upFunction}
      className={classNames(styles.upButton, { [styles.disabled]: !upFunction })}
    >
      {renderIcon('arrowUpBold')}
    </button>
    <button
      type='button'
      onClick={downFunction}
      className={classNames(styles.downButton, { [styles.disabled]: !downFunction })}
    >
      {renderIcon('arrowDownBold')}
    </button>
    <button
      type='button'
      onClick={deleteFunction}
      className={styles.deleteButton}
    >
      {renderIcon('delete')}
    </button>
  </div>
);
