import FileDownload from 'js-file-download';
import * as cheerio from 'cheerio';
import { filter, uniq } from 'lodash';
import * as types from '../constants/content-refresh';
import { htmlToSections } from '../util/helpers';
import { openModal } from './modal';
import {
  AI_ERROR_MODAL,
  EXTRACTING_URL_ERROR_MODAL,
  INTERNET_CONNECTION_ERROR_MODAL,
  NOT_FOUND_ERROR_MODAL,
  PREVIOUS_STEP_WARNING_MODAL, URL_NOT_FOUND_ERROR_MODAL,
} from '../constants/modal';
import { client } from '../util/api';

import {
  PARAGRAPH_IN_DEPTH_LOADER,
  GENERATE_FULL_ARTICLE_LOADER,
} from '../constants/loaders-new';
import { apiUrl } from './content-creation-new';

const backendUrl = process.env.REACT_APP_API_URL;

export const showLoader = (name) => ({
  type: types.SHOW_LOADER,
  payload: name,
});

export const hideLoader = () => ({
  type: types.HIDE_LOADER,
});

export const toggleLoader = (toggleValue) => ({
  type: types.TOGGLE_LOADER,
  payload: toggleValue,
});

export const setDownloadRequested = () => ({
  type: types.SET_DOWNLOAD_REQUESTED,
});

export const resetReducer = () => ({
  type: types.RESET_REDUCER,
});

export const setInputSourceUrl = (url) => ({
  type: types.SET_INPUT_SOURCE_URL,
  payload: url,
});

export const setInputSourceType = (type) => ({
  type: types.SET_INPUT_SOURCE_TYPE,
  payload: type,
});

export const setUploadedFile = (file) => ({
  type: types.SET_UPLOADED_FILE,
  payload: file,
});

export const showError = (name = AI_ERROR_MODAL) => (dispatch) => {
  dispatch(openModal(name));
  dispatch(toggleLoader(false));
};

export const showPreviousStepWarning = (onConfirm) => (dispatch) => {
  dispatch(openModal(PREVIOUS_STEP_WARNING_MODAL, {
    onConfirm,
  }));
};

export function setPreviousProgressLoader(loaderActive) {
  return {
    type: types.SET_PREVIOUS_PROGRESS_LOADER,
    payload: loaderActive,
  };
}

export function setStore(store) {
  return {
    type: types.SET_STORE,
    payload: store,
  };
}

export function setActiveStep(step) {
  return {
    type: types.SET_ACTIVE_STEP,
    payload: step,
  };
}

export function setActiveCard(card) {
  return {
    type: types.SET_ACTIVE_CARD,
    payload: card,
  };
}

export function resetSections(...sections) {
  return {
    type: types.RESET_SECTIONS,
    payload: sections,
  };
}

export function setParagraph(title, text, index) {
  return {
    type: types.SET_PARAGRAPH,
    payload: {
      title,
      text,
      index,
    },
  };
}

export const addParagraph = (index, type) => ({
  type: types.ADD_PARAGRAPH,
  payload: {
    paragraph: {
      type,
      title: 'New paragraph title',
      text: 'New paragraph text',
    },
    index,
  },
});

export function deleteParagraphs(indexes) {
  return {
    type: types.DELETE_PARAGRAPHS,
    payload: indexes,
  };
}

export function changeParagraphTypes(indexes) {
  return {
    type: types.CHANGE_PARAGRAPH_TYPES,
    payload: indexes,
  };
}

export function setIntroduction(text) {
  return {
    type: types.SET_INTRODUCTION,
    payload: text,
  };
}

export function setConclusion(text) {
  return {
    type: types.SET_CONCLUSION,
    payload: text,
  };
}

const handleRequestFailures = (dispatch, error) => {
  if (error.response) {
    if (error.response.status === 404) {
      // show 404 error
      dispatch(showError(NOT_FOUND_ERROR_MODAL));
    } else {
      // show AI error
      dispatch(showError(AI_ERROR_MODAL));
    }
  } else {
    // show internet connection error
    dispatch(showError(INTERNET_CONNECTION_ERROR_MODAL));
  }
};

export function extractUrl(payload) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-refresh/extract-url`;
    dispatch(toggleLoader(true));

    dispatch(resetSections('preloadedInputSourceContent', 'informationBox'));

    client.post(myUrl, payload)
      .then(data => {
        if (data.data.data.status_code === 500) {
          if (data.data.data.message === 'invalid_url') {
            // show 404 error
            dispatch(showError(URL_NOT_FOUND_ERROR_MODAL));
          } else {
            dispatch(showError(EXTRACTING_URL_ERROR_MODAL));
            dispatch(setInputSourceType('text'));
          }
          return;
        }

        dispatch({
          type: types.EXTRACT_URL,
          payload: data.data.data.output,
        });

        dispatch({
          type: types.TOGGLE_INFORMATION_BOX,
          payload: true,
        });

        dispatch(toggleLoader(false));
      }).catch(() => {
        // show internet connection error
        dispatch(showError(INTERNET_CONNECTION_ERROR_MODAL));
      });
  };
}

export function extractSubject(languageCode, inputSource) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-refresh/extract-subject`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('subject'));

    const $ = cheerio.load(inputSource);

    const h3 = $('h3').first();

    const paragraphs = h3.nextUntil('h3', 'p');

    let userInput;

    if (h3.length > 0 && paragraphs.length > 0) {
      const title = h3.text();
      const paragraph = paragraphs.map((_, el) => $(el).text()).get().join('');

      userInput = `${title} ${paragraph}`;
    }

    client.post(myUrl, { target_language: languageCode, user_input: userInput })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          return;
        }

        dispatch({
          type: types.EXTRACT_SUBJECT,
          payload: (data.data.data.output || [])[0] || '',
        });

        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);

        dispatch(setActiveCard(2));
        dispatch(resetSections('subject'));
      });
  };
}

export function setSerpContext(subject, googleDomain) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/serp/context/`;

    dispatch(resetSections('serpContext'));

    // const location = `google.${countryCode.toLowerCase()}`;
    const location = googleDomain;

    client.post(myUrl, {
      subject, location,
    })
      .then(data => {
        dispatch({
          type: types.SET_SERP_CONTEXT,
          payload: data.data.data.output || '',
        });
      }).catch(() => {
        dispatch({
          type: types.SET_SERP_CONTEXT,
          payload: '',
        });
      });
  };
}

export function extractKeywords(languageCode, inputSource) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-refresh/extract-keywords`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('keywords'));

    client.post(myUrl, {
      target_language: languageCode, section: htmlToSections(inputSource), mode: 1,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(3));
          dispatch(resetSections('keywords'));
          return;
        }

        dispatch({
          type: types.EXTRACT_KEYWORDS,
          payload: data.data.data.output,
        });

        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);

        dispatch(setActiveCard(3));
        dispatch(resetSections('keywords'));
      });
  };
}

export function generateTitles(languageCode, subject, keywords, mainKeyword, setLoader) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-creation/generate-titles`;

    dispatch(resetSections('titles'));

    client.post(myUrl, {
      subject, keywords, main_keyword: mainKeyword, target_language: languageCode,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(1));
          dispatch(resetSections('titles'));
          return;
        }

        setLoader(false);

        dispatch({
          type: types.GENERATE_TITLES,
          payload: data.data.data.output,
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);

        setLoader(false);

        dispatch(setActiveCard(1));
        dispatch(resetSections('titles'));
      });
  };
}

export function generateParagraphInDepth(
  index,
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  paragraphToWrite,
  paragraphContent,
  keyMessages = [],
) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-creation/generate-paragraph-in-depth`;

    dispatch(showLoader(`${PARAGRAPH_IN_DEPTH_LOADER}_${index}`));

    client.post(myUrl, {
      subject,
      main_keyword: mainKeyword,
      keywords,
      title,
      target_language: languageCode,
      paragraph_to_write: paragraphToWrite,
      paragraph_content: paragraphContent,
      key_messages: keyMessages,
    })
      .then(data => {
        dispatch(hideLoader());

        if (data.data.data.success === false) {
          dispatch(showError());
          return;
        }

        dispatch({
          type: types.GENERATE_IN_DEPTH_PARAGRAPH,
          payload: {
            text: data.data.data.output,
            index,
          },
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
      });
  };
}

export const generateParagraphs = async (
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  toneOfVoice,
  outlines,
  paragraphToWrite,
  keyMessages = [],
) => {
  const url = `${backendUrl}/content-creation/generate-h3h4-paragraph`;

  const data = await client.post(url, {
    subject,
    main_keyword: mainKeyword,
    keywords,
    title,
    tov: toneOfVoice,
    outlines,
    target_language: languageCode,
    paragraph_to_write: paragraphToWrite,
    key_messages: keyMessages,
  });

  return data.data.data.output.map(paragraph => paragraph.replace(/^\s+|\n/g, ''));
};

export function extractSummary(languageCode, inputSource, serpContext = false) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-refresh/extract-summary`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('summaries'));

    const postData = {
      target_language: languageCode, section: htmlToSections(inputSource),
    };

    if (serpContext) {
      postData.get_serp_data = true;
      postData.serp_context = serpContext;
    }

    client.post(myUrl, postData)
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          return;
        }

        dispatch({
          type: types.EXTRACT_SUMMARY,
          payload: uniq(filter([
            ...data.data.data.output,
            data.data.data?.serpOutput || null,
          ])),
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);

        dispatch(setActiveCard(1));
        dispatch(resetSections('summaries'));
      });
  };
}

export function extractWholeArticle(subject, mainKeyword, keywords, article) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-refresh/extract-whole-article`;
    dispatch(showLoader(GENERATE_FULL_ARTICLE_LOADER));

    const postData = {
      subject, main_keyword: mainKeyword, keywords, article,
    };

    client.post(myUrl, postData)
      .then(data => {
        dispatch(hideLoader());
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveStep(2));
          dispatch(setActiveCard(3));
          dispatch(resetSections('outlines'));
          return;
        }

        const modifiedArticle = data.data.data.output.outlines.reduce((result, item) => {
          if (item.h2_title) {
            result.push({
              type: 'h2',
              title: item.h2_title,
              text: item.h2_content.trim(),
            });
          }

          if (item.h3_subheadings) {
            item.h3_subheadings.forEach((h3Item) => {
              if (h3Item.h3_title) {
                result.push({
                  type: 'h3',
                  title: h3Item.h3_title,
                  text: h3Item.h3_content.trim(),
                });
              }

              if (h3Item.h4_subheadings) {
                h3Item.h4_subheadings.forEach((h4Item) => {
                  if (h4Item.h4_title) {
                    result.push({
                      type: 'h4',
                      title: h4Item.h4_title,
                      text: h4Item.h4_content.trim(),
                    });
                  }
                });
              }
            });
          }

          return result;
        }, []);

        const introduction = modifiedArticle[0].text;
        const conclusion = modifiedArticle[modifiedArticle.length - 1].text;
        const paragraphs = modifiedArticle;

        dispatch({
          type: types.EXTRACT_WHOLE_ARTICLE,
          payload: {
            introduction,
            conclusion,
            paragraphs,
          },
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveStep(2));
        dispatch(setActiveCard(4));
        dispatch(resetSections('outlines'));
      });
  };
}

export function extractOutline(languageCode, subject, inputSource, serpContext) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-refresh/extract-outline`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('outlines'));

    const postData = {
      section: htmlToSections(inputSource), subject, translation: false, target_language: languageCode,
    };

    if (serpContext) {
      postData.get_serp_data = true;
      postData.serp_context = serpContext;
    }

    client.post(myUrl, postData)
      .then(data => {
        dispatch(toggleLoader(false));

        if (data.data.data.success === false) {
          dispatch(showError());
          return;
        }

        const filteredOutlines = data.data.data.output.filter((outline) => outline.trim() !== '');

        dispatch({
          type: types.EXTRACT_OUTLINES,
          payload: {
            outlines: filteredOutlines,
            outlinesSerp: data.data.data.serpOutput || [],
          },
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveStep(2));
        dispatch(setActiveCard(2));
        dispatch(resetSections('outlines'));
      });
  };
}

// export function extractKeyMessages(languageCode, inputSource, title, outlines, summary, serpContext = null) {
//   return (dispatch) => {
//     const myUrl = `${backendUrl}/content-refresh/extract-key-messages`;
//
//     const postData = {
//       section: htmlToSections(inputSource),
//       is_generate: false,
//       title,
//       target_language: languageCode,
//       generated_outline: outlines,
//       summary,
//     };
//
//     if (serpContext) {
//       postData.get_serp_data = true;
//       postData.serp_context = serpContext;
//     }
//
//         dispatch(toggleLoader(true));
//
//     client.post(myUrl, postData)
//       .then(data => {
//         if (data.data.data.success === false) {
//           dispatch(showError());
//           dispatch(setActiveCard(2));
//           dispatch(resetSection('outlines'));
//           return;
//         }
//         const keyMessages = data.data.data.output;
//         const serpMessages = data.data.data.serpOutput;
//         let myArr = [];
//
//         if (serpContext) {
//           myArr = keyMessages.map((x, index) => [...x, ...serpMessages[index]]);
//         } else myArr = keyMessages;
//
//         dispatch({
//           type: types.EXTRACT_KEY_MESSAGES,
//           payload: myArr.map(ar => ar.map((x, index) => ({ message: x, deletable: false, selected: index < 5 }))),
//         });
//        dispatch(toggleLoader(false));
//       }).catch((error) => {
//         handleRequestFailures(dispatch, error);
//
//         dispatch(setActiveCard(2));
//         dispatch(resetSection('outlines'));
//       });
//   };
// }

export function generateKeyMessages(languageCode, title, summary, outlines, serpContext = false) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-creation/generate-key-messages`;

    dispatch(toggleLoader(true));

    const postData = {
      target_language: languageCode, title, summary, generated_outline: outlines,
    };

    if (serpContext) {
      postData.get_serp_data = true;
      postData.serp_context = serpContext;
    }

    client.post(myUrl, postData)
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(5));
          dispatch(resetSections('outlines'));
          return;
        }

        const keyMessages = data.data.data.output;
        const serpMessages = data.data.data.serpOutput;

        let myArr = [];

        if (serpMessages) {
          myArr = keyMessages.map((x, index) => [...x, ...serpMessages[index]]);
        } else {
          myArr = keyMessages;
        }

        dispatch({
          type: types.EXTRACT_KEY_MESSAGES,
          payload: myArr.map(ar => ar.map((x, index) => ({ message: x, deletable: false, selected: index < 5 }))),
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveCard(5));
        dispatch(resetSections('outlines'));
      });
  };
}

export function generateIntroduction(languageCode, subject, keywords, title, summary, outlines) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-creation/generate-introduction`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('introductions'));

    client.post(myUrl, {
      subject, keywords, title, summary, outlines, target_language: languageCode,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveStep(2));
          dispatch(setActiveCard(3));
          dispatch(resetSections('introductions'));
          return;
        }

        dispatch({
          type: types.GENERATE_INTRODUCTIONS,
          payload: data.data.data.output,
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);

        dispatch(setActiveStep(3));
        dispatch(setActiveCard(2));
        dispatch(resetSections('introductions'));
      });
  };
}

export function extractParagraph(
  languageCode,
  subject,
  keywords,
  title,
  summary,
  outlines,
  introduction,
  paragraphToWrite,
  outlineContent,
  keyMessages,
) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-creation/generate-paragraph`;

    dispatch(toggleLoader(true));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      keywords,
      title,
      summary,
      outlines,
      introduction,
      paragraph_to_write: paragraphToWrite,
      outline_content: outlineContent,
      key_messages: keyMessages,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(1));
          dispatch(resetSections('paragraphs'));
          return;
        }

        const filteredParagraphs = data.data.data.output.filter((paragraph) => paragraph.trim() !== '');

        dispatch({
          type: types.EXTRACT_PARAGRAPH,
          payload: filteredParagraphs,
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);

        dispatch(setActiveCard(1));
        dispatch(resetSections('paragraphs'));
      });
  };
}

export function extractAndReplaceParagraph(
  paragraphIndex,
  languageCode,
  subject,
  keywords,
  title,
  summary,
  outlines,
  introduction,
  paragraphToWrite,
  outlineContent,
  keyMessages,
) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-creation/generate-paragraph`;

    dispatch(toggleLoader(true));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      keywords,
      title,
      summary,
      outlines,
      introduction,
      paragraph_to_write: paragraphToWrite,
      outline_content: outlineContent,
      key_messages: keyMessages,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(1));
          dispatch(resetSections('paragraphs'));
          return;
        }

        const filteredParagraphs = data.data.data.output.filter((paragraph) => paragraph.trim() !== '');

        dispatch({
          type: types.EXTRACT_AND_REPLACE_PARAGRAPH,
          payload: {
            output: filteredParagraphs,
            paragraphIndex,
          },
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveCard(1));
        dispatch(resetSections('paragraphs'));
      });
  };
}

export function extractConclusion(
  languageCode,

  subject,
  keywords,
  title,
  summary,
  outlines,
  introduction,
  outlineContent,
  keyMessages,
) {
  return (dispatch) => {
    const myUrl = `${backendUrl}/content-creation/generate-conclusion`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('conclusions'));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      keywords,
      title,
      summary,
      outlines,
      introduction,
      outline_content: outlineContent,
      key_messages: keyMessages,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(1));
          dispatch(resetSections('conclusions'));
          return;
        }

        dispatch({
          type: types.EXTRACT_CONCLUSION,
          payload: data.data.data.output,
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveCard(1 + outlines.length));
        dispatch(resetSections('conclusions'));
      });
  };
}

export function setSectionValue(section, value) {
  return {
    type: types.SET_SECTION_VALUE,
    payload: {
      section,
      value,
    },
  };
}

export const storeProgressAndSendBackendErrorEmail = async (email, store, values, continueHash = '') => {
  const storeProgressUrl = `${backendUrl}/continue-flow/store-current-flow`;
  const sendEmailUrl = `${backendUrl}/mail/open-ai-error`;

  const data = {
    data: JSON.stringify({
      store,
      values,
    }),
    document_type: 'refresh',
  };

  if (continueHash !== '') {
    data.hash = continueHash;
  }

  await client.post(storeProgressUrl, data);

  return client.post(sendEmailUrl, {
    document_type: 'refresh',
    email,
  });
};

// export const storeProgressAndSendExitEmail = async (email = null, store, values, continueHash = '') => {
//   const storeProgressUrl = `${backendUrl}/continue-flow/store-current-flow`;
//   const sendEmailUrl = `${backendUrl}/mail/left-flow`;
//
//   const data = {
//     data: JSON.stringify({
//       store,
//       values,
//     }),
//     document_type: 'refresh',
//   };
//
//   if (continueHash !== '') {
//     data.hash = continueHash;
//   }
//
//   await client.post(storeProgressUrl, data);
//
//   if (email) {
//     await client.post(sendEmailUrl, {
//       document_type: 'refresh',
//       email,
//     });
//   }
// };

export const leftFlow = async (email, continueHash) => {
  const myUrl = `${apiUrl}/mail/left-flow`;

  if (continueHash !== '') {
    await client.post(myUrl, {
      document_type: 'refresh',
      email,
      hash: continueHash,
    });
  }
};

export const storeCurrentFlow = async (store, values, email, providedHash) => {
  const myUrl = `${apiUrl}/continue-flow/store-current-flow`;

  const myData = {
    data: JSON.stringify({
      store,
      values,
    }),
    document_type: 'refresh',
    hash: providedHash,
  };

  const response = await client.post(myUrl, myData);

  const hashToUse = providedHash || response.data.hash;

  return leftFlow(email, hashToUse);
};

export const restorePreviousProgress = (continueHash) => {
  const myUrl = `${backendUrl}/continue-flow/continue/${continueHash}`;

  return client.get(myUrl);
};

export function saveContentRefreshFile(text, continueHash = null) {
  const myUrl = `${backendUrl}/finished_documents/`;

  const requestData = {
    text,
    document_type: 'refresh',
  };

  if (continueHash) {
    requestData.hash = continueHash;
  }

  return client.post(myUrl, requestData);
}

export function downloadContentRefreshFile(title, paragraphs, email, continueHash = null) {
  return () => {
    const myUrl = `${backendUrl}/download-h3h4-file`;

    // const paragraphHeadings = paragraphs.map(paragraph => paragraph.title);

    // const paragraphsText = paragraphs.map(paragraph => paragraph.text);

    const requestData = {
      title,
      paragraphs,
      email,
      document_type: 'creation',
    };

    if (continueHash) {
      requestData.hash = continueHash;
    }

    client.post(myUrl, requestData, {
      responseType: 'blob',
    })
      .then(data => {
        FileDownload(data.data, `${title}.docx`);
      });
  };
}
