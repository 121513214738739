import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';

function Stepper({
  steps,
  activeStep,
  isDetail,
}) {
  return (
    <div className={styles.container}>
      {!isDetail ? steps.map(step => (
        <div
          className={classNames(styles.step, {
            [styles.active]: activeStep === step.id,
            [styles.finished]: activeStep > step.id,
          })}
          key={step.id + step.title}
        >
          <div
            className={styles.button}
            data-tooltip-id='tooltip'
            data-tooltip-content={activeStep !== step.id ? step.title : ''}
          >
            {step.id}
          </div>
          {activeStep === step.id
            && <span className={styles.title}>{step.title}</span>}
        </div>
      )) : (
        <div className={styles.titleFinal}>
          <div className={styles.checkMark}>
            {renderIcon('greenChecked')}
          </div>
          Your blog is finished and added to your library
        </div>
      ) }
    </div>
  );
}

export default Stepper;
