import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { range } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AppHeader from '../../Header/AppHeader';
import styles from './styles.module.scss';
import renderIcon from '../../../util/renderIcon';
import Table from '../../core/components/Table/Table';
import mockup from './mockup';
import MultiSelect from '../../core/components/MultiSelect/MultiSelect';
import Dropdown from '../../core/components/Dropdown/Dropdown';
import { CONFIRM_DELETING_DOCUMENT_MODAL, EDIT_ARTICLE_MODAL } from '../../../constants/modal';
import { openModal } from '../../../actions/modal';
import EditArticleModal from '../../Modals/EditArticleModal';
import { listFinishedDocuments } from '../../../actions/documents';
import ConfirmDeletingDocument from '../../Modals/ConfirmDeletingDocument';

function Documents() {
  const [activeActionsDropdown, setActiveActionsDropdown] = useState(null);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sort, setSort] = useState('newest');
  const [limit, setLimit] = useState(10);
  const [selectedTools, setSelectedTools] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const documents = useSelector((state) => state.documents);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reloadDocuments = () => {
    dispatch(listFinishedDocuments({
      sort,
      limit,
      currentPage: documents.currentPage,
      tools: selectedTools,
      statuses: selectedStatuses,
      search: searchQuery,
      navigate,
    }));
  };

  useEffect(() => {
    reloadDocuments();
  }, [limit, sort, documents.currentPage, selectedTools, selectedStatuses, searchQuery]);

  const columns = [
    {
      Header: 'name',
      accessor: 'name',
      width: 800,
    },
    {
      Header: 'tool',
      accessor: 'tool',
      width: 120,
    },
    {
      Header: 'status',
      accessor: 'status',
      width: 150,
    },
    {
      Header: '',
      accessor: 'download',
      width: 50,
    },
    {
      Header: '',
      accessor: 'more',
      width: 50,
    },
  ];

  const toolIconMappings = {
    creation: renderIcon('creationFlow'),
    refresh: renderIcon('refreshFlow'),
  };

  const totalStepsMapping = {
    creation: 4,
    refresh: 5,
  };

  const sortOptions = [
    {
      value: 'newest',
      label: 'newest on top',
    },
    {
      value: 'oldest',
      label: 'oldest on top',
    },
  ];

  const limitOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  const data = useMemo(() => {
    const filteredData = documents.data;

    console.log(filteredData);

    const routeMapping = {
      refresh: '/content-refresh',
      creation: '/content-creation',
    };

    return filteredData.map((row) => {
      const parsedData = JSON.parse(row.data);

      const parsedName = parsedData?.values?.title;
      let name = '';

      if (row.name) {
        name = row.name;
        const matches = row.name.match(new RegExp(/^(.*?)-\s*(\d{4}-\d{2}-\d{2})/));
        name = (matches?.[1] || '').trim();
      } else if (parsedName) {
        name = parsedName;
      } else {
        name = <span className={styles.wip}>Your document is a work in progress. Keep going and finish your masterpiece!</span>;
      }

      const totalSteps = totalStepsMapping[row.tool];
      const stepsCompleted = (row.document_status === 'in-progress') ? Math.max((parsedData?.store?.activeStep || 0) - 1, 0) : totalSteps;

      return ({
        name: (
          <div className={styles.nameColumn}>
            {row.document_status === 'in-progress' ? (
              <div className={styles.nameColumnWrapper}>
                <span className={styles.draft}>Draft</span>
                <span>{name}</span>
              </div>
            ) : (
              <span>{name}</span>
            )}
          </div>
        ),
        tool: <span className={styles.toolIcon}>{toolIconMappings[row.tool]}</span>,
        status: (
          <div className={styles.statusColumn}>
            {row.document_status === 'in-progress' ? (
              <span
                tabIndex={0}
                role='button'
                className={styles.statusContinueButton}
                onClick={() => navigate(`${routeMapping[row.tool]}?continue_hash=${row.hash}`)}
                onKeyDown={() => navigate(`${routeMapping[row.tool]}?continue_hash=${row.hash}`)}
              >
                continue
              </span>
            ) : (
              <span className={styles.status}>Finished</span>
            )}
            <div className={styles.progressBarWrapper}>
              {range(stepsCompleted).map(() => (
                <div className={classNames(styles.progressBarItem, styles.active)} />
              ))}
              {range(totalSteps - stepsCompleted).map(() => (
                <div className={styles.progressBarItem} />
              ))}
            </div>
          </div>
        ),
        download: row.document_status === 'in-progress'
          ? ''
          : (
            <a
              className={classNames(styles.downloadButton, styles.active)}
              href={row.download_url}
              target='_blank'
              rel='noreferrer'
            >
              {renderIcon('downloadRounded')}
            </a>
          ),
        more: (
          <div
            role='button'
            tabIndex={0}
            className={classNames(styles.moreButton)}
            onKeyDown={() => setActiveActionsDropdown(activeActionsDropdown === row.id ? null : row.id)}
            onClick={() => setActiveActionsDropdown(activeActionsDropdown === row.id ? null : row.id)}

          >
            <Dropdown
              buttonContent={renderIcon('threeDots')}
              onlyIcon
              position='right'
              minWidth='140'
              content={(
                <>
                  {(row.document_status !== 'in-progress') && (
                  <div
                    role='button'
                    tabIndex={0}
                    className={styles.option}
                    onKeyDown={() => dispatch(openModal(EDIT_ARTICLE_MODAL, row.id))}
                    onClick={() => {
                      dispatch(openModal(EDIT_ARTICLE_MODAL, row.id));
                    }}
                  >
                    View/Edit
                  </div>
                  )}
                  <div
                    tabIndex={0}
                    role='button'
                    className={classNames(styles.option, styles.delete)}
                    onClick={() => {
                      // noinspection JSUnusedGlobalSymbols
                      dispatch(openModal(CONFIRM_DELETING_DOCUMENT_MODAL, {
                        row,
                        onDelete: () => reloadDocuments(), // Used in ConfirmDeletingDocument
                      }));
                    }}
                    onKeyDown={
                      () => {
                        // noinspection JSUnusedGlobalSymbols
                        dispatch(openModal(CONFIRM_DELETING_DOCUMENT_MODAL, {
                          row,
                          onDelete: () => reloadDocuments(), // Used in ConfirmDeletingDocument
                        }));
                      }
                    }
                  >
                    Delete
                  </div>
                </>
              )}
            />
          </div>
        ),
      });
    });
  }, [documents, activeActionsDropdown, searchQuery, selectedTools, selectedStatuses, sort, limit, documents.currentPage]);

  const handleSortOptionClick = (value) => {
    setSort(value);
  };

  return (
    <>
      <AppHeader />
      <div className={styles.wrapper}>
        <div className={styles.filtersContainer}>
          <div className={styles.filtersGroup}>
            <MultiSelect
              label='tool'
              options={mockup.tools}
              onChangeSelectedValues={(newSelectedValues) => setSelectedTools(newSelectedValues)}
              selectedValues={selectedTools}
            />
            <MultiSelect
              label='status'
              options={[
                {
                  label: 'Finished',
                  value: 'finished',
                },
                {
                  label: 'In progress',
                  value: 'in-progress',
                },
              ]}
              onChangeSelectedValues={setSelectedStatuses}
              selectedValues={selectedStatuses}
            />
          </div>
          <div className={styles.filtersGroup}>
            <Dropdown
              buttonLabel='create new'
              buttonIcon='arrowDown'
              buttonBackgroundColor='#FFE403'
              content={(
                <>
                  <div
                    tabIndex={0}
                    role='button'
                    className={styles.option}
                    onKeyDown={() => navigate('/content-refresh')}
                    onClick={() => navigate('/content-refresh')}
                  >
                    AI Content Refresh
                  </div>
                  <div
                    tabIndex={0}
                    role='button'
                    className={styles.option}
                    onKeyDown={() => navigate('/content-creation')}
                    onClick={() => navigate('/content-creation')}
                  >
                    AI Content Creation
                  </div>
                </>
              )}
            />
            <div className={classNames(styles.filter, styles.square)}>
              {activeSearch ? (
                <div className={classNames(styles.search, styles.active)}>
                  <input
                    placeholder='search'
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.currentTarget.value)}
                  />
                  <span
                    role='button'
                    tabIndex={0}
                    onKeyDown={() => setActiveSearch(false)}
                    onClick={() => setActiveSearch(false)}
                  >
                    {renderIcon('searchNew')}
                  </span>
                </div>
              ) : (
                <span
                  role='button'
                  tabIndex={0}
                  onKeyDown={() => setActiveSearch(true)}
                  onClick={() => setActiveSearch(true)}
                >
                  {renderIcon('searchNew')}
                </span>
              )}
            </div>
            <Dropdown
              buttonLabel='view'
              position='right'
              buttonIcon='arrowDown'
              buttonBorder='1px solid #e7e7e7'
              content={(
                <>
                  <div>
                    <span className={styles.groupTitle}>Sort</span>
                    {sortOptions.map((sortOption) => (
                      <div
                        role='button'
                        tabIndex={0}
                        className={styles.option}
                        key={sortOption.value}
                        onKeyDown={() => handleSortOptionClick(sortOption.value)}
                        onClick={() => handleSortOptionClick(sortOption.value)}
                      >
                        <div
                          className={classNames(styles.checkbox, { [styles.active]: sort === sortOption.value })}
                        />
                        <span>{sortOption.label}</span>
                      </div>
                    ))}
                  </div>
                  <div>
                    <span className={styles.groupTitle}>Show</span>
                    {limitOptions.map((showOption) => (
                      <div
                        role='button'
                        tabIndex={0}
                        className={styles.option}
                        key={showOption.value}
                        onKeyDown={() => setLimit(showOption.value)}
                        onClick={() => setLimit(showOption.value)}
                      >
                        <div
                          className={classNames(styles.checkbox, { [styles.active]: limit === showOption.value })}
                        />
                        <span>{showOption.label}</span>
                      </div>
                    ))}
                  </div>
                </>
             )}
            />
          </div>
        </div>
        <div className={styles.filtersLabels}>
          {documents.total === null ? '' : `${documents.total} documents`}
        </div>
        <div className={styles.documentsTable}>
          <Table
            columns={columns}
            data={data}
            limit={limit}
            total={documents.total}
            currentPage={documents.currentPage}
          />
          <EditArticleModal />
          <ConfirmDeletingDocument />
        </div>
      </div>
    </>
  );
}

export default Documents;
