import React, { useEffect, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import renderIcon from '../../../../util/renderIcon';
import styles from './styles.module.scss';
import { closeModal } from '../../../../actions/modal';
import { ModalProps } from './interfaces';
import { RootState } from '../../../../reducers';

const Index: React.FC<ModalProps> = ({
  name, onClose, children, modalWidth = '500px',
}) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
    onClose && onClose();
  };

  useEffect(() => {
    // Function to handle the Escape key press event
    const handleEscapeKey = (e: KeyboardEvent) => e.code === 'Escape' && handleCloseModal();

    // Add event listener for the keydown event on the document
    document.addEventListener('keydown', handleEscapeKey);

    // Clean up by removing the event listener when the component unmounts or when the dependency array is empty
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, []);

  const currentOpenedName = useSelector((state: RootState) => state.modal.name);

  // Memoized value to determine if the component is currently opened
  const isOpened = useMemo(() => name === currentOpenedName, [name, currentOpenedName]);

  return isOpened ? (
    <div className={styles.modalOverlay}>
      <div className={styles.wrapper}>
        <div className={styles.close}>
          {renderIcon('close')}
        </div>
        <OutsideClickHandler
          onOutsideClick={handleCloseModal}
        >
          <div
            className={styles.modal}
            style={{ width: modalWidth }}
          >
            {children}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  ) : null;
};
export default Index;
