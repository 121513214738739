import React from 'react';
import classNames from 'classnames';
import styles from './ToolCardNew.module.scss';
import { Button } from '../Button/button';
import renderIcon from '../../../../util/renderIcon';
import { ToolCardProps } from './interfaces';

const ToolCardNew: React.FC<ToolCardProps> = ({
  icon,
  iconBackgroundColor,
  label,
  labelColor,
  title,
  description,
  list,
  primaryButtonName,
  primaryAction,
  disabled,
  rightIcon,
  linkText,
  linkIcon,
  iconStyles,
}) => (
  <div className={classNames(styles.card, { [styles.disabled]: disabled })}>
    <div className={styles.toolCardHeader}>
      <div
        className={classNames(styles.icon, iconStyles)}
        style={{ backgroundColor: iconBackgroundColor }}
      >
        {renderIcon(icon)}
      </div>
      <div className={styles.titleContainer}>
        <div
          className={styles.label}
          style={{ color: labelColor }}
        >
          {label}
        </div>
        <div className={styles.title}>
          {title}
        </div>
      </div>
    </div>
    <div className={styles.description}>
      {description}
    </div>
    <div className={styles.listWrapper}>
      {list.map((listText) => (
        <div className={styles.listItem}>
          <div className={styles.checkMarkIcon}>
            {renderIcon('greenChecked')}
          </div>
          <div className={styles.listText}>
            {listText}
          </div>
        </div>
      ))}
    </div>
    <Button
      className={styles.actionButton}
      actionName={primaryButtonName}
      action={primaryAction}
      disabled={disabled}
      rightIcon={rightIcon}
    />
    <div className={styles.link}>
      <div className={styles.linkText}>
        {linkText}
      </div>
      {renderIcon(linkIcon)}
    </div>
  </div>
);

export default ToolCardNew;
