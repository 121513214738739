import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IFrameDocumentsTable from './IFrameDocumentsTable';
import styles from './styles.module.scss';
import IFrameTools from '../IFrameTools';
import { whoami } from '../../../actions/auth';
import { setToken } from '../../../util/api';
// import { RootState } from '../../../reducers';

function DocumentsIFrame() {
  const [showMenuOption, setShowMenuOption] = useState(1);
  const onClickHandler = (option) => { setShowMenuOption(option); };
  const urlParams = new URLSearchParams(window.location.search);
  const iframeTokenCoded = urlParams.get('iframeTokenCoded');
  const dispatch = useDispatch();

  useEffect(() => { dispatch(whoami(iframeTokenCoded)); }, []);

  const myToken = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (myToken && myToken.user && myToken.user.myToken) {
      setToken(myToken.user.myToken);
    } else {
      console.info('Token is not available', myToken);
    }
  }, [myToken]);

  return (
    <>
      <div className={styles.header}>
        <button
          type='button'
          className={showMenuOption === 1 ? styles.active : styles.option}
          onClick={() => { onClickHandler(1); }}
        >
          tools
        </button>
        <button
          type='button'
          className={showMenuOption === 2 ? styles.active : styles.option}
          onClick={() => { onClickHandler(2); }}
        >
          my library
        </button>
      </div>
      {showMenuOption === 1 ? <IFrameTools /> : <IFrameDocumentsTable />}
    </>
  );
}

export default DocumentsIFrame;
