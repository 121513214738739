import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import styles from './styles.module.scss';
import ProfilePictureMan from '../../../../assets/images/profile-picture.jpeg';
import ProfilePictureMantwo from '../../../../assets/images/profile-picture2.jpeg';
import renderIcon from '../../../../util/renderIcon';

function ReviewsBox() {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const [currentReview, setCurrentReview] = useState(0);

  const reviews = [
    {
      image: ProfilePictureMan,
      name: 'Wendy van Meer',
      company: 'International Marketing Manager at EIFFEL',
      description: `
        I like the easy, step-by-step approach to creating instructions for the AI to generate content.
        It helps me structure the content of the webpage and
        it's much easier to use Contentoo AI than to use ChatGPT.
      `,
    },
    {
      image: ProfilePictureMantwo,
      name: 'Kjeld Oostra',
      company: 'AI Consultant at Entropical',
      description: `
        Contentoo AI really differentiates itself from alternatives like ChatGPT by adding unique insights from external data sources.
        Because of this, the knowledge base is up-to-date so
        I can generate relevant blog posts.
      `,
    },
    // Add more reviews here
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 15000); // Change every 15s

    return () => clearInterval(interval);
  }, [reviews.length]);

  const goToPrevious = () => {
    setCurrentReview((prev) => (prev - 1 + reviews.length) % reviews.length);
  };

  const goToNext = () => {
    setCurrentReview((prev) => (prev + 1) % reviews.length);
  };

  return (
    <div>
      {!isMobile && (
        <div className={styles.boxWrapper}>
          <div className={styles.box}>
            <div className={styles.image}>
              <img
                src={reviews[currentReview].image}
                alt='Profile'
              />
            </div>
            <div className={styles.name}>{reviews[currentReview].name}</div>
            <div className={styles.company}>{reviews[currentReview].company}</div>
            <div className={styles.description}>
              {`‘’${reviews[currentReview].description}’’`}
            </div>
          </div>
          <div className={styles.arrowsWrapper}>
            <button
              type='button'
              className={styles.arrow}
              onClick={goToPrevious}
            >
              {renderIcon('arrowPrevious')}
            </button>
            <button
              type='button'
              className={styles.arrow}
              onClick={goToNext}
            >
              {renderIcon('arrowNext')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReviewsBox;
