import * as types from '../constants/content-refresh';

interface FooterAction {
  disabled: boolean;
  onClick: () => void;
}

export interface Paragraph {
  title: string,
  text: string,
  type?: string,
}

export interface ContentRefreshState {
  activeStep: number;
  activeCard: number;
  inputSourceType: 'doc' | 'web' | 'text' | string;
  inputSourceUrl: string;
  preloadedInputSourceContent: null | string;
  subject: null | string;
  keywords: null | string[];
  titles: string[];
  summary: string[];
  outlines: string[];
  outlinesSerp: string[];
  keyMessages: string[];
  introductions: string[];
  paragraphs: Paragraph[];
  conclusions: string[];
  informationBox: boolean;
  uploadedFile: null | File;
  footerActions: {
    next: FooterAction;
    previous: FooterAction;
  };
  downloadRequested: boolean;
  previousProgressLoader: boolean;
  serpContext: null | string;
  article: string;
  introduction: string;
  conclusion: string;
  loader: null | string;
}

const initialState = {
  activeStep: 1,
  activeCard: 1,
  inputSourceType: 'web',
  inputSourceUrl: '',
  preloadedInputSourceContent: null,
  subject: null,
  keywords: null,
  titles: [],
  summary: [],
  outlines: [],
  outlinesSerp: [],
  keyMessages: [],
  introductions: [],
  paragraphs: [],
  conclusions: [],
  loader: null,
  informationBox: false,
  uploadedFile: null,
  footerActions: {
    next: {
      disabled: true,
      onClick: () => {},
    },
    previous: {
      disabled: true,
      onClick: () => {},
    },
  },
  downloadRequested: false,
  previousProgressLoader: false,
  serpContext: null,
  article: '',
  introduction: '',
  conclusion: '',
};

export default function contentRefresh(state: ContentRefreshState = initialState, action) {
  switch (action.type) {
    case types.SET_SERP_CONTEXT:
      return {
        ...state,
        serpContext: action.payload,
      };
    case types.SET_INPUT_SOURCE_URL:
      return {
        ...state,
        inputSourceUrl: action.payload,
        preloadedInputSourceContent: initialState.preloadedInputSourceContent,
        loader: initialState.loader,
        informationBox: initialState.informationBox,
      };
    case types.RESET_SECTIONS: {
      const newState = {
        ...state,
      };

      action.payload.forEach((section) => {
        newState[section] = initialState[section];
      });

      return newState;
    }
    case types.SET_SECTION_VALUE:
      return {
        ...state,
        [action.payload.section]: action.payload.value,
      };
    case types.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case types.SET_ACTIVE_CARD:
      return {
        ...state,
        activeCard: action.payload,
      };
    case types.SET_DOWNLOAD_REQUESTED:
      return {
        ...state,
        downloadRequested: true,
      };
    case types.RESET_REDUCER:
      return {
        ...initialState,
      };
    case types.SET_INPUT_SOURCE_TYPE:
      return {
        ...state,
        inputSourceType: action.payload,
      };
    case types.SET_UPLOADED_FILE:
      return {
        ...state,
        uploadedFile: action.payload,
      };
    case types.EXTRACT_URL:
      return {
        ...state,
        article: action.payload,
      };
    case types.TOGGLE_INFORMATION_BOX:
      return {
        ...state,
        informationBox: state.loader ? action.payload : initialState.informationBox,
      };
    case types.EXTRACT_SUBJECT:
      return {
        ...state,
        subject: action.payload,
      };
    case types.EXTRACT_KEYWORDS:
      return {
        ...state,
        keywords: action.payload,
      };
    case types.GENERATE_TITLES:
      return {
        ...state,
        titles: action.payload ? [
          ...action.payload,
          '',
        ] : [],
      };
    case types.EXTRACT_SUMMARY:
      return {
        ...state,
        summary: action.payload ? [
          ...action.payload,
          '',
        ] : [],
      };
    case types.EXTRACT_OUTLINES:
      return {
        ...state,
        outlines: action.payload.outlines || [],
        outlinesSerp: action.payload.outlinesSerp || [],
      };
    case types.EXTRACT_KEY_MESSAGES:
      return {
        ...state,
        keyMessages: action.payload || null,
      };
    case types.GENERATE_INTRODUCTIONS:
      return {
        ...state,
        introductions: action.payload || [],
      };
    case types.EXTRACT_PARAGRAPH:
      return {
        ...state,
        paragraphs: [
          ...state.paragraphs,
          action.payload || [],
        ],
      };
    case types.SET_PARAGRAPH: {
      const newParagraphs = [...state.paragraphs];

      newParagraphs[action.payload.index].title = action.payload.title;
      newParagraphs[action.payload.index].text = action.payload.text;

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.ADD_PARAGRAPH: {
      const updatedParagraphs = [...state.paragraphs];
      updatedParagraphs.splice(action.payload.index, 0, action.payload.paragraph);

      return {
        ...state,
        paragraphs: updatedParagraphs,
      };
    }
    case types.SET_INTRODUCTION: {
      return {
        ...state,
        introduction: action.payload,
      };
    }
    case types.SET_CONCLUSION: {
      return {
        ...state,
        conclusion: action.payload,
      };
    }
    case types.GENERATE_IN_DEPTH_PARAGRAPH: {
      const newParagraphs = [...state.paragraphs];

      if (newParagraphs?.[action.payload.index]) {
        newParagraphs[action.payload.index].text = action.payload.text;
      }

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.REGENERATE_PARAGRAPH: {
      const newParagraphs = [
        ...state.paragraphs,
      ];

      newParagraphs[action.payload.index].text = action.payload.output;

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.DELETE_PARAGRAPHS: {
      let newParagraphs = [...state.paragraphs];

      const indexesToDelete = action.payload;

      newParagraphs = newParagraphs.filter((paragraph, index) => !indexesToDelete.includes(index));

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.CHANGE_PARAGRAPH_TYPES: {
      const newParagraphs = [...state.paragraphs];

      const indexesToChange = action.payload;

      indexesToChange.forEach((indexToChange) => {
        if (newParagraphs[indexToChange].type === 'h4') {
          newParagraphs[indexToChange].type = 'h3';
        } else if (newParagraphs[indexToChange].type === 'h3') {
          newParagraphs[indexToChange].type = 'h2';
        }
      });

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.EXTRACT_AND_REPLACE_PARAGRAPH: {
      const newParagraphs = [
        ...state.paragraphs,
      ];

      newParagraphs[action.payload.paragraphIndex] = action.payload.output;

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.EXTRACT_CONCLUSION:
      return {
        ...state,
        conclusions: action.payload || [],
      };
    case types.TOGGLE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case types.SHOW_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case types.HIDE_LOADER:
      return {
        ...state,
        loader: null,
      };
    case types.SET_PREVIOUS_PROGRESS_LOADER:
      return {
        ...state,
        previousProgressLoader: action.payload,
      };
    case types.SET_STORE:
      return {
        ...action.payload,
      };
    case types.EXTRACT_WHOLE_ARTICLE: {
      const { introduction, paragraphs, conclusion } = action.payload;
      return {
        ...state,
        introduction,
        paragraphs,
        conclusion,
      };
    }
    default:
      return state;
  }
}
