import React from 'react';

import { ReactComponent as TemplateDocument } from '../assets/svg/templates-documnet.svg';
import { ReactComponent as BlogPost } from '../assets/svg/blog_post.svg';
import { ReactComponent as LandingPage } from '../assets/svg/landingPage.svg';
import { ReactComponent as ProductItem } from '../assets/svg/productItem.svg';
import { ReactComponent as LogoSVG } from '../assets/svg/icon-contentoo.svg';
import { ReactComponent as AddSvg } from '../assets/svg/add.svg';
import { ReactComponent as ArrowSVG } from '../assets/svg/arrow_forward.svg';
import { ReactComponent as CreditSvg } from '../assets/svg/сreditsIcon.svg';
import { ReactComponent as DeleteSVG } from '../assets/svg/delete.svg';
import { ReactComponent as DocumentSVG } from '../assets/svg/document.svg';
import { ReactComponent as LogoutSVG } from '../assets/svg/logout.svg';
import { ReactComponent as ProfileSVG } from '../assets/svg/profile.svg';
import { ReactComponent as SettingsSVG } from '../assets/svg/settings.svg';
import { ReactComponent as TemplatesSVG } from '../assets/svg/templates.svg';
import { ReactComponent as TutorialsSVG } from '../assets/svg/tutorials.svg';
import { ReactComponent as ArrowDropUpSVG } from '../assets/svg/arrow_drop_up.svg';
import { ReactComponent as ArrowDropDownSVG } from '../assets/svg/arrow_drop_down.svg';
import { ReactComponent as SearchSVG } from '../assets/svg/search.svg';
import { ReactComponent as CheckSvg } from '../assets/svg/check.svg';
import { ReactComponent as LightningSvg } from '../assets/svg/lightningSvg.svg';
import { ReactComponent as AddReversed } from '../assets/svg/addReversed.svg';
import { ReactComponent as RoundedArrow } from '../assets/svg/arrowRounded.svg';
import { ReactComponent as DropdownArrow } from '../assets/svg/dropDownArrow.svg';
import { ReactComponent as DownloadSvg } from '../assets/svg/download.svg';
import { ReactComponent as ShareSvg } from '../assets/svg/share.svg';
import { ReactComponent as WriteSvg } from '../assets/svg/write.svg';
import { ReactComponent as TranslateSvg } from '../assets/svg/translate.svg';
import { ReactComponent as RefreshSvg } from '../assets/svg/refresh.svg';
import { ReactComponent as LoadingSvg } from '../assets/svg/loading.svg';
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow_left.svg';
import { ReactComponent as LowCredits } from '../assets/svg/lowCredits.svg';
import { ReactComponent as ArrowDown } from '../assets/svg/arrow_down.svg';
import { ReactComponent as ArrowUp } from '../assets/svg/arrow_up.svg';
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { ReactComponent as DeleteRounded } from '../assets/svg/delete-rounded.svg';
import { ReactComponent as Pen } from '../assets/svg/pen.svg';
import { ReactComponent as CrossSVG } from '../assets/svg/cross-out.svg';
import { ReactComponent as Brochure } from '../assets/svg/brochure.svg';
import { ReactComponent as Multitasking } from '../assets/svg/multitasking.svg';
import { ReactComponent as OhNo } from '../assets/svg/oh-no.svg';
import { ReactComponent as ArrowUpButton } from '../assets/svg/arrow-up-new.svg';
import { ReactComponent as ArrowDownButton } from '../assets/svg/arrow-down-new.svg';
import { ReactComponent as GreenChecked } from '../assets/svg/green-check.svg';
import { ReactComponent as ArrowNext } from '../assets/svg/arrow-next.svg';
import { ReactComponent as ArrowPrevious } from '../assets/svg/arrow-previous.svg';
import { ReactComponent as CrossBold } from '../assets/svg/cross-bold.svg';
import { ReactComponent as NoData } from '../assets/svg/no-data.svg';
import { ReactComponent as Choice } from '../assets/svg/choice.svg';
import { ReactComponent as Url } from '../assets/svg/url.svg';
import { ReactComponent as UpgradeCheck } from '../assets/svg/upgrade-check.svg';
import { ReactComponent as DocumentRounded } from '../assets/svg/document-rounded.svg';
import { ReactComponent as Enthusiastic } from '../assets/svg/enthusiastic.svg';
import { ReactComponent as RefreshFlow } from '../assets/svg/refresh-new.svg';
import { ReactComponent as CreationFlow } from '../assets/svg/creation.svg';
import { ReactComponent as VolumeFlow } from '../assets/svg/volume.svg';
import { ReactComponent as ContentooLogo } from '../assets/svg/contentoo-logo.svg';
import { ReactComponent as Plus } from '../assets/svg/plus.svg';
import { ReactComponent as TechnicalError } from '../assets/svg/tehnical-error.svg';
import { ReactComponent as InternetConnectionError } from '../assets/svg/no-connection.svg';
import { ReactComponent as CharactersWarning } from '../assets/svg/are-you-sure.svg';
import { ReactComponent as SectionsWarning } from '../assets/svg/sections-warning.svg';
import { ReactComponent as SectionsError } from '../assets/svg/sections-error.svg';
import { ReactComponent as UrlNotFoundError } from '../assets/svg/404.svg';
import { ReactComponent as DragIcon } from '../assets/svg/dragIcon.svg';
import { ReactComponent as Search } from '../assets/svg/search-new.svg';
import { ReactComponent as ThreeDots } from '../assets/svg/three-dots.svg';
import { ReactComponent as DownloadRounded } from '../assets/svg/download-new.svg';
import { ReactComponent as GreenCheckMark } from '../assets/svg/green-check-mark.svg';
import { ReactComponent as Warning } from '../assets/svg/warning.svg';
import { ReactComponent as Thunder } from '../assets/svg/thunder.svg';
import { ReactComponent as ShortLengthText } from '../assets/svg/short-length-text.svg';
import { ReactComponent as MediumLengthText } from '../assets/svg/medium-length-text.svg';
import { ReactComponent as LongLengthText } from '../assets/svg/long-length-text.svg';
import { ReactComponent as Undo } from '../assets/svg/undo.svg';
import { ReactComponent as Redo } from '../assets/svg/redo.svg';
import { ReactComponent as MagicWand } from '../assets/svg/generate.svg';
import { ReactComponent as Edit } from '../assets/svg/pen-new.svg';
import { ReactComponent as Regenerate } from '../assets/svg/regenerate.svg';
import { ReactComponent as Trending } from '../assets/svg/trending.svg';
import { ReactComponent as Finished } from '../assets/svg/black-check-mark.svg';
import { ReactComponent as Bold } from '../assets/svg/bold.svg';
import { ReactComponent as Italic } from '../assets/svg/italic.svg';
import { ReactComponent as BulletsList } from '../assets/svg/bullets.svg';
import { ReactComponent as NumbersList } from '../assets/svg/numbers.svg';
import { ReactComponent as StrikeThrough } from '../assets/svg/strikethrough.svg';
import { ReactComponent as Underline } from '../assets/svg/underline.svg';
import { ReactComponent as LinkIcon } from '../assets/svg/link.svg';
import { ReactComponent as AdvancedIcon } from '../assets/svg/advanced.svg';
import { ReactComponent as WritingIllustration } from '../assets/svg/writing-illustration.svg';
import { ReactComponent as EnvelopeIllustration } from '../assets/svg/envelope-illustration.svg';
import { ReactComponent as ArrowDownBold } from '../assets/svg/arrow-down-bold.svg';
import { ReactComponent as ArrowUpBold } from '../assets/svg/arrow-up-bold.svg';
import { ReactComponent as LinkNew } from '../assets/svg/link-new.svg';
import { ReactComponent as Clock } from '../assets/svg/clock.svg';

export default function renderIcon(icon) {
  switch (icon) {
    case 'logo':
      return <LogoSVG />;
    case 'templateDocument':
      return <TemplateDocument />;
    case 'blogPost':
      return <BlogPost />;
    case 'landingPage':
      return <LandingPage />;
    case 'productItem':
      return <ProductItem />;
    case 'add':
      return <AddSvg />;
    case 'arrow':
      return <ArrowSVG />;
    case 'credits':
      return <CreditSvg />;
    case 'bucket':
      return <DeleteSVG />;
    case 'document':
      return <DocumentSVG />;
    case 'logout':
      return <LogoutSVG />;
    case 'profile':
      return <ProfileSVG />;
    case 'settings':
      return <SettingsSVG />;
    case 'templates':
      return <TemplatesSVG />;
    case 'tutorials':
      return <TutorialsSVG />;
    case 'arrowDropUp':
      return <ArrowDropUpSVG />;
    case 'arrowDropDown':
      return <ArrowDropDownSVG />;
    case 'search':
      return <SearchSVG />;
    case 'check':
      return <CheckSvg />;
    case 'lightning':
      return <LightningSvg />;
    case 'addReversed':
      return <AddReversed />;
    case 'roundedArrow':
      return <RoundedArrow />;
    case 'dropDownArrow':
      return <DropdownArrow />;
    case 'download':
      return <DownloadSvg />;
    case 'share':
      return <ShareSvg />;
    case 'write':
      return <WriteSvg />;
    case 'translate':
      return <TranslateSvg />;
    case 'refresh':
      return <RefreshSvg />;
    case 'loading':
      return <LoadingSvg />;
    case 'arrowLeft':
      return <ArrowLeft />;
    case 'lowCredits':
      return <LowCredits />;
    case 'arrowDown':
      return <ArrowDown />;
    case 'arrowUp':
      return <ArrowUp />;
    case 'delete':
      return <DeleteRounded />;
    case 'close':
      return <Close />;
    case 'pen':
      return <Pen />;
    case 'cross':
      return <CrossSVG />;
    case 'brochure':
      return <Brochure />;
    case 'multitasking':
      return <Multitasking />;
    case 'ohNo':
      return <OhNo />;
    case 'arrowUpButton':
      return <ArrowUpButton />;
    case 'arrowDownButton':
      return <ArrowDownButton />;
    case 'greenChecked':
      return <GreenChecked />;
    case 'arrowNext':
      return <ArrowNext />;
    case 'arrowPrevious':
      return <ArrowPrevious />;
    case 'crossBold':
      return <CrossBold />;
    case 'noData':
      return <NoData />;
    case 'choice':
      return <Choice />;
    case 'url':
      return <Url />;
    case 'upgradeCheck':
      return <UpgradeCheck />;
    case 'documentRounded':
      return <DocumentRounded />;
    case 'enthusiastic':
      return <Enthusiastic />;
    case 'refreshFlow':
      return <RefreshFlow />;
    case 'creationFlow':
      return <CreationFlow />;
    case 'volumeFlow':
      return <VolumeFlow />;
    case 'contentooLogo':
      return <ContentooLogo />;
    case 'plus':
      return <Plus />;
    case 'technicalError':
      return <TechnicalError />;
    case 'connectionError':
      return <InternetConnectionError />;
    case 'charactersWarning':
      return <CharactersWarning />;
    case 'sectionsWarning':
      return <SectionsWarning />;
    case 'sectionsError':
      return <SectionsError />;
    case 'urlNotFoundError':
      return <UrlNotFoundError />;
    case 'drag':
      return <DragIcon />;
    case 'searchNew':
      return <Search />;
    case 'threeDots':
      return <ThreeDots />;
    case 'downloadRounded':
      return <DownloadRounded />;
    case 'greenCheckMark':
      return <GreenCheckMark />;
    case 'warning':
      return <Warning />;
    case 'thunder':
      return <Thunder />;
    case 'shortLengthText':
      return <ShortLengthText />;
    case 'mediumLengthText':
      return <MediumLengthText />;
    case 'longLengthText':
      return <LongLengthText />;
    case 'undo':
      return <Undo />;
    case 'redo':
      return <Redo />;
    case 'magicWand':
      return <MagicWand />;
    case 'edit':
      return <Edit />;
    case 'regenerate':
      return <Regenerate />;
    case 'trending':
      return <Trending />;
    case 'finished':
      return <Finished />;
    case 'bold':
      return <Bold />;
    case 'italic':
      return <Italic />;
    case 'bullets':
      return <BulletsList />;
    case 'numbers':
      return <NumbersList />;
    case 'strikeThrough':
      return <StrikeThrough />;
    case 'underline':
      return <Underline />;
    case 'link':
      return <LinkIcon />;
    case 'advanced':
      return <AdvancedIcon />;
    case 'writingIllustration':
      return <WritingIllustration />;
    case 'envelopeIllustration':
      return <EnvelopeIllustration />;
    case 'arrowDownBold':
      return <ArrowDownBold />;
    case 'arrowUpBold':
      return <ArrowUpBold />;
    case 'linkNew':
      return <LinkNew />;
    case 'clock':
      return <Clock />;
    default:
      return null;
  }
}
