import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './ParagraphNew.module.scss';
import { ParagraphNewProps } from './interfaces';
import renderIcon from '../../../../util/renderIcon';
import TextEditor from '../TextEditorAI/TextEditor';
import Input from '../InputAI/Input';
import { Button } from '../Button/button';
import { htmlToText } from '../../../../util/helpers';
import LoaderAI from '../LoaderAI/LoaderAI';

// Needs to be outside of class to prevent re-rendering
let hoverTimeout: NodeJS.Timeout | null = null;

const ParagraphNew: React.FC<ParagraphNewProps> = ({
  inputText,
  editorText,
  onSave,
  caption,
  onDelete,
  showAlternatives,
  generateInDepthText,
  // showTopics = false,
  // onTopicsClick,
  isLoading,
  loaderText,
  handleUndo,
  handleRedo,
  highlight = false,
  hasSubparagraphs,
}) => {
  const [editorContents, setEditorContents] = useState<any>(null);
  const [deleteDropdownActive, setDeleteDropdownActive] = useState<any>(false);

  const handleShowEditor = useCallback(() => {
    setEditorContents({
      title: inputText,
      text: editorText,
    });
  }, [inputText, editorText]);

  const handleTitleChange = useCallback((e) => {
    setEditorContents({
      ...editorContents,
      title: e.target.value,
    });
  }, [editorContents]);

  const handleTextChange = useCallback((text) => {
    setEditorContents({
      ...editorContents,
      text,
    });
  }, [editorContents]);

  const handleSave = () => {
    onSave({
      title: editorContents.title,
      text: htmlToText(editorContents.text),
    });

    setEditorContents(null);
  };

  const handleMouseOver = () => {
    hoverTimeout && clearTimeout(hoverTimeout);

    setDeleteDropdownActive(true);
  };

  const handleMouseOut = () => {
    hoverTimeout = setTimeout(() => setDeleteDropdownActive(false), 300);
  };

  const deleteDropdownOptions = [
    {
      title: `Delete ${caption} title only`,
      description: !caption ? undefined : {
        H2: 'Any H3\'s become H2\'s',
        H3: 'Any H4\'s become H3\'s and H3\'s become H2\'s',
      }[caption],
      action: () => onDelete && onDelete(false),
    },
    {
      title: `Delete whole ${caption} paragraph`,
      description: !caption ? undefined : {
        H2: 'Including all H3\'s and H4\'s',
        H3: 'Including all H4\'s',
      }[caption],
      action: () => onDelete && onDelete(true),
    },
  ];

  const getFontClass = (paragraphType) => {
    switch (paragraphType) {
      case 'h2':
        return styles.h2;
      case 'h3':
        return styles.h3;
      case 'h4':
        return styles.h4;
      default:
        return undefined;
    }
  };

  return (
    <>
      {!editorContents && (
      <div
        className={classNames(styles.wrapper, { [styles.highlight]: highlight })}
      >
        {(!isLoading) && (
        <div className={styles.actions}>
          {editorText && (
            <>
              <div
                tabIndex={0}
                role='button'
                className={styles.option}
                onClick={generateInDepthText}
                onKeyDown={generateInDepthText}
              >
                <span className={styles.actionIcon}>{renderIcon('regenerate')}</span>
                <span>in-depth text</span>
              </div>
              <div
                tabIndex={0}
                role='button'
                className={styles.option}
                onClick={showAlternatives}
                onKeyDown={showAlternatives}
              >
                <span className={styles.actionIcon}>{renderIcon('magicWand')}</span>
                <span>alternatives</span>
              </div>
              {/* {showTopics && ( */}
              {/*  <div */}
              {/*    tabIndex={0} */}
              {/*    role='button' */}
              {/*    className={styles.option} */}
              {/*    onClick={onTopicsClick} */}
              {/*    onKeyDown={onTopicsClick} */}
              {/*  > */}
              {/*    <span className={styles.actionIcon}>{renderIcon('edit')}</span> */}
              {/*    <span>topics</span> */}
              {/*  </div> */}
              {/* )} */}
              <div
                tabIndex={0}
                role='button'
                className={classNames(styles.option, styles.operations, { [styles.disabled]: !handleUndo })}
                onClick={handleUndo}
                onKeyDown={handleUndo}
              >
                <span className={styles.operationIcon}>{renderIcon('undo')}</span>
              </div>
              <div
                tabIndex={0}
                role='button'
                className={classNames(styles.option, styles.operations, { [styles.disabled]: !handleRedo })}
                onClick={handleRedo}
                onKeyDown={handleRedo}
              >
                <span className={styles.operationIcon}>{renderIcon('redo')}</span>
              </div>
            </>
          )}
          {onDelete && (caption !== 'Introduction' && caption !== 'Conclusion')
            && (
            <>
              <div
                tabIndex={0}
                role='button'
                className={classNames(styles.option, styles.operations)}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onFocus={handleMouseOver}
                onBlur={handleMouseOut}
                onClick={() => (!hasSubparagraphs ? onDelete(true) : undefined)}
                onKeyDown={() => (!hasSubparagraphs ? onDelete(true) : undefined)}
              >
                <span className={styles.operationIcon}>{renderIcon('delete')}</span>
              </div>
              {(deleteDropdownActive && hasSubparagraphs) && (
                <div
                  className={styles.deleteDropdown}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onFocus={handleMouseOver}
                  onBlur={handleMouseOut}
                >
                  <div className={styles.deleteDropdownOptions}>
                    {(deleteDropdownOptions.map(({ action, title, description }) => (
                      <div
                        key={title}
                        role='button'
                        tabIndex={0}
                        className={styles.deleteDropdownOption}
                        onClick={action}
                        onKeyDown={action}
                      >
                        <div className={styles.optionTitle}>{title}</div>
                        <div className={styles.optionDescription}>{description}</div>
                      </div>
                    )))}
                  </div>
                </div>
              )}
            </>
            )}
        </div>
        )}
        <div className={styles.header}>
          {inputText && (
            <div
              tabIndex={0}
              role='button'
              className={classNames(styles.title, getFontClass(caption?.toLowerCase()))}
              onClick={isLoading ? undefined : handleShowEditor}
              onKeyDown={isLoading ? undefined : handleShowEditor}
            >
              {inputText}
            </div>
          )}
          <div
            tabIndex={0}
            role='button'
            className={styles.icon}
            onClick={handleShowEditor}
            onKeyDown={handleShowEditor}
          >
            {renderIcon('edit')}
          </div>
        </div>
        {editorText && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {isLoading ? (
              <LoaderAI
                componentLoader
                componentLoaderText={loaderText}
              />
            ) : (
              <div
                tabIndex={0}
                role='button'
                className={styles.text}
                onClick={!isLoading && handleShowEditor}
                onKeyDown={!isLoading && handleShowEditor}
              >
                <div dangerouslySetInnerHTML={{ __html: editorText }} />
              </div>
            )}
          </>
        )}
      </div>
      )}
      {editorContents
        && (
          <OutsideClickHandler onOutsideClick={() => setEditorContents(null)}>
            {((inputText && editorText) || editorText)
              ? (
                <div className={styles.editorWrapper}>
                  <div className={styles.editorHeader}>
                    <span className={classNames(styles.editorCaption, getFontClass(caption?.toLowerCase()))}>
                      {caption}
                    </span>
                    <div className={styles.editorActions}>
                      <Button
                        actionName='cancel'
                        action={() => setEditorContents(null)}
                        className={classNames(styles.cancelButton, styles.editorButton)}
                      />
                      <Button
                        actionName='save'
                        action={handleSave}
                        className={styles.editorButton}
                        disabled={editorContents?.title?.length <= 0 || editorContents?.text?.length < 12}
                      />
                    </div>
                  </div>
                  {inputText && (
                  <Input
                    onChange={handleTitleChange}
                    value={editorContents.title}
                  />
                  )}
                  <TextEditor
                    onChange={handleTextChange}
                    initialText={editorContents.text}
                    toolbarHidden={!editorContents}
                    noWrapper
                  />
                </div>
              ) : (
                <Input
                  onChange={handleTitleChange}
                  value={editorContents.title}
                  showCheckIcon
                  onClickOnShowIcon={handleSave}
                />
              )}
          </OutsideClickHandler>
        )}
    </>
  );
};

export default ParagraphNew;
