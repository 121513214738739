import * as types from '../constants/content-creation-new';
import { Paragraph } from '../components/core/components/ParagraphNew/interfaces';

export interface ContentCreationStateNew {
  activeStep: number;
  activeCard: number;
  titles: string[];
  outlines: any;
  outlinesSerp: string[];
  serpContext?: any;
  continueHash: string;
  introduction: string;
  paragraphs: Paragraph[];
  conclusion: string;
  keyMessages: string[][];
  summary: string;
  loader: null | string;
  previousProgressLoader: boolean;
  downloadRequested: boolean;
  restored: boolean;
}

const initialState = {
  activeStep: 1,
  activeCard: 1,
  titles: [],
  outlines: [],
  outlinesSerp: [],
  serpContext: null,
  continueHash: '',
  introduction: '',
  paragraphs: [],
  conclusion: '',
  keyMessages: [],
  summary: '',
  loader: null,
  previousProgressLoader: false,
  downloadRequested: false,
  restored: false,
};

export default function contentCreationNew(state: ContentCreationStateNew = initialState, action) {
  switch (action.type) {
    case types.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case types.SET_ACTIVE_CARD:
      return {
        ...state,
        activeCard: action.payload,
      };
    case types.SET_PARAGRAPH: {
      const newParagraphs = [...state.paragraphs];

      newParagraphs[action.payload.index].title = action.payload.title;
      newParagraphs[action.payload.index].text = action.payload.text;

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.ADD_PARAGRAPH: {
      const updatedParagraphs = [...state.paragraphs];
      updatedParagraphs.splice(action.payload.index, 0, action.payload.paragraph);

      return {
        ...state,
        paragraphs: updatedParagraphs,
      };
    }
    case types.SET_INTRODUCTION: {
      return {
        ...state,
        introduction: action.payload,
      };
    }
    case types.SET_CONCLUSION: {
      return {
        ...state,
        conclusion: action.payload,
      };
    }
    case types.GENERATE_IN_DEPTH_INTRODUCTION: {
      return {
        ...state,
        introduction: action.payload,
      };
    }
    case types.GENERATE_IN_DEPTH_PARAGRAPH: {
      const newParagraphs = [...state.paragraphs];

      if (newParagraphs?.[action.payload.index]) {
        newParagraphs[action.payload.index].text = action.payload.text;
      }

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.GENERATE_IN_DEPTH_CONCLUSION: {
      return {
        ...state,
        conclusion: action.payload,
      };
    }
    case types.REGENERATE_PARAGRAPH: {
      const newParagraphs = [
        ...state.paragraphs,
      ];

      newParagraphs[action.payload.index].text = action.payload.output;

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.GENERATE_KEY_MESSAGES: {
      return {
        ...state,
        keyMessages: action.payload,
      };
    }
    case types.GENERATE_SUMMARY: {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case types.DELETE_PARAGRAPHS: {
      let newParagraphs = [...state.paragraphs];

      const indexesToDelete = action.payload;

      newParagraphs = newParagraphs.filter((paragraph, index) => !indexesToDelete.includes(index));

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.CHANGE_PARAGRAPH_TYPES: {
      const newParagraphs = [...state.paragraphs];

      const indexesToChange = action.payload;

      indexesToChange.forEach((indexToChange) => {
        if (newParagraphs[indexToChange].type === 'h4') {
          newParagraphs[indexToChange].type = 'h3';
        } else if (newParagraphs[indexToChange].type === 'h3') {
          newParagraphs[indexToChange].type = 'h2';
        }
      });

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.RESET_SECTIONS: {
      const newState = {
        ...state,
      };

      action.payload.forEach((section) => {
        newState[section] = initialState[section];
      });

      return newState;
    }
    case types.SET_SECTION_VALUE:
      return {
        ...state,
        [action.payload.section]: action.payload.value,
      };
    case types.GENERATE_TITLES:
      return {
        ...state,
        titles: action.payload ? [
          ...action.payload,
          '',
        ] : [],
      };
    case types.GENERATE_OUTLINES:
      return {
        ...state,
        outlines: action.payload || [],
        outlinesSerp: action.payload.outlinesSerp || [],
      };
    case types.GENERATE_FULL_ARTICLE: {
      const { introduction, paragraphs, conclusion } = action.payload;

      return {
        ...state,
        introduction,
        paragraphs,
        conclusion,
      };
    }
    case types.SET_SERP_CONTEXT:
      return {
        ...state,
        serpContext: action.payload,
      };
    case types.CONTINUE_HASH:
      return {
        ...state,
        continueHash: action.payload,
      };
    case types.SHOW_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case types.HIDE_LOADER:
      return {
        ...state,
        loader: null,
      };
    case types.SET_PREVIOUS_PROGRESS_LOADER:
      return {
        ...state,
        previousProgressLoader: action.payload,
      };
    case types.SET_STORE:
      return {
        ...action.payload,
        restored: true,
      };
    case types.SET_DOWNLOAD_REQUESTED:
      return {
        ...state,
        downloadRequested: true,
      };
    default:
      return state;
  }
}
