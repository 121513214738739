import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { SecondaryButton } from '../../../core/components/Button/button';
import AuthFooter from '../../../Footer/AuthFooter/AuthFooter';
import AuthPage from '../index';
import Separator from '../../../core/components/Separator/Separator';

function CheckResetEmail() {
  const navigate = useNavigate();

  return (
    <AuthPage>
      <div className={styles.smallLogo}>
        {renderIcon('logo')}
      </div>
      <div className={styles.largeLogo}>
        {renderIcon('contentooLogo')}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Check your email
        </div>
        <div className={styles.subtitle}>
          We&apos;ll be sending you an email soon with instructions on how to reset your password.
          <br />
          If you don&apos;t see the email in your inbox, please take a look in your spam folder or try resending it.
        </div>
        <Separator margin='20px 0' />
        <div className={styles.loginWrapper}>
          <span>Found your password?</span>
          <SecondaryButton
            name='Login'
            className={styles.loginButton}
            onClick={() => navigate('/login')}
          />
        </div>
      </div>
      <AuthFooter />
    </AuthPage>
  );
}

export default CheckResetEmail;
