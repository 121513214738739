import React from 'react';
import classNames from 'classnames';
import nl2br from 'react-newline-to-break';
import styles from './FormikInput.module.scss';
import { FormikInputProps } from './interfaces';

const FormikInput: React.FC<FormikInputProps> = ({
  label,
  placeholder,
  type,
  additionalStyle,
  message,
  error,
  field,
}) => (
  <div
    className={styles.wrapper}
  >
    {label && <div className={styles.label}>{label}</div>}
    <input
      type={type}
      placeholder={placeholder}
      className={classNames(styles.inputContainer, { [styles.errorBorder]: error })}
      style={additionalStyle}
      {...field}
    />
    {message && <div className={styles.message}>{message}</div>}
    {error && <div className={styles.error}>{Array.isArray(error) ? nl2br(error.join('\n')) : error}</div>}
  </div>
);

export default FormikInput;
