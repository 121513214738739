import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import * as Yup from 'yup';
import Header from '../../Header';
import StepperHeader from '../../Header/StepperHeader/StepperHeader';
import Stepper from '../../core/components/Stepper';
import styles from './styles.module.scss';
import FirstStep from './Steps/FirstStep';
import SecondStep from './Steps/SecondStep';
import FourthStep from './Steps/FourthStep';
import {
  resetReducer,
  setActiveCard,
  setActiveStep,
  setPreviousProgressLoader,
  storeProgressAndSendBackendErrorEmail,
  storeCurrentFlow,
} from '../../../actions/content-refresh';
import ContentPreview from '../../core/components/ContentPreview/ContentPreview';
import ContentPreviewToolbar from '../../core/components/ContentPreviewToolbar/ContentPreviewToolbar';
import AIErrorModal from '../../Modals/AIErrorModal';
import ExtractingUrlErrorModal from '../../Modals/ExtractingUrlErrorModal';
import ExitFlowModal from '../../Modals/ExitFlowModal';
import EditWarningModal from '../../Modals/EditWarningModal';
import PreviousStepWarning from '../../Modals/PreviousStepWarning';
import NotFoundErrorModal from '../../Modals/NotFoundErrorModal';
import InternetConnectionError from '../../Modals/InternetConnectionError';
import CharactersWarningModal from '../../Modals/CharactersWarningModal';
import SectionsWarningModal from '../../Modals/SectionsWarningModal';
import SectionsErrorModal from '../../Modals/SectionsErrorModal';
import UrlNotFoundErrorModal from '../../Modals/UrlNotFoundErrorModal';
import ThirdStep from './Steps/ThirdStep';
import Mixpanel from '../../../util/Mixpanel';
import { EXIT_FLOW_WARNING_MODAL } from '../../../constants/modal';
import { openModal } from '../../../actions/modal';
// import { LOGGED_IN } from '../../../constants';
import { RootState } from '../../../reducers';
import { DetailPage } from './Steps/DetailPage';

const validationSchema = Yup.object().shape({
  subject: Yup.string().min(6, 'Subject must be at least 6 characters long').required('Subject is required'),
  title: Yup.string().min(6, 'Title must be at least 6 characters long').required('Title is required'),
});

function ContentRefreshNew() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const store = useSelector((state: RootState) => state.contentRefresh);

  const {
    activeStep, activeCard, downloadRequested, loader,
  } = useSelector((state: RootState) => state.contentRefresh);

  // const loggedIn = useSelector((state: RootState) => state.auth.loggedIn) === LOGGED_IN;

  const searchParams = new URLSearchParams(location.search);
  const continueHash = searchParams.get('continue_hash');

  const [showMessage, setShowMessage] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [formVals, setFormVals] = useState({});
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    dispatch(resetReducer());
    if (continueHash?.length) {
      dispatch(setPreviousProgressLoader(true));
    }
    return () => {
      dispatch(setActiveStep(1));
      dispatch(setActiveCard(1));
      dispatch(resetReducer());
    };
  }, [dispatch, continueHash]);

  const scrollToCard = (stepNumber: number, cardNumber: number) => {
    scroller.scrollTo(`step${stepNumber}_card${cardNumber}`, {
      offset: -90,
      duration: 0,
      smooth: false,
      containerId: 'scrollerContainer',
    });
  };

  useEffect(() => {
    if (activeStep < 5) {
      scrollToCard(activeStep, activeCard);
    }
    setShowMessage(false);
  }, [activeCard, activeStep]);

  const stepComponents = {
    1: FirstStep,
    2: SecondStep,
    3: ThirdStep,
    4: FourthStep,
    5: DetailPage,
  };

  const CurrentStepComponent = stepComponents[activeStep];

  return (
    <div
      id='scrollerContainer'
      className={styles.scroller}
    >
      <AIErrorModal
        action={(values) => storeProgressAndSendBackendErrorEmail(values.email, store, formVals, continueHash || '').then(() => {
          setShowMessage(true);
          setEmailSent(true);
        })}
        showMessage={showMessage}
      />
      <ExtractingUrlErrorModal />
      {!downloadRequested && (
        <ExitFlowModal
          showMessage={showMessage}
          cardLoader={loader}
          saveAction={(email) => {
            Mixpanel.track('Content Refresh - Finalize - Click continue later');
            storeCurrentFlow(store, formVals, email, continueHash || '').then(() => setShowMessage(true));
          }}
          exitWithoutSaveAction={() => Mixpanel.track('Content Refresh - Finalize - Click to exit flow')}
        />
      )}
      <EditWarningModal />
      <PreviousStepWarning />
      <NotFoundErrorModal />
      <InternetConnectionError />
      <CharactersWarningModal />
      <SectionsWarningModal />
      <SectionsErrorModal />
      <UrlNotFoundErrorModal />
      <UrlNotFoundErrorModal />
      <Header>
        <StepperHeader
          title='Content Refresh'
          stepper={(
            <Stepper
              steps={[
                { id: 1, title: 'Input' },
                { id: 2, title: 'Briefing' },
                { id: 3, title: 'Outlines' },
                { id: 4, title: 'Refresh' },
              ]}
              activeStep={activeStep}
              isDetail={activeStep === 5}
            />
          )}
          closeAction={() => {
            if ((activeStep === 1 && activeCard === 1) || (downloadRequested && !loader) || emailSent) {
              navigate('/');
            } else {
              dispatch(openModal(EXIT_FLOW_WARNING_MODAL));
            }
          }}
        />
      </Header>
      <ContentPreviewToolbar
        preview={activeStep !== 5 && activeStep !== 1}
        previewAction={() => setShowPreview(!showPreview)}
        previewValue={showPreview}
      />
      <div
        className={classNames(
          styles.container,
          { [styles.withPreview]: showPreview && activeStep !== 5 && activeStep !== 1 },
        )}
      >
        <Formik
          initialValues={{
            toneOfVoice: 'default',
            articleLength: 'short',
            subject: '',
            blogGeneration: 'turbo',
          }}
          onSubmit={() => { }}
          validationSchema={validationSchema}
        >
          {(form) => {
            setFormVals(form.values);
            return (
              <CurrentStepComponent
                form={form}
                activeCard={activeCard}
                setActiveCard={(card: number) => dispatch(setActiveCard(card))}
              />
            );
          }}
        </Formik>
      </div>
      {showPreview && activeStep !== 5 && activeStep !== 1 && (
        <div className={styles.previewContainer}>
          <ContentPreview contentValues={formVals} />
        </div>
      )}
    </div>
  );
}

export default ContentRefreshNew;
