import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import classNames from 'classnames';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import styles from './TextEditor.module.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import renderIcon from '../../../../util/renderIcon';
import { TextEditorProps } from './interfaces';
import BoldIcon from '../../../../assets/svg/bold.svg';
import ItalicIcon from '../../../../assets/svg/italic.svg';
import StrikeThroughIcon from '../../../../assets/svg/strikethrough.svg';
import UnderlineIcon from '../../../../assets/svg/underline.svg';
import UnorderedListIcon from '../../../../assets/svg/bullets.svg';
import OrderedListIcon from '../../../../assets/svg/numbers.svg';
import UndoIcon from '../../../../assets/svg/undo.svg';
import RedoIcon from '../../../../assets/svg/redo.svg';
import LinkIcon from '../../../../assets/svg/link.svg';

const TextEditor: React.FC<TextEditorProps> = ({
  initialText,
  noWrapper,
  onChange,
  maxHeight = 'auto',
  allowedTags = ['Normal', 'H3'],
  border,
  toolbarHidden = false,
  onFocus,
  onBlur,
  blockType,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  // Initialize the editor state based on the initial text
  const [editorState, setEditorState] = useState(() => {
    const contentStateData = convertFromHTML(initialText?.toString() || '');

    // If there is no initial text after converting from HTML, create an empty editor state
    if (!contentStateData.contentBlocks) {
      return EditorState.createEmpty();
    }

    // Convert the initial text from HTML to ContentState
    const contentState = ContentState.createFromBlockArray(contentStateData);

    // Create an editor state with the converted content state
    return EditorState.createWithContent(contentState);
  });

  const handleEditorStateChange = (content) => {
    // Update the editor state
    setEditorState(content);

    // Convert the current content to HTML
    const html = stateToHTML(content.getCurrentContent());

    // Invoke the onChange function with the HTML content
    onChange(html);
  };

  // Effect hook to update the HTML content when the editor state changes
  useEffect(() => {
    // Convert the current content of the editor state to HTML
    const html = stateToHTML(editorState.getCurrentContent());

    // Invoke the onChange function with the HTML content
    onChange(html);
  }, []);

  return (
    <div
      className={classNames(
        styles.wrapper,
        {
          [styles.noWrapper]: noWrapper,
          [styles.border]: border,
          [styles.toolbarHidden]: toolbarHidden,
        },
      )}
    >
      <div className={styles.row}>
        <span>
          Input content
        </span>
        <div
          tabIndex={0}
          role='button'
          className={styles.icon}
          onClick={() => setCollapsed(!collapsed)}
          onKeyDown={() => setCollapsed(!collapsed)}
        >
          {renderIcon(collapsed ? 'arrowDown' : 'arrowUp')}
        </div>
      </div>
      {!collapsed && (
      <Editor
        onEditorStateChange={handleEditorStateChange}
        toolbarClassName='toolbar'
        wrapperClassName='wrapperClassName'
        editorClassName='editor'
        stripPastedStyles
        editorState={editorState}
        editorStyle={{
          maxHeight,
        }}
        toolbarHidden={toolbarHidden}
        onFocus={onFocus}
        onBlur={onBlur}
        toolbar={{
          options: blockType ? ['blockType', 'inline', 'list', 'history'] : ['inline', 'list', 'history'],
          blockType: blockType
            ? {
              inDropdown: true,
              options: allowedTags,
            }
            : undefined,
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
            bold: { icon: BoldIcon, className: styles.textEditorIcon },
            italic: { icon: ItalicIcon, className: styles.textEditorIcon },
            underline: { icon: UnderlineIcon, className: styles.textEditorIcon },
            strikethrough: { icon: StrikeThroughIcon, className: styles.textEditorIcon },
          },
          list: {
            inDropdown: false,
            options: ['unordered', 'ordered'],
            unordered: { icon: UnorderedListIcon, className: styles.textEditorIcon },
            ordered: { icon: OrderedListIcon, className: styles.textEditorIcon },
          },
          history: {
            inDropdown: false,
            options: ['undo', 'redo'],
            undo: { icon: UndoIcon, className: styles.textEditorIcon },
            redo: { icon: RedoIcon, className: styles.textEditorIcon },
          },
        }}
      />
      )}
    </div>
  );
};

export default TextEditor;
