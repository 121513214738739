import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isInteger } from 'lodash';
import { useNavigate } from 'react-router';
import { EDIT_ARTICLE_MODAL } from '../../constants/modal';
import { Button } from '../core/components/Button/button';
import Modal from '../core/components/Modal';
import styles from './styles.module.scss';
import TextEditor from '../core/components/TextEditorAI/TextEditor';
import Separator from '../core/components/Separator/Separator';
import { editDocument, readDocument } from '../../actions/documents';
import { closeModal } from '../../actions/modal';
import renderIcon from '../../util/renderIcon';
import { checkUserAuthenticated } from '../../actions/auth';

function EditArticleModal() {
  const [text, setText] = useState('');
  const [textChangedTimes, setTextChangedTimes] = useState(0);
  const [document, setDocument] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const documentId = useSelector(state => state.modal.data);

  useEffect(() => {
    if (isInteger(documentId)) {
      dispatch(readDocument(documentId)).then(data => {
        if (!checkUserAuthenticated(data, navigate, dispatch)) {
          return;
        }

        setDocument(data.data.document);
      });
    }
  }, [documentId]);

  useEffect(() => {
    if (document.parsedData) {
      let html = '';

      html += `<h2>${document.parsedData.title}</h2>`;
      html += `<p>${document.parsedData.introduction}</p>`;

      document.parsedData.paragraph_headings.forEach((heading, i) => {
        html += `<h3>${heading}</h3>`;
        html += `<p>${document.parsedData.paragraphs[i]}</p>`;
      });

      html += `<p>${document.parsedData.conclusion}</p>`;

      setText(html);
    }
  }, [document.parsedData]);

  useEffect(() => {
    setTextChangedTimes(textChangedTimes + 1);
  }, [text]);

  const warnIcon = {
    icon: renderIcon('warning'),
  };

  return (
    <Modal
      name={EDIT_ARTICLE_MODAL}
      modalWidth='700px'
    >
      <div className={styles.editArticleModalWrapper}>
        <div className={styles.editArticleModalHeader}>
          <div className={styles.leftSide}>
            View/Edit your article
          </div>
          <div className={styles.rightSide}>
            <Button
              rightIcon='downloadRounded'
              className={styles.downloadButton}
              action={() => window.open(document.download_url, '_blank')}
            />
            <Button
              actionName='save'
              disabled={textChangedTimes < 4}
              action={() => dispatch(editDocument(documentId, text)).then((data) => {
                if (!checkUserAuthenticated(data, navigate, dispatch)) {
                  return;
                }

                if (!data.data.success) {
                  toast.warn(`${(data.data.message.text).join(' ')}`, warnIcon);
                  return;
                }

                dispatch(closeModal());
              })}
            />
          </div>
        </div>
        <Separator margin='30px 0 10px 0' />
        <div>
          {(text.length > 0) && (
          <TextEditor
            onChange={(html) => setText(html)}
            noWrapper
            allowedTags={['Normal', 'H3', 'H2']}
            initialText={text}
            maxHeight='500px'
            blockType
          />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default EditArticleModal;
