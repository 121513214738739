import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';

function Input({
  form, field, name, label, placeholder, disabled, deletable, setShowSuggestions = () => {}, onEnter,
}) {
  const errorMessage = form.errors[field.name];

  const deletableInput = (input) => (
    <div className={styles['position-relative']}>
      {input}
      <span className={styles['position-absolute-icon']}>{renderIcon('addReversed')}</span>
    </div>
  );

  const handleChange = (e) => {
    if (!name) {
      form.setFieldValue(field.name, e.target.value);
      setShowSuggestions(false);
    } else {
      form.setFieldValue(e);
    }
  };

  const inputInstance = (
    <>
      {label && (<div className={styles.label}>{label}</div>)}
      <input
        name={name}
        type='text'
        className={classNames(styles['input-container'], { [styles.invalid]: !!errorMessage })}
        placeholder={placeholder}
        value={field.value}
        onChange={(e) => handleChange(e)}
        onKeyUp={e => onEnter && e.key === 'Enter' && onEnter()}
        disabled={disabled}
      />
      {!!errorMessage && (<span className={styles.errorText}>{errorMessage}</span>)}
    </>
  );

  return deletable ? deletableInput(inputInstance) : inputInstance;
}

export default Input;
