import React from 'react';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import Modal from '../core/components/Modal';
import { EXTRACTING_URL_ERROR_MODAL } from '../../constants/modal';

function ExtractingUrlErrorModal() {
  return (
    <Modal name={EXTRACTING_URL_ERROR_MODAL}>
      <div className={styles.modal}>
        <div className={styles.imageLarge}>
          {renderIcon('ohNo')}
        </div>
        <span className={styles.title}>
          Oops, something went wrong
        </span>
        <span className={styles.text}>
          We apologize for the inconvenience, but it seems that we&apos;re currently unable to show you the results you requested.
          <br />
          <br />
          However, there&apos;s no need to worry as you can still add the information manually in plain text format.
        </span>
      </div>
    </Modal>
  );
}

export default ExtractingUrlErrorModal;
