import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';
import Input from '../../../core/components/InputAI/Input';
import { Button } from '../../../core/components/Button/button';
import AuthFooter from '../../../Footer/AuthFooter/AuthFooter';
import AuthPage from '../index';
import Separator from '../../../core/components/Separator/Separator';
import { resetPassword } from '../../../../actions/auth';

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  // eslint-disable-next-line no-restricted-globals
  const searchParams = new URLSearchParams(location.search);

  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const { validationErrors, errorMessage } = useSelector(state => state.auth);

  const handleResetPassword = () => {
    const email = searchParams.get('email');
    const { token } = params;

    dispatch(resetPassword(email, password, confirmPass, token, navigate));
  };

  return (
    <AuthPage>
      <div className={styles.smallLogo}>
        {renderIcon('logo')}
      </div>
      <div className={styles.largeLogo}>
        {renderIcon('contentooLogo')}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Reset your password
        </div>
        <div className={styles.subtitle}>
          We understand that forgetting passwords happens to the best of us, but don't worry, we've got you covered.
        </div>
        <div className={styles.form}>
          <Input
            label='Password'
            placeholder='Enter your password'
            type='password'
            onChange={e => setPassword(e.target.value)}
            message='Use 8 or more characters with a mix of letters, numbers and symbols'
            error={validationErrors?.password}
          />
          <Input
            label='Repeat password'
            placeholder='Confirm password'
            type='password'
            onChange={e => setConfirmPass(e.target.value)}
          />
          {(errorMessage && isEmpty(validationErrors)) && <span className={styles.error}>{errorMessage}</span>}
          {validationErrors?.email && <span className={styles.error}>Incorrect URL provided</span>}
          <Separator margin='20px 0' />
          <Button
            actionName='Reset'
            className={styles.resetButton}
            disabled={password !== confirmPass}
            action={handleResetPassword}
          />
        </div>
      </div>
      <AuthFooter />
    </AuthPage>
  );
}

export default ResetPassword;
