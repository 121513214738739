export const OPEN_MODAL = 'MODAL/OPEN_MODAL';
export const CLOSE_MODAL = 'MODAL/CLOSE_MODAL';

export const AI_ERROR_MODAL = 'MODAL/AI_ERROR_MODAL';
export const CHARACTERS_WARNING_MODAL = 'MODAL/CHARACTERS_WARNING_MODAL';
export const EDIT_WARNING_MODAL = 'MODAL/EDIT_WARNING_MODAL';
export const EXIT_FLOW_WARNING_MODAL = 'MODAL/EXIT_FLOW_WARNING_MODAL';
export const EXTRACTING_URL_ERROR_MODAL = 'MODAL/EXTRACTING_URL_ERROR_MODAL';
export const INTERNET_CONNECTION_ERROR_MODAL = 'MODAL/INTERNET_CONNECTION_ERROR_MODAL';
export const NOT_FOUND_ERROR_MODAL = 'MODAL/NOT_FOUND_ERROR_MODAL';
export const PREVIOUS_STEP_WARNING_MODAL = 'MODAL/PREVIOUS_STEP_WARNING_MODAL';
export const SECTIONS_WARNING_MODAL = 'MODAL/SECTIONS_WARNING_MODAL';
export const SECTIONS_ERROR_MODAL = 'MODAL/SECTIONS_ERROR_MODAL';
export const URL_NOT_FOUND_ERROR_MODAL = 'MODAL/URL_NOT_FOUND_ERROR_MODAL';
export const EDIT_ARTICLE_MODAL = 'MODAL/EDIT_ARTICLE_MODAL';
export const KEY_MESSAGES_MODAL = 'MODAL/KEY_MESSAGES_MODAL';
export const FEEDBACK_MODAL = 'MODAL/FEEDBACK_MODAL';
export const CONFIRM_DELETING_DOCUMENT_MODAL = 'MODAL/CONFIRM_DELETING_DOCUMENT_MODAL';
export const CONFIRM_DELETING_PARAGRAPH_MODAL = 'MODAL/CONFIRM_DELETING_PARAGRAPH_MODAL';
export const ALTERNATIVES_MODAL = 'MODAL/ALTERNATIVES_MODAL';
export const PARAGRAPHS_ERROR_MODAL = 'MODAL/PARAGRAPHS_ERROR_MODAL';
export const DOWNLOAD_FILE_MODAL = 'MODAL/DOWNLOAD_FILE_MODAL';
