import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import { Button } from '../core/components/Button/button';
import { closeModal } from '../../actions/modal';
import Modal from '../core/components/Modal';
import { PREVIOUS_STEP_WARNING_MODAL } from '../../constants/modal';
import { toggleLoader } from '../../actions/content-refresh';

function PreviousStepWarning() {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal);

  return (
    <Modal name={PREVIOUS_STEP_WARNING_MODAL}>
      <div className={styles.modal}>
        <div className={styles.image}>
          {renderIcon('choice')}
        </div>
        <span className={styles.title}>
          Are you sure?
        </span>
        <span className={styles.text}>
          Your current progress on this step will be lost if you proceed.
          <br />
          Are you sure you want to continue?
        </span>
        <div className={styles.buttonsRow}>
          <Button
            actionName='no'
            action={() => dispatch(closeModal())}
          />
          <Button
            actionName='yes'
            action={() => {
              modal?.data?.onConfirm && modal.data.onConfirm();

              dispatch(closeModal());
              dispatch(toggleLoader(false));
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default PreviousStepWarning;
