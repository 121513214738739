import React, { useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';
import Input from '../../../core/components/InputAI/Input';
import { Button, SecondaryButton } from '../../../core/components/Button/button';
import AuthFooter from '../../../Footer/AuthFooter/AuthFooter';
import AuthPage from '../index';
import Separator from '../../../core/components/Separator/Separator';
import { login } from '../../../../actions/auth';

function Login() {
  const [rememberMeSelected, setRememberMeSelected] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { validationErrors, errorMessage } = useSelector(state => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <AuthPage>
      <div className={styles.smallLogo}>
        {renderIcon('logo')}
      </div>
      <div className={styles.largeLogo}>
        {renderIcon('contentooLogo')}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Contentoo login
        </div>
        <div className={styles.subtitle}>
          Please log in using your email and password.
        </div>
        <div className={styles.form}>
          <Input
            label='Email'
            placeholder='Enter your email'
            type='email'
            onChange={e => setEmail(e.target.value)}
            error={validationErrors?.email}
          />
          <Input
            label='Password'
            placeholder='Enter your password'
            type='password'
            onChange={e => setPassword(e.target.value)}
            error={validationErrors?.password}
          />
          <div
            className={styles.checkboxWrap}
            role='button'
            tabIndex={0}
            onKeyDown={() => setRememberMeSelected(!rememberMeSelected)}
            onClick={() => setRememberMeSelected(!rememberMeSelected)}
          >
            <div
              aria-label='Toggle Remember Me'
              className={classNames(styles.checkbox, { [styles.active]: rememberMeSelected })}
            />
            <span>Remember me</span>
          </div>
          {(errorMessage && isEmpty(validationErrors)) && <span className={styles.error}>{errorMessage}</span>}
          <Separator margin='20px 0' />
          <div className={styles.buttonsWrapper}>
            <Button
              actionName='Login'
              className={styles.loginButton}
              action={() => dispatch(login(email, password, navigate))}
            />
            <SecondaryButton
              name='Damn, I forgot my password'
              className={styles.forgotPasswordButton}
              onClick={() => navigate('/forgot-password')}
            />
          </div>
          <div className={styles.registerWrapper}>
            <span>Don&apos;t have an account yet?</span>
            <SecondaryButton
              name='Register'
              className={styles.registerButton}
              onClick={() => navigate('/sign-up')}
            />
          </div>
        </div>
      </div>
      <AuthFooter />
    </AuthPage>
  );
}

export default Login;
