import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

const envCheck = process.env.NODE_ENV === 'production';
console.info('envCheck', envCheck);
console.info('process.env.NODE_ENV', process.env.NODE_ENV);

const Mixpanel = {
  identify: (id) => {
    if (envCheck) mixpanel.identify(id);
  },
  track: (name, props) => {
    if (envCheck) mixpanel.track(name, props);
  },
};

export default Mixpanel;
