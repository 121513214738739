import React from 'react';
import { ToastContainer } from 'react-toastify';
import './Toast.scss';

function Toast() {
  return (
    <ToastContainer
      position='top-right'
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='light'
    />
  );
}

export default Toast;
