/* eslint-disable @typescript-eslint/naming-convention */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { animateScroll } from 'react-scroll';
import OutsideClickHandler from 'react-outside-click-handler';
import { Outline } from '../../../core/components/Outline/Outline';
import { RootState } from '../../../../reducers';
import { AddNewButton } from '../../../core/components/AddNewButton/AddNewButton';
import AdditionalTopics from '../../../core/components/AdditionalTopics/AdditionalTopics';
import Separator from '../../../core/components/Separator/Separator';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import {
  generateFullArticleAdvanced,
  resetSections,
  setActiveStep,
} from '../../../../actions/content-creation-new';
import { showPreviousStepWarning } from '../../../../actions/content-creation';
import EstimationCard from '../../../core/components/EstimationCard/EstimationCard';
import languages from '../../../../assets/json/languages.json';
import Layout from './Layout';
import LoaderAI from '../../../core/components/LoaderAI/LoaderAI';
import { GENERATE_OUTLINES_LOADER } from '../../../../constants/loaders-new';
import styles from './styles.module.scss';
import Mixpanel from '../../../../util/Mixpanel';
import { IOutline } from '../interfaces';
import Input from '../../../core/components/InputAI/Input';

const Outlines = ({ form }) => {
  const {
    outlines,
    outlinesSerp,
    loader,
  } = useSelector((state: RootState) => state.contentCreationNew);

  const [paragraphs, setParagraphs] = useState<IOutline[]>(outlines);
  const [serpArray, setSerpArray] = useState<string[]>(outlinesSerp);
  const [titleEditMode, setTitleEditMode] = useState<any>(false);

  const { errors, touched } = form;

  const modifiedParagraphs = paragraphs.map((item) => {
    const h2_title = item.title;
    const h2_key_messages = item.keyMessages;

    const h3_subheadings = item.subheadings.map((subheading) => {
      const h3_title = subheading.title;
      const h3_key_messages = subheading.keyMessages;

      const h4_subheadings = subheading.subheadings.map((h4Item) => {
        const h4_title = h4Item.title;
        const h4_key_messages = h4Item.keyMessages;

        return {
          h4_title,
          h4_key_messages,
        };
      });

      return {
        h3_title,
        h3_key_messages,
        h4_subheadings,
      };
    });

    return {
      h2_title,
      h2_key_messages,
      h3_subheadings,
    };
  });

  // Scroll to the top of the page.
  useEffect(() => {
    animateScroll.scrollToTop({
      containerId: 'scrollerContainer',
      duration: 0,
      smooth: false,
    });
  }, []);

  useEffect(() => {
    setParagraphs(outlines);
  }, [outlines]);

  useEffect(() => {
    setSerpArray(outlinesSerp);
  }, [outlinesSerp]);

  const dispatch = useDispatch();

  // Find the language object in the 'languages' array that matches the language ID from the form values
  const selectedLanguage: any = useMemo(() => {
    const lang = languages.find((language) => language.id === form.values.language) || {};
    return lang;
  }, [form.values.language]);

  // Retrieve language code and Google domain for the selected language
  const { crowdin_code: languageCode } = selectedLanguage;

  const addNewParagraph = (indexes, tag, value = 'New Outline') => {
    let arr: IOutline[] = [];
    let upperArr: IOutline[] = [];
    let ind = -1;
    let i = 0;
    const depth = indexes.length;

    let outlineLevel = 0;

    switch (tag) {
      case 'h2':
        outlineLevel = 1;
        break;
      case 'h3':
        outlineLevel = 2;
        break;
      case 'h4':
        outlineLevel = 3;
        break;
      default:
        outlineLevel = 0;
        break;
    }

    console.info('indexes', indexes);
    console.info('outlineLevel', outlineLevel);

    const toChangeOutlines = [...paragraphs];

    if (indexes[0] === -1) {
      toChangeOutlines.splice(0, 0, { title: value, keyMessages: [], subheadings: [] });
      setParagraphs(toChangeOutlines);
      return;
    }

    arr = toChangeOutlines;
    while (i < depth && i < outlineLevel) {
      ind = indexes[i];
      upperArr = arr;
      arr = arr[ind].subheadings;
      i += 1;
    }

    // UBACI SUBHEADINGE
    const currentIndex = indexes[outlineLevel - 1];
    upperArr.splice(currentIndex + 1, 0, { title: value, keyMessages: [], subheadings: [] });

    // if (currentIndex) {
    //   const temp = upperArr[currentIndex - 1];
    //   upperArr.splice(currentIndex + 1, 0, { title: value, keyMessages: [], subheadings: temp.subheadings });
    //   temp.subheadings = [];
    // } else {
    // }

    setParagraphs(toChangeOutlines);
  };

  const upFunction = (indexes: number[]) => {
    let arr: IOutline[] = [];
    let upperArr: IOutline[] = [];
    let ind = -1;
    let i = 0;
    const depth = indexes.length;

    const toChangeOutlines = [...paragraphs];
    arr = toChangeOutlines;
    while (i < depth) {
      ind = indexes[i];
      upperArr = arr;
      arr = arr[ind].subheadings;
      i += 1;
    }

    const currentIndex = indexes[depth - 1];
    const temp = upperArr[currentIndex];
    upperArr[currentIndex] = upperArr[currentIndex - 1];
    upperArr[currentIndex - 1] = temp;

    setParagraphs(toChangeOutlines);
  };

  const downFunction = (indexes: number[]) => {
    let arr: IOutline[] = [];
    let upperArr: IOutline[] = [];
    let ind = -1;
    let i = 0;
    const depth = indexes.length;

    const toChangeOutlines = [...paragraphs];
    arr = toChangeOutlines;
    while (i < depth) {
      ind = indexes[i];
      upperArr = arr;
      arr = arr[ind].subheadings;
      i += 1;
    }

    const OUTLINE = upperArr[indexes[depth - 1]];
    const currentIndex = indexes[depth - 1];
    upperArr[currentIndex] = upperArr[currentIndex + 1];
    upperArr[currentIndex + 1] = OUTLINE;

    setParagraphs(toChangeOutlines);
  };

  const deleteFunction = (indexes: number[]) => {
    let arr: IOutline[] = [];
    let upperArr: IOutline[] = [];
    let ind = -1;
    let i = 0;
    const depth = indexes.length;

    const toChangeOutlines = [...paragraphs];
    arr = toChangeOutlines;
    while (i < depth) {
      ind = indexes[i];
      upperArr = arr;
      arr = arr[ind].subheadings;
      i += 1;
    }
    upperArr.splice(indexes[depth - 1], 1);

    setParagraphs(toChangeOutlines);
  };

  const editOutline = (indexes: number[], title) => {
    let arr: IOutline[] = [];
    let upperArr: IOutline[] = [];
    let ind = -1;
    let i = 0;
    const depth = indexes.length;

    const toChangeOutlines = [...paragraphs];
    arr = toChangeOutlines;
    while (i < depth) {
      ind = indexes[i];
      upperArr = arr;
      arr = arr[ind].subheadings;
      i += 1;
    }
    const currentIndex = indexes[depth - 1];
    const newOutline = upperArr[indexes[depth - 1]];
    newOutline.title = title;

    upperArr[currentIndex] = newOutline;

    setParagraphs(toChangeOutlines);
  };

  const addFromSerp = (topic, index) => {
    const temp = [...paragraphs];
    temp.splice(paragraphs.length, 0, { title: topic, keyMessages: [], subheadings: [] });
    setParagraphs(temp);

    const newArray = [...serpArray];
    newArray.splice(index, 1);
    setSerpArray(newArray);
  };

  const onClickForInput = () => {
    if (form.values.title.length > 5) {
      setTitleEditMode(!titleEditMode);
    }
  };

  const numOfParagraphs = {
    short: 3,
    medium: 5,
    long: 7,
  }[form.values.articleLength];

  return (
    <Layout columns={2}>
      <EstimationCard
        title='Outline'
        description='In this step you can choose your paragraphs.
        See our suggestions on the right.
        On the bottom you can add additional paragraphs based on trending topics.
        Move paragraphs up and down to get them in the right order.'
        wordCount={paragraphs.length > 0 ? (
          <span>
            <span style={{ color: '#ababab' }}>Estimated word count</span>
            {' '}
            <span style={{ color: '#000' }}>{paragraphs.length * 150 + 400}</span>
          </span>
        ) : null}
        infoBox={`Tip: based on a ${form.values.articleLength} text length we advice you to use ${numOfParagraphs} paragraphs.`}
      />
      <div>
        {loader === GENERATE_OUTLINES_LOADER ? (
          <LoaderAI
            pageLoader
            pageLoaderMainText='Just a second, we’re generating your outlines…'
          />
        ) : (
          <>
            {/* <div className={styles.caption}>H1 (Title)</div> */}
            {!titleEditMode && (
            <div
              tabIndex={0}
              role='button'
              className={classNames(styles.mainTitle, styles.titleOutlines)}
              onClick={() => {
                if (!titleEditMode) {
                  setTitleEditMode(true);
                }
              }}
              onKeyDown={() => {
                if (!titleEditMode) {
                  setTitleEditMode(true);
                }
              }}
            >
              <div className={styles.h1Tag}>H1</div>
              {form.values.title}
            </div>
            )}
            {titleEditMode && (
              <OutsideClickHandler onOutsideClick={() => {
                if (form.values.title.length > 5) {
                  setTitleEditMode(false);
                }
              }}
              >
                <Input
                  value={form.values.title}
                  placeholder='Enter your title'
                  type='text'
                  showCheckIcon
                  onClickOnShowIcon={onClickForInput}
                  onChange={e => form.setFieldValue('title', e.target.value)}
                  checkButtonDisabled={form.values.title.length < 6}
                />
                {(errors.title || touched.title) && (
                  <div className={styles.error}>{String(errors.title)}</div>
                )}
              </OutsideClickHandler>
            )}
            {/* <div className={styles.caption}>Introduction will be generated in the next step</div> */}
            <div className={styles.outlinesWrapper}>
              <AddNewButton
                onButtonClick={(tag) => addNewParagraph([-1], tag)}
                allowedTags={['h2']}
              />
              {paragraphs.map((paragraph, index) => (
                <>
                  <Outline
                    title={paragraph.title}
                    key={paragraph.title}
                    tag='H2'
                    upFunction={(index <= 0) ? undefined : () => upFunction([index])}
                    downFunction={(index >= paragraphs.length - 1) ? undefined : () => downFunction([index])}
                    deleteFunction={() => deleteFunction([index])}
                    onClickOnEditIcon={(title) => editOutline([index], title)}
                  />
                  <AddNewButton
                    onButtonClick={(tag) => addNewParagraph([index], tag)}
                    allowedTags={['h2', 'h3']}
                  />
                  {paragraph.subheadings.map((subParagraph, subIndex) => (
                    <>
                      <Outline
                        title={subParagraph.title}
                        key={subParagraph.title}
                        tag='H3'
                        upFunction={(subIndex <= 0) ? undefined : () => upFunction([index, subIndex])}
                        downFunction={(subIndex >= paragraph.subheadings.length - 1) ? undefined : () => downFunction([index, subIndex])}
                        deleteFunction={() => deleteFunction([index, subIndex])}
                        onClickOnEditIcon={(title) => editOutline([index, subIndex], title)}
                      />
                      <AddNewButton
                        onButtonClick={(tag) => addNewParagraph([index, subIndex], tag)}
                        allowedTags={['h3', 'h4']}
                      />
                      {subParagraph.subheadings.map((subSubParagraph, subSubIndex) => (
                        <>
                          <Outline
                            title={subSubParagraph.title}
                            key={subSubParagraph.title}
                            tag='H4'
                            upFunction={(subSubIndex <= 0) ? undefined : () => upFunction([index, subIndex, subSubIndex])}
                            downFunction={(subSubIndex >= subParagraph.subheadings.length - 1) ? undefined
                              : () => downFunction([index, subIndex, subSubIndex])}
                            deleteFunction={() => deleteFunction([index, subIndex, subSubIndex])}
                            onClickOnEditIcon={(title) => editOutline([index, subIndex, subSubIndex], title)}
                          />
                          <AddNewButton
                            onButtonClick={(tag) => addNewParagraph([index, subIndex, subSubIndex], tag)}
                            allowedTags={['h2', 'h3', 'h4']}
                          />
                        </>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </div>
            {!!serpArray.length && (
              <AdditionalTopics
                topics={serpArray}
                addTopic={(topic, index) => addFromSerp(topic, index)}
              />
            )}
            <Separator margin='40px 0' />
            <StepperFooter
              nextStep={() => {
                dispatch(setActiveStep(4));
                dispatch(generateFullArticleAdvanced(
                  languageCode,
                  form.values.subject,
                  form.values.primarySEO[0],
                  form.values.secondarySEO,
                  form.values.title,
                  form.values.toneOfVoice,
                  modifiedParagraphs,
                ));
                Mixpanel.track('Content Creation - Outlines - Generate and edit outlines');
              }}
              actionName='generate blog'
              footerLeftIcon='magicWand'
              previousStep={() => {
                dispatch(showPreviousStepWarning(() => {
                  dispatch(setActiveStep(2));
                  dispatch(resetSections('outlines'));
                  dispatch(resetSections('outlinesSerp'));
                }));
              }}
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default Outlines;
