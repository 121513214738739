import React from 'react';
import styles from './EstimationCard.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { EstimationCardProps } from './interfaces';

const EstimationCard: React.FC<EstimationCardProps> = ({
  title,
  description,
  wordCount,
  infoBox,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.heading}>
      <span className={styles.icon}>{renderIcon('magicWand')}</span>
      <span className={styles.title}>{title}</span>
    </div>
    <div className={styles.description}>
      {description}
    </div>
    <div className={styles.estimation}>
      <span className={styles.count}>{wordCount}</span>
    </div>
    <div className={styles.infoBox}>
      <span className={styles.infoIcon}>i</span>
      <span>
        {infoBox}
      </span>
    </div>
  </div>
);

export default EstimationCard;
