import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Modal from '../Modal';
import { KEY_MESSAGES_MODAL } from '../../../../constants/modal';
import styles from './KeyMessages.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { Button } from '../Button/button';
import { RootState } from '../../../../reducers';
import { Message } from './interfaces';
import Separator from '../Separator/Separator';
import { closeModal } from '../../../../actions/modal';

const KeyMessagesModal = ({ saveChanges, actionName = null }) => {
  const {
    messages,
    title,
    outlineIndex,
  } = useSelector((state: RootState) => state.modal.data);

  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const [numOfSelected, setNumOfSelected] = useState<number>(0);

  const dispatch = useDispatch();

  // Update the 'allMessages' state with the value of 'messages' or an empty array if 'messages' is falsy.
  useEffect(() => {
    setAllMessages(messages || []);
  }, [messages]);

  // Calculate the number of selected messages in the 'allMessages' array and update the 'numOfSelected' state accordingly.
  useEffect(() => {
    let selectedNumber = 0;
    allMessages.forEach(msg => {
      if (msg.selected) selectedNumber += 1;
    });
    setNumOfSelected(selectedNumber);
  }, [allMessages]);

  const handleClickMessage = (message: Message, index: number) => {
    const selectedArray = [...allMessages];
    // If the message is already selected, deselect it
    if (message.selected) {
      selectedArray[index].selected = false;
      // If the number of selected messages is less than 5 and the message is not empty, select it
    } else if (numOfSelected < 5 && message.message !== '') {
      selectedArray[index].selected = true;
    }

    setAllMessages(selectedArray);
  };

  const addNewMessage = () => {
    // Check if the last message in allMessages is not empty
    if (allMessages[allMessages.length - 1].message !== '') {
      // Add a new message object to the allMessages array
      setAllMessages([...allMessages, { message: '', deletable: true, selected: false } as Message]);
    }

    // Scroll to the bottom of the message wrapper
    setTimeout(() => {
      const wrapper = document.getElementById('keymessages-wrapper');
      if (wrapper) {
        wrapper.scrollTop = wrapper.scrollHeight;
      }
    }, 50);
  };

  const handleRemoveMessage = (index: number) => {
    const newSelected = allMessages;
    // Update allMessages by filtering out the message at the specified index
    setAllMessages(newSelected.filter((x, id) => id !== index));
  };

  const handleChangeMessage = (index: number, sentence: string) => {
    const newMessages = [
      ...allMessages,
    ];

    // Update the message at the specified index with the new sentence
    newMessages[index].message = sentence;

    // Update the allMessages state with the modified newMessages array
    setAllMessages(newMessages);
  };

  const handleSaveButton = () => {
    // const newMessages = allMessages.filter(msg => msg.selected).map(msg => msg.message);

    saveChanges(allMessages, outlineIndex);
  };

  return (
    <Modal
      name={KEY_MESSAGES_MODAL}
      modalWidth='1090px'
    >
      <div className={styles.keyMessagesModal}>
        <div className={styles.header}>
          <div className={styles.heading}>
            <div className={styles.title}>Topics</div>
            <div className={styles.subtitle}>{title}</div>
          </div>
          <div className={styles.actions}>
            <Button
              actionName='cancel'
              action={() => dispatch(closeModal())}
              className={styles.cancelButton}
            />
            <Button
              actionName={actionName || 'regenerate'}
              action={handleSaveButton}
              className={styles.saveButton}
              leftIcon='magicWand'
            />
          </div>
        </div>
        <Separator margin='32px 0 23px 0' />
        <span>Select or add topics you want to cover in this paragraph</span>
        <div
          className={styles.messagesWrapper}
          id='keymessages-wrapper'
        >
          {allMessages && allMessages.map((msg, index) => (
            <div
              className={classNames(styles.messageWrapper, {
                [styles.isActive]: msg.selected,
                [styles.isDeletable]: msg.deletable,
              })}
            >
              <div
                className={styles.checkbox}
                onClick={() => handleClickMessage(msg, index)}
                onKeyDown={() => handleClickMessage(msg, index)}
                tabIndex={0}
                role='button'
                data-tooltip-id='tooltip'
                data-tooltip-content={numOfSelected === 5 && !msg.selected ? 'You can select up to 5 key messages' : ''}
              >
                {renderIcon('check')}
              </div>
              {index + 1 === allMessages.length && msg.deletable
                ? (
                  <input
                    className={styles.message}
                    value={msg.message}
                    placeholder='Enter your own key message'
                    onChange={(e) => handleChangeMessage(index, e.target.value)}
                  />
                )
                : (
                  <div
                    className={styles.message}
                    onClick={() => handleClickMessage(msg, index)}
                    onKeyDown={() => handleClickMessage(msg, index)}
                    tabIndex={0}
                    role='button'
                  >
                    {msg.message}
                  </div>
                )}
              {msg.deletable
                ? (
                  <div
                    className={styles.deleteButton}
                    onClick={() => handleRemoveMessage(index)}
                    onKeyDown={() => handleRemoveMessage(index)}
                    tabIndex={0}
                    role='button'
                  >
                    {renderIcon('bucket')}
                  </div>
                )
                : ''}
            </div>
          ))}
        </div>
        <Button
          className={styles.addButton}
          actionName='+ add own topic'
          action={addNewMessage}
        />
      </div>
    </Modal>
  );
};

export default KeyMessagesModal;
