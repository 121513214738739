import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';
import Input from '../../../core/components/InputAI/Input';
import { Button, SecondaryButton } from '../../../core/components/Button/button';
import ReviewsBox from '../ReviewsBox';
import AuthPage from '../index';
import Separator from '../../../core/components/Separator/Separator';
import { register } from '../../../../actions/auth';

function Registration() {
  const [agreeButtonSelected, setAgreeButtonSelected] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const { validationErrors, errorMessage } = useSelector(state => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRegister = useCallback(() => {
    setError(!agreeButtonSelected);

    if (agreeButtonSelected) {
      dispatch(register(email, firstName, lastName, password, confirmPass, navigate));
    }
  }, [agreeButtonSelected, dispatch, email, firstName, lastName, password, confirmPass, navigate]);

  return (
    <AuthPage>
      <div className={styles.smallLogo}>
        {renderIcon('logo')}
        <span>Contentoo platform</span>
      </div>
      <div className={styles.largeLogo}>
        {renderIcon('contentooLogo')}
      </div>
      <ReviewsBox />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Get free access to our cutting-edge AI marketing tools
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.form}>
            <span className={styles.subtitle}>
              First, tell us a bit about yourself.
            </span>
            <Input
              label='First name'
              placeholder='Enter your first name'
              type='text'
              onChange={e => setFirstName(e.target.value)}
              error={validationErrors?.first_name}
            />
            <Input
              label='Last name'
              placeholder='Enter your last name'
              type='text'
              onChange={e => setLastName(e.target.value)}
              error={validationErrors?.last_name}
            />
            <Input
              label='Job function'
              placeholder='Marketeer, Product Owner, etc.'
              type='text'
            />
            <Input
              label='Email'
              placeholder='Enter your email'
              type='email'
              onChange={e => setEmail(e.target.value)}
              error={validationErrors?.email}
            />
            <Separator margin='20px 0' />
          </div>
          <div className={styles.form}>
            <span className={styles.subtitle}>
              Now we need some information about your company.
            </span>
            <Input
              label='Company name'
              placeholder='Enter your company name'
              type='text'
            />
            <Separator margin='20px 0' />
          </div>
          <div className={styles.form}>
            <span className={styles.subtitle}>
              To create your account we need a password.
            </span>
            <Input
              label='Password'
              placeholder='Enter your password'
              type='password'
              onChange={e => setPassword(e.target.value)}
              message='Use 8 or more characters with a mix of letters, numbers and symbols'
              error={validationErrors?.password}
            />
            <Input
              label='Repeat password'
              placeholder='Confirm password'
              type='password'
              onChange={e => setConfirmPass(e.target.value)}
            />
          </div>
          <div className={styles.checkboxWrap}>
            <div
              role='button'
              tabIndex={0}
              aria-label='Toggle Agree Button'
              onKeyDown={() => setAgreeButtonSelected(!agreeButtonSelected)}
              onClick={() => setAgreeButtonSelected(!agreeButtonSelected)}
              className={classNames(styles.checkbox, { [styles.active]: agreeButtonSelected })}
            />
            <div className={styles.sentence}>
              By signing up I agree with the
              <span className={styles.link}>
                <a
                  href='https://contentoo.com/general-terms/'
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  terms & conditions
                  {' '}
                </a>
              </span>
              and the
              <span className={styles.link}>
                <a
                  href='https://contentoo.com/privacy-statement/'
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  data protection & privacy policy
                  {' '}
                </a>
              </span>
              {error && (
                <div className={styles.error}>
                  Please agree to the terms and conditions.
                </div>
              )}
            </div>
          </div>
          {(errorMessage && isEmpty(validationErrors)) && <span className={styles.error}>{errorMessage}</span>}
          <Button
            actionName='Register'
            className={styles.registerButton}
            action={handleRegister}
          />
          <div className={styles.loginWrapper}>
            <span>Already have an account?</span>
            <SecondaryButton
              name='Login'
              className={styles.loginButton}
              onClick={() => navigate('/login')}
            />
          </div>
        </div>
      </div>
    </AuthPage>
  );
}

export default Registration;
