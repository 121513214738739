export const GENERATE_TITLES = 'GENERATE_TITLES_CONTENT_CREATION';
export const GENERATE_SUMMARIES = 'GENERATE_SUMMARIES_CONTENT_CREATION';
export const GENERATE_OUTLINES = 'GENERATE_OUTLINES_CONTENT_CREATION';
export const GENERATE_KEY_MESSAGES = 'GENERATE_KEY_MESSAGES_CONTENT_CREATION';
export const SET_KEY_MESSAGES = 'SET_KEY_MESSAGES_CONTENT_CREATION';
export const GENERATE_INTRODUCTIONS = 'GENERATE_INTRODUCTIONS_CONTENT_CREATION';
export const GENERATE_PARAGRAPH = 'GENERATE_PARAGRAPH_CONTENT_CREATION';
export const GENERATE_AND_REPLACE_PARAGRAPH = 'GENERATE_AND_REPLACE_PARAGRAPH_CONTENT_CREATION';
export const GENERATE_CONCLUSION = 'GENERATE_CONCLUSION_CONTENT_CREATION';
export const SET_SECTION_VALUE = 'SET_SECTION_VALUE_CONTENT_CREATION';
export const RESET_SECTIONS = 'RESET_SECTIONS_CONTENT_CREATION';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP_CONTENT_CREATION';
export const SET_ACTIVE_CARD = 'SET_ACTIVE_CARD_CONTENT_CREATION';
export const TOGGLE_LOADER = 'TOGGLE_LOADER_CONTENT_CREATION';
export const SET_DOWNLOAD_REQUESTED = 'SET_DOWNLOAD_REQUESTED_CONTENT_CREATION';
export const RESET_REDUCER = 'RESET_REDUCER_CONTENT_CREATION';
export const SET_PREVIOUS_PROGRESS_LOADER = 'SET_PREVIOUS_PROGRESS_LOADER_CONTENT_CREATION';
export const SET_STORE = 'SET_STORE_CONTENT_CREATION';
export const SET_SERP_CONTEXT = 'SET_SERP_CONTEXT_CONTENT_CREATION';
export const DELETE_ADDITIONAL_TOPIC = 'DELETE_ADDITIONAL_TOPIC_CONTENT_CREATION';
export const LIST_ADDITIONAL_TOPICS = 'LIST_ADDITIONAL_TOPICS_CONTENT_CREATION';
