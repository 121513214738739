import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ToolCard from './ToolCard';
import styles from './styles.module.scss';
import renderIcon from '../../../util/renderIcon';

function Landing() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${document.title} | Landing page`;
    const token = localStorage.getItem('token');
    console.info('token', token);
    if (token) {
      navigate('/tools');
    }
  }, []);
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Contentoo AI-Powered Content Marketing Tools</h1>
      <h5 className={styles.subtitle}>
        Generate persuasive marketing copies that convert and drive results with free AI writing and SEO tools by Contentoo
      </h5>

      <div className={styles.toolbox}>
        <ToolCard
          title='AI Content Refresh'
          subtitle='Performance-optimise legacy content at any stage of your conversion funnel.'
          primaryButtonName='Check it out!'
          primaryAction={() => navigate('/content-refresh')}
          icon={renderIcon('refreshFlow')}
        />
        <ToolCard
          title='AI Content Creation'
          subtitle='Create unique, ownable content that cuts through with the top of your conversion funnel.'
          primaryButtonName='Check it out!'
          primaryAction={() => navigate('/content-creation')}
          icon={renderIcon('creationFlow')}
        />
        <ToolCard
          title='AI Product Descriptions'
          subtitle='Create high quality product descriptions that perform at scale.'
          primaryButtonName='Coming soon!'
          icon={renderIcon('volumeFlow')}
          disabled
        />
        <ToolCard
          title='AI Briefing Generator'
          primaryButtonName='Coming soon!'
          icon={renderIcon('pen')}
          disabled
        />
        <ToolCard
          title='AI Content Assessment'
          primaryButtonName='Coming soon!'
          icon={renderIcon('pen')}
          disabled
        />
        <ToolCard
          title='AI Quality Checker'
          primaryButtonName='Coming soon!'
          icon={renderIcon('pen')}
          disabled
        />
      </div>
    </div>
  );
}

export default Landing;
