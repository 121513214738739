import React from 'react';
import styles from './AdditionalTopics.module.scss';
import renderIcon from '../../../../util/renderIcon';

const AdditionalTopics = ({
  topics,
  addTopic,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.heading}>
      <div className={styles.icon}>
        {renderIcon('thunder')}
      </div>
      <div className={styles.title}>
        Add more paragraphs based on trending topics
      </div>
    </div>
    <div className={styles.subheading}>
      Our data sources identified these trending topics that might be interesting to add.
    </div>
    <div className={styles.topicsWrapper}>
      {topics.map((topic, index) => (
        <div className={styles.topic}>
          <span className={styles.sentence}>{topic}</span>
          <div className={styles.rightSide}>
            <span className={styles.bluePill}>
              <span className={styles.icon}>{renderIcon('trending')}</span>
              <span>Trending</span>
            </span>
            <span
              role='button'
              tabIndex={0}
              className={styles.addButton}
              onClick={() => addTopic(topic, index)}
              onKeyDown={() => addTopic(topic, index)}
            >
              {renderIcon('plus')}
            </span>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default AdditionalTopics;
