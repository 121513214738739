import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './AddNewButton.module.scss';
import { AddNewButtonProps, ParagraphTag } from './interfaces';
import renderIcon from '../../../../util/renderIcon';

// Needs to be outside of class to prevent re-rendering
let hoverTimeout: NodeJS.Timeout | null = null;

export const AddNewButton: React.FC<AddNewButtonProps> = ({ onButtonClick, allowedTags = ['h2', 'h3', 'h4'] }) => {
  const tags: ParagraphTag[] = ['h2', 'h3', 'h4'];

  const [isTagsWrapperVisible, setIsTagsWrapperVisible] = useState(false);

  const handleMouseOver = () => {
    // Clear all timeouts that are waiting for execution
    hoverTimeout && clearTimeout(hoverTimeout);

    setIsTagsWrapperVisible(true);
  };

  const handleMouseOut = () => {
    hoverTimeout = setTimeout(() => setIsTagsWrapperVisible(false), 300);
  };

  const actionClicked = (tag) => {
    if (allowedTags.includes(tag)) {
      onButtonClick(tag);
      setIsTagsWrapperVisible(false);
    }
  };

  return (
    <div className={styles.mainWrapper}>
      <button
        type='button'
        className={styles.addButton}
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onBlur={handleMouseOut}
      >
        {renderIcon('plus')}
      </button>
      <div className={styles.line} />
      {isTagsWrapperVisible && (
      <div
        className={styles.tagsWrapper}
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onBlur={handleMouseOut}
      >
        {tags.map(tag => (
          <div
            tabIndex={0}
            role='button'
            className={classNames(styles.tag, { [styles.disabled]: !allowedTags.includes(tag) })}
            onClick={() => actionClicked(tag)}
            onKeyDown={() => actionClicked(tag)}
          >
            {renderIcon('plus')}
            <span>{tag.toUpperCase()}</span>
          </div>
        ))}
      </div>
      )}
    </div>
  );
};
