export const EXTRACT_URL = 'CONTENT_REFRESH/EXTRACT_URL';
export const TOGGLE_INFORMATION_BOX = 'CONTENT_REFRESH/TOGGLE_INFORMATION_BOX';
export const EXTRACT_SUBJECT = 'CONTENT_REFRESH/EXTRACT_SUBJECT';
export const EXTRACT_KEYWORDS = 'CONTENT_REFRESH/EXTRACT_KEYWORDS';
export const GENERATE_TITLES = 'CONTENT_REFRESH/GENERATE_TITLES';
export const EXTRACT_SUMMARY = 'CONTENT_REFRESH/EXTRACT_SUMMARY';
export const EXTRACT_OUTLINES = 'CONTENT_REFRESH/EXTRACT_OUTLINES';
export const EXTRACT_KEY_MESSAGES = 'CONTENT_REFRESH/EXTRACT_KEY_MESSAGES';
export const GENERATE_INTRODUCTIONS = 'CONTENT_REFRESH/GENERATE_INTRODUCTIONS';
export const EXTRACT_PARAGRAPH = 'CONTENT_REFRESH/EXTRACT_PARAGRAPH';
export const EXTRACT_AND_REPLACE_PARAGRAPH = 'CONTENT_REFRESH/EXTRACT_AND_REPLACE_PARAGRAPH';
export const EXTRACT_CONCLUSION = 'CONTENT_REFRESH/EXTRACT_CONCLUSION';
export const RESET_SECTIONS = 'CONTENT_REFRESH/RESET_SECTIONS';
export const TOGGLE_LOADER = 'CONTENT_REFRESH/TOGGLE_LOADER';
export const SET_SECTION_VALUE = 'CONTENT_REFRESH/SET_SECTION_VALUE';
export const SET_ACTIVE_STEP = 'CONTENT_REFRESH/SET_ACTIVE_STEP';
export const SET_ACTIVE_CARD = 'CONTENT_REFRESH/SET_ACTIVE_CARD';
export const SET_INPUT_SOURCE_URL = 'CONTENT_REFRESH/SET_INPUT_SOURCE_URL';
export const SET_INPUT_SOURCE_TYPE = 'CONTENT_REFRESH/SET_INPUT_SOURCE_TYPE';
export const SET_UPLOADED_FILE = 'CONTENT_REFRESH/SET_UPLOADED_FILE';
export const SET_DOWNLOAD_REQUESTED = 'CONTENT_REFRESH/SET_DOWNLOAD_REQUESTED';
export const RESET_REDUCER = 'CONTENT_REFRESH/RESET_REDUCER';
export const SET_PREVIOUS_PROGRESS_LOADER = 'CONTENT_REFRESH/SET_PREVIOUS_PROGRESS_LOADER';
export const SET_STORE = 'CONTENT_REFRESH/SET_STORE';
export const SET_SERP_CONTEXT = 'CONTENT_REFRESH/SET_SERP_CONTEXT';
export const EXTRACT_WHOLE_ARTICLE = 'CONTENT_REFRESH/EXTRACT_WHOLE_ARTICLE';
export const SET_PARAGRAPH = 'CONTENT_REFRESH/SET_PARAGRAPH';
export const ADD_PARAGRAPH = 'CONTENT_REFRESH/ADD_PARAGRAPH';
export const DELETE_PARAGRAPHS = 'CONTENT_REFRESH/DELETE_PARAGRAPHS';
export const CHANGE_PARAGRAPH_TYPES = 'CONTENT_REFRESH/CHANGE_PARAGRAPH_TYPES';
export const SET_INTRODUCTION = 'CONTENT_REFRESH/SET_INTRODUCTION';
export const SET_CONCLUSION = 'CONTENT_REFRESH/SET_CONCLUSION';
export const PARAGRAPH_IN_DEPTH_LOADER = 'CONTENT_REFRESH/PARAGRAPH_IN_DEPTH_LOADER';
export const GENERATE_IN_DEPTH_PARAGRAPH = 'CONTENT_REFRESH/GENERATE_IN_DEPTH_PARAGRAPH';
export const SHOW_LOADER = 'CONTENT_REFRESH/SHOW_LOADER';
export const HIDE_LOADER = 'CONTENT_REFRESH/HIDE_LOADER';
export const REGENERATE_PARAGRAPH = 'CONTENT_REFRESH/REGENERATE_PARAGRAPH';
