import * as types from '../constants/content-creation';

export interface ContentCreationState {
  activeStep: number;
  activeCard: number;
  titles: string[];
  summaries: string[];
  outlines: string[];
  outlinesSerp: string[];
  keyMessages: string[];
  introductions: string[];
  outlineContent: string[];
  paragraphs: string[];
  conclusions: string[];
  loader: boolean;
  downloadRequested: boolean;
  previousProgressLoader: boolean;
  serpContext?: string | null;
  additionalTopics: string[];
}

const initialState = {
  activeStep: 1,
  activeCard: 1,
  titles: [],
  summaries: [],
  outlines: [],
  outlinesSerp: [],
  keyMessages: [],
  introductions: [],
  outlineContent: [],
  paragraphs: [],
  conclusions: [],
  loader: false,
  downloadRequested: false,
  previousProgressLoader: false,
  serpContext: null,
  additionalTopics: [],
};

export default function contentCreation(state: ContentCreationState = initialState, action) {
  switch (action.type) {
    case types.SET_SERP_CONTEXT:
      return {
        ...state,
        serpContext: action.payload,
      };
    case types.RESET_SECTIONS: {
      const newState = {
        ...state,
      };

      action.payload.forEach((section) => {
        newState[section] = initialState[section];
      });

      return newState;
    }
    case types.SET_SECTION_VALUE:
      return {
        ...state,
        [action.payload.section]: action.payload.value,
      };
    case types.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case types.SET_ACTIVE_CARD:
      return {
        ...state,
        activeCard: action.payload,
      };
    case types.SET_DOWNLOAD_REQUESTED:
      return {
        ...state,
        downloadRequested: true,
      };
    case types.RESET_REDUCER:
      return {
        ...initialState,
      };
    case types.GENERATE_TITLES:
      return {
        ...state,
        titles: action.payload ? [
          ...action.payload,
          '',
        ] : [],
      };
    case types.GENERATE_SUMMARIES:
      return {
        ...state,
        summaries: action.payload ? [
          ...action.payload,
          '',
        ] : [],
      };
    case types.GENERATE_OUTLINES:
      return {
        ...state,
        outlines: action.payload.outlines || [],
        outlinesSerp: action.payload.outlinesSerp || [],
      };
    case types.GENERATE_KEY_MESSAGES:
    case types.SET_KEY_MESSAGES:
      return {
        ...state,
        keyMessages: action.payload || [],
      };
    case types.GENERATE_INTRODUCTIONS:
      return {
        ...state,
        introductions: action.payload || [],
      };
    case types.GENERATE_PARAGRAPH:
      return {
        ...state,
        paragraphs: [
          ...state.paragraphs,
          action.payload || [],
        ],
      };
    case types.GENERATE_AND_REPLACE_PARAGRAPH: {
      const newParagraphs = [
        ...state.paragraphs,
      ];

      newParagraphs[action.payload.paragraphIndex] = action.payload.output;

      return {
        ...state,
        paragraphs: newParagraphs,
      };
    }
    case types.GENERATE_CONCLUSION:
      return {
        ...state,
        conclusions: action.payload || [],
      };
    case types.TOGGLE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case types.SET_PREVIOUS_PROGRESS_LOADER:
      return {
        ...state,
        previousProgressLoader: action.payload,
      };
    case types.SET_STORE:
      return {
        ...action.payload,
      };
    case types.LIST_ADDITIONAL_TOPICS:
      return {
        ...state,
        additionalTopics: action.payload,
      };
    case types.DELETE_ADDITIONAL_TOPIC: {
      const newAdditionalTopics = [...state.additionalTopics];

      newAdditionalTopics.splice(action.payload, 1);

      return {
        ...state,
        additionalTopics: newAdditionalTopics,
      };
    }
    default:
      return state;
  }
}
