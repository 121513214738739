import React, { useEffect, useState, FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { scroller } from 'react-scroll';
import * as Yup from 'yup';
import Stepper from '../../core/components/Stepper';
import Header from '../../Header';
import StepperHeader from '../../Header/StepperHeader/StepperHeader';
import BriefingPage from './Steps/BriefingPage';
import SettingsPage from './Steps/SettingsPage';
import {
  resetReducer,
  setActiveCard,
  setActiveStep,
  setPreviousProgressLoader,
  storeProgressAndSendBackendErrorEmail, storeCurrentFlow,
} from '../../../actions/content-creation-new';
import Mixpanel from '../../../util/Mixpanel';
import AIErrorModal from '../../Modals/AIErrorModal';
import ExitFlowModal from '../../Modals/ExitFlowModal';
import EditWarningModal from '../../Modals/EditWarningModal';
import PreviousStepWarning from '../../Modals/PreviousStepWarning';
import InternetConnectionError from '../../Modals/InternetConnectionError';
import { EXIT_FLOW_WARNING_MODAL } from '../../../constants/modal';
import { openModal } from '../../../actions/modal';
import styles from './styles.module.scss';
import { Step } from './interfaces';
import { RootState } from '../../../reducers';
import GeneratePage from './Steps/GeneratePage';
import Outlines from './Steps/Outlines';
import { DetailPage } from './Steps/DetailPage';
import NotFoundErrorModal from '../../Modals/NotFoundErrorModal';

const validationSchema = Yup.object().shape({
  subject: Yup.string().min(6, 'Subject must be at least 6 characters long').required('Subject is required'),
  title: Yup.string().min(6, 'Title must be at least 6 characters long').required('Title is required'),
});

const steps: Step[] = [
  { component: SettingsPage, title: 'Settings' },
  { component: BriefingPage, title: 'Briefing' },
  { component: Outlines, title: 'Outlines' },
  { component: GeneratePage, title: 'Generate' },
  { component: DetailPage, title: 'Detail Page' },
];

const ContentCreationNew: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const store = useSelector((state: RootState) => state.contentCreationNew);

  const searchParams = new URLSearchParams(location.search);
  const continueHash = searchParams.get('continue_hash');

  const {
    activeStep,
    activeCard,
    downloadRequested,
    loader,
  } = store;

  const [showMessage, setShowMessage] = useState(false);
  const [formVals, setFormVals] = useState({});
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    // Reset the relevant reducer state using the 'resetReducer' action.
    dispatch(resetReducer());

    Mixpanel.track('Content Creation - Briefing - Start Briefing');
    // Set 'previousProgressLoader' state to true if 'continueHash' has a truthy value.
    if (continueHash?.length) {
      dispatch(setPreviousProgressLoader(true));
    }

    // Return a cleanup function to be executed when the component unmounts.
    return () => {
      // Reset the 'activeStep' state to 1 using the 'setActiveStep' action.
      dispatch(setActiveStep(1));
      // Reset the 'activeCard' state to 1 using the 'setActiveCard' action.
      dispatch(setActiveCard(1));
      // Reset the relevant reducer state using the 'resetReducer' action.
      dispatch(resetReducer());
    };
  }, []);

  const CurrentStepComponent = steps[activeStep - 1].component;

  // Scroll to a specific card within a step.
  const scrollToCard = (stepNumber, cardNumber) => {
    scroller.scrollTo(`step${stepNumber}_card${cardNumber}`, {
      offset: -130,
      duration: 1000,
      smooth: true,
      containerId: 'scrollerContainer',
    });
  };

  // Scrolling to a specific card when the 'activeCard' or 'activeStep' dependencies change.
  useEffect(() => {
    setTimeout(() => scrollToCard(activeStep, activeCard), 50);
  }, [activeCard, activeStep, scrollToCard]);

  return (
    <div
      id='scrollerContainer'
      className={styles.scroller}
    >
      <AIErrorModal
        // add type for values
        action={(values) => storeProgressAndSendBackendErrorEmail(values.email, store, formVals, continueHash || '').then(() => {
          setShowMessage(true);
          setEmailSent(true);
        })}
        showMessage={showMessage}
      />
      {!downloadRequested && (
        <ExitFlowModal
          showMessage={showMessage}
          cardLoader={loader}
          // add type for email
          saveAction={(email) => {
            Mixpanel.track('Content Creation - Finalize - Click continue later');
            storeCurrentFlow(store, formVals, email, continueHash || '').then(() => setShowMessage(true));
          }}
          exitWithoutSaveAction={() => Mixpanel.track('Content Creation - Finalize - Click to exit flow')}
        />
      )}
      <EditWarningModal />
      <PreviousStepWarning />
      <InternetConnectionError />
      <NotFoundErrorModal />
      <Header>
        <StepperHeader
          title='Content Creation'
          stepper={(
            <Stepper
              steps={[
                { id: 1, title: 'Settings' },
                { id: 2, title: 'Briefing' },
                { id: 3, title: 'Outlines' },
                { id: 4, title: 'Generate' },
              ]}
              activeStep={activeStep}
              isDetail={activeStep === 5}
            />
          )}
          closeAction={() => {
            if ((activeStep === 1 && activeCard === 1) || (downloadRequested && !loader) || emailSent) {
              navigate('/');
            } else {
              dispatch(openModal(EXIT_FLOW_WARNING_MODAL));
            }
          }}
        />
      </Header>
      <div
        className={activeStep !== 5 ? styles.container : styles.finalContainer}
      >
        <Formik
          initialValues={{
            language: 10,
            toneOfVoice: 'default',
            articleLength: 'short',
            subject: '',
            blogGeneration: 'turbo',
          }}
          onSubmit={() => { console.info('Done'); }}
          validationSchema={validationSchema}
        >
          {(form) => {
            setFormVals(form.values);
            return (
              <CurrentStepComponent
                form={form}
                {...(steps[activeStep - 1].finalize ? { documentType: 'creation' } : {})}
              />
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ContentCreationNew;
