import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import ToolCardNew from '../../core/components/ToolCardNew/ToolCardNew';
import { Button } from '../../core/components/Button/button';

const IFrameTools = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.toolCardContainer}>
        <ToolCardNew
          icon='magicWand'
          iconStyles={styles.blueIcon}
          iconBackgroundColor='#DEF3FC'
          label='AI Content Creation Tool'
          labelColor='#58C2F0'
          title='Create in-dept blog posts'
          description='Create unique, ownable blog posts that cut through to the top of your conversion funnel.
        Provide us with the keywords that you want to write about, add unique insights and let us do the magic.'
          list={['Superior quality content', 'SEO optimized', 'Brief freelancer to edit or translate']}
          primaryButtonName='start now'
          primaryAction={() => window.open('/content-creation', '_blank')}
          rightIcon='arrowNext'
        />
        <ToolCardNew
          icon='thunder'
          iconStyles={styles.purpleIcon}
          iconBackgroundColor='#ECE5FA'
          label='AI Content Refresh Tool'
          labelColor='#A17EE8'
          title='Refresh blogs to boost SEO'
          description='Refresh outdated blogs at any stage of your conversion funnel.
        Provide us with a blog URL, add unique insights from our external data sources, refresh it and boost your Google rankings.'
          list={['Superior quality content', 'SEO optimized', 'Brief freelancer to edit or translate']}
          primaryButtonName='coming soon'
          primaryAction={() => window.open('/content-creation', '_blank')}
          disabled
        />
        <ToolCardNew
          icon='regenerate'
          iconStyles={styles.greenIcon}
          iconBackgroundColor='#E7F9E9'
          label='AI Content Rewrite Tool'
          labelColor='#82E190'
          title='Rewrite existing blogs'
          description='Did you come across a blog online that you like?
        Simply put that blog into our tool and add unique insights from our external data sources.
        We rewrite it into a new ownable blog post.'
          list={['Superior quality content', 'SEO optimized', 'Brief freelancer to edit or translate']}
          primaryButtonName='coming soon'
          primaryAction={() => window.open('/content-creation', '_blank')}
          disabled
        />
      </div>
      <div className={styles.supportBoxWrapper}>
        <div className={styles.supportText}>
          In need of high-volume projects or any other specific AI use cases? Get in touch with one of our AI consultants
        </div>
        <Button
          actionName='contact support'
          action={() => navigate('/')}
          className={styles.supportButton}
        />
      </div>
    </div>
  );
};

export default IFrameTools;
