import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Field, FormikProps } from 'formik';
import { animateScroll, Element } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import Card from '../../../core/components/Card/Card';
import { RootState } from '../../../../reducers';
import { NextButton, PreviousButton } from '../../../core/components/Button/button';
import RadioGroup from '../../../core/components/RadioGroup/RadioGroup';
import {
  extractWholeArticle,
  resetSections,
  setActiveCard,
  setActiveStep,
  setSectionValue,
  showPreviousStepWarning,
  generateTitles,
} from '../../../../actions/content-refresh';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import Mixpanel from '../../../../util/Mixpanel';
import Layout from '../../ContentCreationNew/Steps/Layout';
import FormikInput from '../../../core/components/FormikInput/FormikInput';
import Tags from '../../../core/components/Tags/Tags';
import {
  showLoader,
} from '../../../../actions/content-creation-new';
import Select from '../../../core/components/Select/Select';
import RadioButtonGroup from '../../../core/components/RadioButtonGroup/RadioButtonGroup';
import Separator from '../../../core/components/Separator/Separator';
import { GENERATE_OUTLINES_LOADER } from '../../../../constants/loaders-new';
import renderIcon from '../../../../util/renderIcon';
import { Option } from '../../../core/components/RadioButtonGroup/interfaces';

// Define the shape of form values using an interface
interface FormValues {
  language?: string;
  title?: string;
  input_source?: string;
  summary?: string;
  subject: string;
  primarySEO: string[];
  secondarySEO?: string[];
  toneOfVoice: string;
  blogGeneration: string;
  articleLength: string;
}

// Define the props for the SecondStep component
interface SecondStepProps {
  form: FormikProps<FormValues>;
}

const SecondStep: React.FC<SecondStepProps> = ({ form }) => {
  const dispatch = useDispatch();
  const {
    activeCard,
    article,
  } = useSelector((state: RootState) => state.contentRefresh);

  const { titles } = useSelector((state: RootState) => state.contentRefresh);

  const { errors, touched } = form;

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    animateScroll.scrollToTop({
      containerId: 'previewScroller',
      duration: 0,
      smooth: false,
    });
  }, [form?.values?.title]);

  const resetToCard = (cardNumber: number) => {
    dispatch(setActiveCard(cardNumber));

    if (cardNumber <= 1) {
      dispatch(resetSections('title'));
      form.setFieldValue('title', '');
    }
  };

  const toneOfVoiceOptions = [
    {
      value: 'default',
      label: 'Generic',
    },
    {
      value: 'formal',
      label: 'Formal',
    },
    {
      value: 'playful',
      label: 'Playful',
    },
    {
      value: 'casual',
      label: 'Casual',
    },
    {
      value: 'technical',
      label: 'Technical',
    },
    {
      value: 'academic',
      label: 'Academic',
    },
    {
      value: 'humorous',
      label: 'Humorous',
    },
    {
      value: 'professional',
      label: 'Professional',
    },
    {
      value: 'engaging',
      label: 'Engaging',
    },
    {
      value: 'inspirational',
      label: 'Inspirational',
    },
    {
      value: 'informative',
      label: 'Informative',
    },
  ];

  const languageCode = 'en';

  const blogGenerationOptions = [
    {
      id: 'turbo',
      icon: renderIcon('thunder'),
      label: 'Turbo',
      description: 'We use our AI magic to automatically generate your content.',
      caption: 'Takes only 1 min.',
    },
    // Uncomment this part of code when advanced is finished
    // {
    //   id: 'advanced',
    //   icon: renderIcon('advanced'),
    //   label: 'Advanced',
    //   description: 'Manually control the content and order of your paragraphs',
    //   caption: 'Takes only 3 min.',
    // },
  ];

  const blogGenerationButtonContent = useCallback((option: Option) => (
    <div className={styles.radioButtonWrapper}>
      <div className={styles.radioButtonHeader}>
        <div className={styles.icon}>{option.icon}</div>
        {option.label}
      </div>
      <div className={styles.radioButtonContent}>
        {option.description}
        <div className={styles.radioButtonCaption}>
          {option.caption}
        </div>
      </div>
    </div>
  ), []);

  return (
    <Layout columns={1}>
      <div style={{ rowGap: '30px' }}>
        <Element
          name='step2_card1'
          className={styles.element}
        >
          <Card
            title='What is your blog about?'
            completed={activeCard > 1}
            editAction={() => {
              resetToCard(1);
            }}
            content={(
              <>
                {activeCard === 1 && (
                  <>
                    <div className={styles.title}>Tell us more about the subject</div>
                    <Field
                      name='subject'
                      component={FormikInput}
                      label='Please describe it in one sentence'
                      placeholder='Enter main subject'
                      type='text'
                      error={errors.subject && touched.subject}
                    />
                    {(errors.subject && touched.subject) && (
                      <div className={styles.error}>{errors.subject}</div>
                    )}
                    <div className={styles.title}>What is the primary (SEO) keyword?</div>
                    <Field
                      name='primarySEO'
                      component={Tags}
                      limit={1}
                      label='We will use this to optimise your blog'
                      placeholder='Enter main keyword'
                    />
                    <div className={styles.title}>Do you have any secondary (SEO) keywords that you want to cover?</div>
                    <Field
                      name='secondarySEO'
                      component={Tags}
                      limit={5}
                      label='This helps us to further shape your blog'
                      placeholder='Enter secondary keywords'
                    />
                  </>
                )}
                {activeCard > 1 && (
                  <div className={styles.completed}>
                    <div className={styles.fieldBlock}>
                      <span className={styles.fieldTitle}>Subject</span>
                      <span className={styles.value}>{form.values.subject}</span>
                    </div>
                    <div className={styles.fieldBlock}>
                      <span className={styles.fieldTitle}>Primary (SEO) keyword</span>
                      <div className={styles.value}>
                        {form.values.primarySEO?.map((tag) => (
                          <span className={styles.pill}>{tag}</span>
                        ))}
                      </div>
                    </div>

                    <div className={styles.fieldBlock}>
                      {(form.values.secondarySEO && form.values.secondarySEO?.length > 0)
                        && (
                          <div className={styles.fieldTitle}>
                            Secondary (SEO) keywords
                          </div>
                        )}
                      <div className={styles.value}>
                        {form.values.secondarySEO?.map((tag) => (
                          <span className={styles.pill}>{tag}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            actionBlock={(activeCard === 1) && (
              <NextButton
                disabled={activeCard !== 1 || (form.values.subject.length < 6 || !form.values.primarySEO?.length)}
                action={() => {
                  dispatch(setActiveCard(2));
                  Mixpanel.track('Content Refresh - Briefing - Enter main subject and keywords');
                }}
              />
            )}
          />
        </Element>
        <Element
          name='step2_card2'
          className={styles.element}
        >
          <Card
            title='Settings'
            subtitle='Set the tone of voice of your article'
            completed={activeCard > 2}
            disabled={activeCard < 2}
            editAction={() => resetToCard(2)}
            content={(
              <>
                {activeCard === 2 && (
                  <>
                    <div className={styles.title}>
                      Which tone of voice do you want to use?
                    </div>
                    <div className={styles.content}>
                      <Field
                        name='toneOfVoice'
                        component={Select}
                        selectLabel='Options'
                        searchable={false}
                        width='310px'
                        options={toneOfVoiceOptions}
                        dropdownPosition='left'
                      />
                    </div>
                  </>
                )}
                {activeCard > 2 && (
                  <div className={styles.completed}>
                    <div className={styles.fieldBlock}>
                      <span className={styles.fieldTitle}>Tone of voice</span>
                      <span className={styles.value}>
                        {form.values.toneOfVoice.charAt(0).toUpperCase() + form.values.toneOfVoice.slice(1)}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
            actionBlock={
              activeCard === 2
              && (
                <>
                  <PreviousButton
                    action={() => {
                      dispatch(setActiveCard(1));
                      form.setFieldValue('toneOfVoice', null);
                    }}
                  />
                  <NextButton
                    disabled={activeCard !== 2 || !form.values.toneOfVoice}
                    action={() => {
                      setLoader(true);
                      dispatch(generateTitles(
                        languageCode,
                        form.values.subject,
                        form.values.secondarySEO,
                        form.values.primarySEO[0],
                        setLoader,
                      ));
                      dispatch(setActiveCard(3));
                      Mixpanel.track('Content Refresh - Briefing - Select tone of voice');
                    }}
                  />
                </>
              )
            }
          />
        </Element>
        <Element
          name='step2_card3'
          className={styles.element}
        >
          <Card
            completed={activeCard > 3}
            disabled={activeCard < 3}
            title={activeCard > 3 ? 'Title' : 'Choose your title'}
            subtitle='Choose one of the generated title’s or write your own.'
            editAction={() => resetToCard(3)}
            isLoading={activeCard === 3 && loader}
            loaderText='We’re generating your title...'
            content={(
              <>
                {(activeCard === 3 && titles.length > 0) && (
                  <Field
                    name='title'
                    component={RadioGroup}
                    label='Select (and edit) a title'
                    suggestions={titles}
                    placeholder='Enter your own title'
                    value='perica'
                    onUpdateValue={(radioIndex, newValue) => {
                      const newTitles = [...titles];
                      newTitles[radioIndex] = newValue;
                      dispatch(setSectionValue('titles', newTitles));
                    }}
                  />
                )}
                {activeCard > 3 && (<div>{form.values.title}</div>)}
              </>
            )}
            actionBlock={
              activeCard === 3
              && (
                <>
                  <PreviousButton
                    action={() => {
                      dispatch(setActiveCard(2));
                      form.setFieldValue('title', null);
                    }}
                  />
                  <NextButton
                    disabled={activeCard !== 3 || !form.values.title}
                    action={() => {
                      dispatch(setActiveCard(4));
                      setLoader(true);
                      Mixpanel.track('Content Refresh - Briefing - Select title');
                    }}
                  />
                </>
              )
            }
          />
        </Element>
        <Element
          name='step2_card4'
          className={styles.element}
        >
          <Card
            disabled={activeCard < 4}
            title='How would you like to generate your blog?'
            content={activeCard === 4 && (
              <div className={styles.content}>
                <Field
                  name='blogGeneration'
                  component={RadioButtonGroup}
                  options={blogGenerationOptions}
                  buttonContent={blogGenerationButtonContent}
                  buttonWidth='329px'
                />
              </div>
            )}
            actionBlock={
              activeCard === 4
              && (
                <PreviousButton
                  action={() => {
                    dispatch(setActiveCard(3));
                  }}
                />
              )
            }
          />
        </Element>
        <Separator margin='20px 0' />
        <StepperFooter
          nextDisabled={
            activeCard < 3
          }
          nextStep={() => {
            if (form.values.blogGeneration === 'advanced') {
              dispatch(showLoader(GENERATE_OUTLINES_LOADER));
              // generate whole article advanced action should go here
              dispatch(setActiveStep(3));
            } else {
              dispatch(setActiveStep(4));
              dispatch(extractWholeArticle(form.values.subject, form.values.primarySEO[0], form.values.secondarySEO, article));
            }
            Mixpanel.track('Content Refresh - Briefing - Blog generation options');
          }}
          previousStep={() => {
            dispatch(showPreviousStepWarning(() => {
              dispatch(setActiveStep(1));
              dispatch(setActiveCard(1));
              dispatch(resetSections('subject', 'primarySEO', 'secondarySEO', 'title', 'blogGeneration'));
              form.setFieldValue('subject', '');
              form.setFieldValue('primarySEO', []);
              form.setFieldValue('secondarySEO', []);
              form.setFieldValue('title', '');
              form.setFieldValue('blogGeneration', 'turbo');
            }));
          }}
          actionName={form.values.blogGeneration === 'turbo' ? 'generate blog' : 'set paragraphs'}
          footerLeftIcon='magicWand'
        />
      </div>
    </Layout>
  );
};

export default SecondStep;
