import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './KeyMessages.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { Button } from '../Button/button';
import { KeyMessagesProps } from './interfaces';

export const KeyMessages: React.FC<KeyMessagesProps> = ({
  title,
  label,
  messages = [],
  openModal,
  isParagraphCard = false,
}) => {
  const [showMessages, setShowMessages] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {isParagraphCard
          ? (
            <button
              type='button'
              className={styles.title}
              onClick={() => setShowMessages(!showMessages)}
            >
              {title}
            </button>
          )
          : <div className={styles.title}>{title}</div>}
      </div>
      <div className={styles.row}>
        <div className={styles.label}>{label}</div>
      </div>
      {(!isParagraphCard || showMessages)
      && (
      <div className={classNames(styles.keyMessages)}>
        {messages.map((sentence) => (
          <div
            className={styles.message}
            key={sentence}
          >
            <span className={styles.bullet}>{renderIcon('check')}</span>
            <span className={styles.transparentInput}>
              {sentence}
            </span>
          </div>
        ))}
      </div>
      )}
      <Button
        action={openModal}
        actionName='edit'
        className={styles.editButton}
      />
    </div>
  );
};

export default KeyMessages;
