import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { animateScroll } from 'react-scroll';
import { get } from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './styles.module.scss';
import ParagraphNew from '../../../core/components/ParagraphNew/ParagraphNew';
import { GeneratePageProps } from './interfaces';
import { RootState } from '../../../../reducers';
import {
  addParagraph, changeParagraphTypes, deleteParagraphs,
  generateParagraphInDepth, generateParagraphs, hideLoader,
  resetSections, setActiveCard,
  setActiveStep,
  setParagraph, setSectionValue, showLoader,
} from '../../../../actions/content-refresh';
import AlternativesModal from '../../../Modals/AlternativesModal';
import EstimationCard from '../../../core/components/EstimationCard/EstimationCard';
import Separator from '../../../core/components/Separator/Separator';
import Layout from '../../ContentCreationNew/Steps/Layout';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import { showPreviousStepWarning } from '../../../../actions/content-creation';
import { closeModal, openModal } from '../../../../actions/modal';
import {
  ALTERNATIVES_MODAL,
  CONFIRM_DELETING_PARAGRAPH_MODAL,
  PARAGRAPHS_ERROR_MODAL,
} from '../../../../constants/modal';
import { AddNewButton } from '../../../core/components/AddNewButton/AddNewButton';
import LoaderAI from '../../../core/components/LoaderAI/LoaderAI';
import {
  GENERATE_FULL_ARTICLE_LOADER, GENERATE_PARAGRAPHS_LOADER,
  PARAGRAPH_IN_DEPTH_LOADER, PARAGRAPH_REGENERATE_LOADER,
} from '../../../../constants/loaders-new';
import ConfirmDeletingParagraph from '../../../Modals/ConfirmDeletingParagraph';
import ParagraphsErrorModal from '../../../Modals/ParagraphsErrorModal';
import Mixpanel from '../../../../util/Mixpanel';
import { ParagraphTag } from '../../../core/components/AddNewButton/interfaces';
import { getHTMLWordCount, revertOutlines } from '../../../../util/helpers';
import Input from '../../../core/components/InputAI/Input';

const GeneratePage: React.FC<GeneratePageProps> = ({ form }) => {
  const {
    paragraphs, loader,
  } = useSelector((state: RootState) => state.contentRefresh);
  const dispatch = useDispatch();

  const [paragraphsHistory, setParagraphsHistory] = useState<any>([]);
  const [displayLoaderText, setDisplayLoaderText] = useState<any>('Generating title and structure...');
  const [highlightedIndex, setHighlightedIndex] = useState<any>(null);
  const [titleEditMode, setTitleEditMode] = useState<any>(false);

  const { errors, touched } = form;

  // Scroll to the top of the page.
  useEffect(() => {
    animateScroll.scrollToTop({
      containerId: 'scrollerContainer',
      duration: 0,
      smooth: false,
    });
  }, []);

  /**
   * Switching loader text every 10 seconds
   */
  useEffect(() => {
    const interval = setInterval(() => {
      switch (displayLoaderText) {
        case 'Generating title and structure...':
          setDisplayLoaderText('Writing introduction...');
          break;
        case 'Writing introduction...':
          setDisplayLoaderText('Writing body content...');
          break;
        case 'Writing body content...':
          setDisplayLoaderText('Writing conclusion...');
          break;
        case 'Writing conclusion...':
          setDisplayLoaderText('Optimizing for SEO...');
          break;
        default:
          clearInterval(interval); // Stop updating after 40 seconds
          break;
      }
    }, 10000); // Update every 10 seconds

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [displayLoaderText]);

  /**
   * Creating a new array to hold the updated paragraph history
   */
  useEffect(() => {
    // Create a new array based on the current paragraphsHistory
    const newParagraphsHistory = [
      ...paragraphsHistory,
    ];

    // Loop through each paragraph in the provided array
    paragraphs?.forEach((paragraph, paragraphIndex) => {
      // On the first occurrence there is no paragraphHistory with the paragraphsIndex specified, so we need to create an empty array
      if (!newParagraphsHistory?.[paragraphIndex]) {
        newParagraphsHistory[paragraphIndex] = [];
      }

      // Check if the current paragraph text is not already in the history and text is not empty (to prevent pushing empty strings)
      if (!newParagraphsHistory[paragraphIndex].includes(paragraph.text) && paragraph.text.length > 0) {
        // Push the paragraph text into the corresponding subarray within newParagraphsHistory
        newParagraphsHistory[paragraphIndex].push(paragraph.text);
      }
    });

    // Update paragraphsHistory with the new modified array
    setParagraphsHistory(newParagraphsHistory);
  }, [paragraphs]);

  // Find the language object in the 'languages' array that matches the language ID from the form values
  // const selectedLanguage: any = useMemo(() => {
  //   const lang = languages.find((language) => language.id === form.values.language) || {};
  //   return lang;
  // }, [form.values.language]);

  // Retrieve language code and Google domain for the selected language
  // const { crowdin_code: languageCode } = selectedLanguage;

  // Extracting paragraph titles for outlines, as they remain undefined when employing the 'Turbo' action
  const paragraphOutlines = paragraphs?.map(paragraph => paragraph.title);
  const originalOutlinesStructure = revertOutlines(paragraphs);

  // outlineContent is each paragraph with corresponding title
  const outlineContent = {};
  if (paragraphOutlines) {
    paragraphOutlines.forEach((paragraphOutline, index) => {
      if (paragraphs && paragraphs[index] && paragraphs[index].text) {
        outlineContent[paragraphOutline] = paragraphs[index].text;
      }
    });
  }

  const handleShowParagraphAlternatives = (index) => {
    dispatch(showLoader(`${GENERATE_PARAGRAPHS_LOADER}_${index}`));
    dispatch(openModal(ALTERNATIVES_MODAL, {
      loadAlternatives: async () => {
        const alternatives = await generateParagraphs(
          'us',
          form.values.subject,
          form.values.primarySEO[0],
          form.values.secondarySEO,
          form.values.title,
          form.values.toneOfVoice,
          originalOutlinesStructure,
          paragraphOutlines[index],
        );

        dispatch(hideLoader());

        return alternatives;
      },
      onSave: (newParagraph) => {
        dispatch(setParagraph(paragraphOutlines[index], newParagraph, index));
        dispatch(closeModal());
      },
      fieldLabel: 'Select (and edit) a paragraph',
      fieldPlaceholder: 'Enter new paragraph',
      modalTitle: `${paragraphOutlines[index]}`,
      alternativesModalLoader: `${GENERATE_PARAGRAPHS_LOADER}_${index}`,
    }));
  };

  // Function used to open a modal dialog for displaying key messages.
  // const handleTopicsModal = (index) => {
  //   console.info(keyMessages);
  //   console.info(keyMessages[index]);
  //   dispatch(
  //     openModal(
  //       KEY_MESSAGES_MODAL,
  //       {
  //         messages: keyMessages[index].map(msg => ({ message: msg, deletable: false, selected: true })),
  //         selectedMessages: keyMessages[index],
  //         title: paragraphOutlines[index],
  //         outlineIndex: index,
  //       },
  //     ),
  //   );
  // };

  // const saveKeyMessagesChanges = (allMessages, index) => {
  //   dispatch(closeModal());

  //   // Create a copy of the 'form.values.keyMessages' array and the 'keyMessages' array.
  //   const selectedMessages = [...keyMessages];

  //   // Update the 'selectedMessages' array at the specified 'index' with the selected messages from 'allMessages'.
  //   // const newKeyMessages = allMessages.filter(msg => msg.selected).map(msg => msg.message);

  //   // selectedMessages[index] = [...newKeyMessages];

  //   // Set key messages value in redux
  //   dispatch(setSectionValue('keyMessages', selectedMessages));

  //   dispatch(regenerateParagraph(
  //     index,
  //     languageCode,
  //     form.values.subject,
  //     form.values.primarySEO[0],
  //     form.values.secondarySEO,
  //     form.values.title,
  //     form.values.toneOfVoice,
  //     paragraphOutlines,
  //     introduction,
  //     paragraphOutlines[index],
  //     outlineContent,
  //   ));
  // };

  /**
   * Handles the UNDO action for the PARAGRAPHS section
   */
  const handleUndoParagraph = useCallback((paragraphIndex) => {
    // Find the current index of the paragraph's text in the history array.
    const currentIndex = paragraphsHistory[paragraphIndex].indexOf(paragraphs[paragraphIndex].text);

    // If the current index is at the beginning or before, exit the function.
    if (currentIndex <= 0) {
      return;
    }

    // Create a copy of the paragraphs array to modify without mutating the original
    const newParagraphs = [...paragraphs];

    // Update the text of the paragraph with the previous version from history.
    newParagraphs[paragraphIndex].text = paragraphsHistory[paragraphIndex][currentIndex - 1];

    // Set paragraphs in redux with previous value of paragraphs
    dispatch(setSectionValue('paragraphs', newParagraphs));
  }, [paragraphsHistory, paragraphs]);

  /**
   * Handles the REDO action for the PARAGRAPHS section
   */
  const handleRedoParagraph = useCallback((paragraphIndex) => {
    // Find the index of the current text in the history array for the given paragraph
    const currentIndex = paragraphsHistory[paragraphIndex].indexOf(paragraphs[paragraphIndex].text);

    // Check if the current text is already the latest in the history
    if (currentIndex >= (paragraphsHistory[paragraphIndex].length - 1)) {
      return; // No need to redo if at the latest version.
    }

    // Create a copy of the paragraphs array to modify
    const newParagraphs = [...paragraphs];

    // Update the text of the paragraph to the next version in history.
    newParagraphs[paragraphIndex].text = paragraphsHistory[paragraphIndex][currentIndex + 1];

    // Set paragraphs in redux with next value of paragraphs
    dispatch(setSectionValue('paragraphs', newParagraphs));
  }, [paragraphsHistory, paragraphs]);

  const getAllowedTags = (index): ParagraphTag[] => {
    const currentParagraphType = paragraphs[index]?.type;
    const nextParagraphType = paragraphs[index + 1]?.type;

    switch (currentParagraphType) {
      case 'h2':
        return ['h2', 'h3'];
      case 'h3':
      case 'h4':
        return nextParagraphType === 'h4' ? ['h3', 'h4'] : ['h2', 'h3', 'h4'];
      default:
        return [];
    }
  };

  const handleDeleteParagraph = useCallback((deleteWhole, clickedIndex: number) => {
    const currentType = paragraphs?.[clickedIndex]?.type;

    const indexesToDelete: number[] = [];
    const indexesToChange: number[] = [];

    // Delete clicked paragraph
    indexesToDelete.push(clickedIndex);

    const startFromIndex = clickedIndex + 1;

    // process subparagraph deletion / modification
    // eslint-disable-next-line no-plusplus
    for (let paragraphIndex = startFromIndex; paragraphIndex < paragraphs.length; paragraphIndex++) {
      const paragraph = paragraphs[paragraphIndex];

      // Stop the loop when the paragraph type matches currentType
      if (paragraph.type && currentType && parseInt(paragraph.type[1], 10) <= parseInt(currentType[1], 10)) {
        break;
      }

      if (deleteWhole) {
        indexesToDelete.push(paragraphIndex);
      } else {
        indexesToChange.push(paragraphIndex);
      }
    }

    // When removing a paragraph, history indexes need to be synced with paragraph indexes
    const newParagraphsHistory = paragraphsHistory.filter((paragraphHistory, index) => !indexesToDelete.includes(index));
    setParagraphsHistory(newParagraphsHistory);

    // Decrease paragraph types (h4 -> h3, h3 -> h2)
    if (indexesToChange.length > 0) {
      dispatch(changeParagraphTypes(indexesToChange));
    }

    // Delete paragraphs
    dispatch(deleteParagraphs(indexesToDelete));
  }, [paragraphs, paragraphsHistory]);

  // OLD WORD COUNT
  // const initialWordCount = 400;
  //
  // const wordCountSum = useMemo(() => paragraphs.reduce((totalWords, { type }) => {
  //   if (type === 'h2') {
  //     return totalWords + 150;
  //   }
  //
  //   if (type === 'h3') {
  //     return totalWords + 100;
  //   }
  //
  //   if (type === 'h4') {
  //     return totalWords + 50;
  //   }
  //
  //   return totalWords;
  // }, initialWordCount), [paragraphs]);

  const allWords = form.values.title.split(' ').length > 0
    && form.values.title.split(' ') + paragraphs.flatMap(paragraph => [paragraph.title, paragraph.text]).join();

  const realWordCount = getHTMLWordCount(allWords);

  const onClickForInput = () => {
    if (form.values.title.length > 5) {
      setTitleEditMode(!titleEditMode);
    }
  };

  return (
    <>
      {/* <KeyMessagesModal saveChanges={saveKeyMessagesChanges} /> */}
      <AlternativesModal />
      <ConfirmDeletingParagraph />
      <ParagraphsErrorModal />
      <Layout columns={2}>
        <div>
          <EstimationCard
            title='Refresh'
            description='Our AI refreshed your blog. In this step you can edit all sections to make it your masterpiece.'
            wordCount={paragraphs?.length > 0 ? (
              <span>
                <span style={{ color: '#ababab' }}>Word count</span>
                {' '}
                <span style={{ color: '#000' }}>{realWordCount}</span>
              </span>
            ) : null}
            infoBox='Tip: use our pre-engineered prompts for optimal results, which are shown as buttons under each of the sections.'
          />
        </div>
        <div>
          {loader === GENERATE_FULL_ARTICLE_LOADER ? (
            <div className={styles.pageLoaderContainer}>
              <LoaderAI
                pageLoader
                pageLoaderMainText={displayLoaderText}
                pageLoaderSecondaryText='The total generation can take up to 2 minutes'
              />
              <div className={styles.linkText}>
                If you don’t like to wait, you can
                {' '}
                <span
                  tabIndex={0}
                  role='button'
                  className={styles.link}
                  onClick={() => window.open('/content-refresh', '_blank')}
                  onKeyDown={() => window.open('/content-refresh', '_blank')}
                >
                  create a new briefing
                </span>
              </div>
            </div>
          ) : (
            <>
              {!titleEditMode && (
                <div
                  tabIndex={0}
                  role='button'
                  className={classNames(styles.mainTitle, styles.titleGenerate)}
                  onClick={() => {
                    if (!titleEditMode) {
                      setTitleEditMode(true);
                    }
                  }}
                  onKeyDown={() => {
                    if (!titleEditMode) {
                      setTitleEditMode(true);
                    }
                  }}
                >
                  {form.values.title}
                </div>
              )}
              {titleEditMode && (
                <OutsideClickHandler onOutsideClick={() => {
                  if (form.values.title.length > 5) {
                    setTitleEditMode(false);
                  }
                }}
                >
                  <Input
                    value={form.values.title}
                    placeholder='Enter your title'
                    type='text'
                    showCheckIcon
                    onClickOnShowIcon={onClickForInput}
                    onChange={e => form.setFieldValue('title', e.target.value)}
                    checkButtonDisabled={form.values.title.length < 6}
                  />
                  {(errors.title || touched.title) && (
                    <div className={styles.error}>{String(errors.title)}</div>
                  )}
                </OutsideClickHandler>
              )}
              {paragraphs?.map((paragraph, index) => (
                <Fragment key={paragraph.title}>
                  <ParagraphNew
                    inputText={(index !== 0 && index !== paragraphs.length - 1) && paragraph.title}
                    editorText={paragraph.text}
                    isLoading={loader === `${PARAGRAPH_IN_DEPTH_LOADER}_${index}` || loader === `${PARAGRAPH_REGENERATE_LOADER}_${index}`}
                    loaderText={loader === `${PARAGRAPH_IN_DEPTH_LOADER}_${index}`
                      ? 'We’re generating an in-depth text...'
                      : 'We’re generating paragraph with new topics...'}
                    onSave={({ title, text }) => dispatch(setParagraph(title, text, index))}
                    onDelete={(deleteWhole) => dispatch(openModal(CONFIRM_DELETING_PARAGRAPH_MODAL, {
                      onConfirmDelete: () => handleDeleteParagraph(deleteWhole, index),
                    }))}
                    caption={index === 0 ? 'Introduction'
                      : (index === paragraphs.length - 1 ? 'Conclusion'
                        : (paragraph.type)?.toUpperCase())}
                    hasSubparagraphs={parseInt(get(paragraphs, [index + 1, 'type', 1]), 10) > parseInt(get(paragraph, ['type', 1]), 10)}
                    showAlternatives={() => handleShowParagraphAlternatives(index)}
                    showTopics
                    // onTopicsClick={() => handleTopicsModal(index)}
                    generateInDepthText={() => dispatch(generateParagraphInDepth(
                      index,
                      'us',
                      form.values.subject,
                      form.values.primarySEO[0],
                      form.values.secondarySEO,
                      form.values.title,
                      paragraphOutlines[index],
                      paragraphs[index].text,
                    ))}
                    handleUndo={
                      (!paragraphsHistory?.[index] || paragraphsHistory[index].indexOf(paragraphs[index].text) <= 0)
                        ? undefined
                        : () => handleUndoParagraph(index)
                    }
                    handleRedo={
                      (!paragraphsHistory?.[index]
                        || paragraphsHistory[index].indexOf(paragraphs[index].text) >= (paragraphsHistory[index].length - 1))
                        ? undefined
                        : () => handleRedoParagraph(index)
                    }
                    highlight={highlightedIndex === index}
                  />
                  {(index !== paragraphs.length - 1) && (
                    <AddNewButton
                      onButtonClick={(tag) => {
                        // When adding more paragraphs, history indexes need to be synced with paragraph indexes
                        const newParagraphsHistory = paragraphsHistory;
                        newParagraphsHistory.splice(index + 1, 0, []);
                        setParagraphsHistory(newParagraphsHistory);

                        // Add paragraph to Redux
                        dispatch(addParagraph(index + 1, tag));

                        // Highlight the new paragraph with yellow color
                        setHighlightedIndex(index + 1);
                        setTimeout(() => setHighlightedIndex(null), 100);
                      }}
                      allowedTags={getAllowedTags(index)}
                    />
                  )}
                </Fragment>
              ))}
              <Separator margin='40px 0' />
              <StepperFooter
                nextStep={() => {
                  if (!paragraphs?.length) {
                    dispatch(openModal(PARAGRAPHS_ERROR_MODAL));
                  } else {
                    dispatch(setActiveStep(5));
                  }
                  Mixpanel.track('Content Creation - Generate - Generate and edit paragraphs');
                }}
                actionName='finish'
                footerLeftIcon='finished'
                previousStep={() => {
                  if (form.values.blogGeneration === 'advanced') {
                    dispatch(showPreviousStepWarning(() => {
                      dispatch(setActiveStep(3));
                      dispatch(setActiveCard(4));
                      dispatch(resetSections('introduction'));
                      dispatch(resetSections('paragraphs'));
                      dispatch(resetSections('conclusion'));
                    }));
                  } else {
                    dispatch(showPreviousStepWarning(() => {
                      dispatch(setActiveStep(2));
                      dispatch(resetSections('introduction'));
                      dispatch(resetSections('paragraphs'));
                      dispatch(resetSections('conclusion'));
                    }));
                  }
                }}
              />
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default GeneratePage;
