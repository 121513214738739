import React, { useEffect, useMemo } from 'react';
import { Field } from 'formik';
import Flag from 'react-world-flags';
import { useDispatch, useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import { useLocation } from 'react-router';
import { useMediaQuery } from '@material-ui/core';
import Card from '../../../core/components/Card/Card';
import styles from './styles.module.scss';
import DataInput from '../../../core/components/DataInput/DataInput';
import Select from '../../../core/components/Select/Select';
import languages from '../../../../assets/json/languages.json';
import { NextButton, PreviousButton } from '../../../core/components/Button/button';
import Input from '../../../core/components/Input';
import {
  extractKeywords,
  extractSubject,
  extractUrl,
  generateTitles,
  resetSections, restorePreviousProgress,
  setActiveCard,
  setActiveStep, setPreviousProgressLoader, setSerpContext, setStore,
} from '../../../../actions/content-refresh';
import Tags from '../../../core/components/Tags/Tags';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import Footer from '../../../Footer';
import { htmlToText } from '../../../../util/helpers';
import Mixpanel from '../../../../util/Mixpanel';
import renderIcon from '../../../../util/renderIcon';
import { openModal } from '../../../../actions/modal';
import { CHARACTERS_WARNING_MODAL, SECTIONS_ERROR_MODAL, SECTIONS_WARNING_MODAL } from '../../../../constants/modal';

function FirstStep({
  form,
  showPreview,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const editorSectionsCount = useMemo(() => {
    const count = (form.values?.input_source?.match(/<h3>\s*(?=\S)(?!<br\s*\/?>)[\s\S]*?<\/h3>/gi) || []).length;
    return count;
  }, [form.values.input_source]);

  const {
    loader,
    preloadedInputSourceContent,
    informationBox,
    subject,
    keywords,
    inputSourceUrl,
    inputSourceType,
    activeCard,
    previousProgressLoader,
  } = useSelector((state) => state.contentRefresh);

  const selectedLanguage = useMemo(() => {
    const lang = languages.find((language) => language.id === form.values.language) || {};
    return lang;
  }, [form.values.language]);

  // Retrieve language code and Google domain for the selected language
  const { country_code: languageCode, google_domain: googleDomain } = selectedLanguage;

  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    // Check if the 'subject' value is truthy
    if (subject) {
      // Set the form field 'subject' to the value of 'subject'
      form.setFieldValue('subject', subject);
    }
  }, [subject]);

  useEffect(() => {
    // Check if the 'keywords' array in the form values is empty or falsy
    if (!form.values.keywords?.length) {
      // Set the form field 'keywords' to a subset of the 'keywords' array, limited to the first 5 elements
      form.setFieldValue('keywords', (keywords || []).slice(0, 5));
    }
  }, [keywords]);

  // Reset the form sections and values based on the given card number
  const resetToCard = cardNumber => {
    dispatch(setActiveCard(cardNumber));

    if (cardNumber <= 3) {
      dispatch(resetSections('keywords'));
      form.setFieldValue('keywords', []);
    }

    if (cardNumber <= 2) {
      dispatch(resetSections('subject'));
      form.setFieldValue('subject', '');
    }

    if (cardNumber <= 1) {
      dispatch(resetSections('preloadedInputSourceContent', 'informationBox', 'inputSourceUrl'));
      form.setFieldValue('input_source', '');
    }
  };

  // Restore previous progress based on the continue_hash from the URL search params
  useEffect(() => {
    // Get the search parameters from the current URL
    const searchParams = new URLSearchParams(location.search);

    // Extract the continue_hash value from the search parameters
    const continueHash = searchParams.get('continue_hash');

    // If continueHash exists and isn't empty
    if (continueHash?.length) {
      // Restore the previous progress using the continueHash
      restorePreviousProgress(continueHash).then(response => {
        // Retrieve the progress data from the backend response
        const progress = response.data.data;

        // If there is either no Redux or Formik values in backend response, throw an error
        if (!progress.store || !progress.values) {
          throw new Error();
        }

        // Restore Redux from backend response
        dispatch(setStore(progress.store));

        // Restore Formik from backend response
        form.setValues(progress.values);
      }).catch(() => {
        // Add some error alert modal that will notify user
        // that the restore process did not complete, because of backend is down
      }).finally(() => {
        dispatch(setPreviousProgressLoader(false));
      });
    }
  }, []);

  // Loader which will show when previous progress from hash is loading
  return previousProgressLoader ? (
    <div className={styles.progressLoader}>
      {renderIcon('contentooLogo')}
    </div>
  ) : (
    <>
      <Element
        name='step1_card1'
        className={styles.element}
      >
        <Card
          title='Language'
          completed={activeCard > 1}
          editAction={() => resetToCard(1)}
          content={activeCard === 1 ? (
            <Field
              name='language'
              label='In what language do you want to generate content?'
              selectLabel='Select'
              component={Select}
              options={languages.map((language) => ({
                value: language.id,
                label: language.name,
                icon: <Flag
                  code={language.country_code}
                  height={12}
                  style={{ borderRadius: '3px' }}
                />,
              }))}
            />
          ) : (
            <>
              <Flag
                code={selectedLanguage?.country_code}
                height={12}
                style={{ borderRadius: '3px' }}
              />
              <span>{selectedLanguage?.name}</span>
            </>
          )}
          actionBlock={activeCard === 1 && (
            <NextButton
              disabled={activeCard !== 1 || !form.values.language}
              action={() => {
                dispatch(setActiveCard(2));
                Mixpanel.track('Content Refresh - Input - Select language');
              }}
            />
          )}
        />
      </Element>
      <Element
        name='step1_card2'
        className={styles.element}
      >
        <Card
          isLoading={activeCard === 2 && loader}
          disabled={activeCard < 2}
          completed={activeCard > 2}
          editAction={() => resetToCard(2)}
          title='Input source'
          content={activeCard === 2 ? (
            <Field
              name='input_source'
              component={DataInput}
              language={languageCode}
            />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {inputSourceType === 'web' && (
                <>
                  {inputSourceUrl && (<span>Web URL</span>)}
                  <span>{inputSourceUrl}</span>
                </>
              )}
              {inputSourceType === 'text' && (
                <span>Custom Text</span>
              )}
            </div>
          )}
          actionBlock={activeCard === 2 && (
            <>
              <PreviousButton
                action={() => {
                  dispatch(setActiveCard(1));
                  dispatch(resetSections('preloadedInputSourceContent', 'informationBox', 'inputSourceUrl'));
                  form.setFieldValue('input_source', '');
                }}
              />
              <NextButton
                disabled={inputSourceType === 'web' ? (
                  (!preloadedInputSourceContent || informationBox))
                  : (form.values.input_source?.length < 12 || !form.values.input_source)}
                action={() => {
                  const continueAction = () => {
                    dispatch(extractSubject(languageCode, form.values.input_source));
                    dispatch(setActiveCard(3));
                  };
                  if (editorSectionsCount < 1) {
                    dispatch(openModal(SECTIONS_ERROR_MODAL));
                  } else if (editorSectionsCount < 4) {
                    dispatch(openModal(SECTIONS_WARNING_MODAL, {
                      continueAction,
                    }));
                  } else if ((htmlToText(form.values.input_source)).length > 10000) {
                    dispatch(openModal(CHARACTERS_WARNING_MODAL, {
                      continueAction,
                    }));
                  } else if (inputSourceType === 'web' && (informationBox || !preloadedInputSourceContent)) {
                    dispatch(extractUrl(inputSourceUrl.trim(), languageCode));
                  } else {
                    continueAction();
                  }
                }}
              />
            </>
          )}
        />
      </Element>
      <Element
        name='step1_card3'
        className={styles.element}
      >
        <Card
          isLoading={activeCard === 3 && loader}
          disabled={activeCard < 3}
          completed={activeCard > 3}
          editAction={() => resetToCard(3)}
          title='Subject'
          content={(activeCard === 3 && form.values.subject !== '' && !loader) ? (
            <Field
              component={Input}
              label='Is this the main subject? If not, please adjust it.'
              placeholder='Enter main subject'
              name='subject'
              onEnter={() => {
                if (form.values.subject && form.values.subject?.length >= 6) {
                  dispatch(extractKeywords(languageCode, form.values.input_source));
                  dispatch(setSerpContext(form.values.subject, googleDomain));
                  dispatch(setActiveCard(4));
                }
              }}
              validate={(value) => {
                if (value?.length < 6 && value?.length) {
                  return 'The subject\'s length must exceed 5 characters.';
                }
              }}
            />
          ) : (
            form.values.subject
          )}
          actionBlock={activeCard === 3 && (
            <>
              <PreviousButton
                action={() => {
                  dispatch(setActiveCard(2));
                  dispatch(resetSections('subject'));
                  form.setFieldValue('subject', '');
                }}
              />
              <NextButton
                disabled={!form.values.subject || !(form.values.subject?.length >= 6)}
                action={() => {
                  dispatch(extractKeywords(languageCode, form.values.input_source));
                  dispatch(setSerpContext(form.values.subject, googleDomain));
                  dispatch(setActiveCard(4));
                  Mixpanel.track('Content Refresh - Input - Enter main subject');
                }}
              />
            </>
          )}
        />
      </Element>
      <Element
        name='step1_card4'
        className={styles.element}
      >
        <Card
          isLoading={activeCard === 4 && loader}
          disabled={activeCard < 4}
          title='Keywords'
          content={(activeCard === 4 && form.values.keywords?.length) && (
            <Field
              component={Tags}
              label='Enter the most relevant keywords (max. 5)'
              description='We already suggested some keywords.
              To add keywords, you can separate them either by using a comma
              or by pressing enter.'
              placeholder='Add more'
              name='keywords'
              limit={5}
            />
          )}
          actionBlock={activeCard === 4 && (
            <PreviousButton
              action={() => {
                dispatch(setActiveCard(3));
                dispatch(resetSections('keywords'));
                form.setFieldValue('keywords', []);
              }}
            />
          )}
        />
      </Element>
      <Footer>
        <StepperFooter
          nextDisabled={!(activeCard === 4 && form.values.keywords?.length)}
          nextStep={() => {
            if (!isMobile) {
              showPreview();
            }
            dispatch(generateTitles(languageCode, form.values.subject, form.values.keywords));
            dispatch(setActiveStep(2));
            dispatch(setActiveCard(1));
            Mixpanel.track('Content Refresh - Input - Add keywords');
          }}
          previousDisabled
          previousStep={null}
          nextStepNumber={2}
          actionName='briefing'
        />
      </Footer>
    </>
  );
}

export default FirstStep;
