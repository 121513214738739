import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import { htmlToSections } from '../../../../util/helpers';
import styles from './styles.module.scss';

function ContentPreview({ contentValues = {}, previousValue = false }) {
  const [newText, setNewText] = useState(true);
  const [previewContent, setPreviewContent] = useState('');

  const generateContent = (obj) => {
    const returnObj = [];

    // Generate the title element based on the presence of 'obj.title'
    if (obj.title) returnObj.push(<h1 key='title'>{obj.title}</h1>);
    else returnObj.push(<h1 key='title'>Title</h1>);
    // Generate the introduction paragraph element based on the presence of 'obj.introduction'.
    if (obj.introduction) returnObj.push(<p key='intro'>{obj.introduction}</p>);
    else {
      returnObj.push(
        <p
          key='intro'
          className={styles.placeholder}
        >
          Introduction text
        </p>,
      );
    }

    if (obj.outlines?.length) {
      // Generate the outline elements and associated paragraphs.
      obj.outlines.filter((outline) => outline.checked).forEach((outline, index) => {
        returnObj.push(
          <Element name={`preview_paragraph.${index}`}>
            <h2>{outline.sentence}</h2>
          </Element>,
        );
        if (obj.paragraphs && obj.paragraphs[index]?.body_text) {
          // Generate the paragraph element with the body text from 'obj.paragraphs' if available.
          returnObj.push(<p>{obj.paragraphs[index].body_text}</p>);
        } else if (outline.checked) {
          // Generate placeholder paragraph for unchecked outline without corresponding entry in 'obj.paragraphs'.
          returnObj.push(<p className={styles.placeholder}>Here comes the generated body text.</p>);
        }
      });
    } else {
      // Generate default paragraphs when there are no outlines.
      for (let index = 1; index <= 3; index += 1) {
        returnObj.push(
          <h2 key={`${index}paragraphTitle`}>
            Paragraph
            {' '}
            {index}
          </h2>,
        );
        returnObj.push(
          <p
            key={`${index}paragraphBody`}
            className={styles.placeholder}
          >
            Here comes the generated body text.
          </p>,
        );
      }
    }

    returnObj.push(<h2>Conclusion</h2>);

    // Generate the conclusion paragraph element based on the presence of 'obj.conclusion'.
    if (obj.conclusion) {
      returnObj.push(<p>{obj.conclusion}</p>);
    } else returnObj.push(<p className={styles.placeholder}>Conclusion text</p>);

    // Set the preview content state with the generated content object.
    setPreviewContent(returnObj.map(el => el));
  };

  // Trigger the 'generateContent' function whenever 'contentValues' changes.
  useEffect(() => {
    generateContent(contentValues);
  }, [contentValues]);

  return (
    <div
      className={styles.wrapper}
      id='previewScroller'
    >
      {previousValue && contentValues.input_source
        ? (
          <>
            <div className={styles.textPicker}>
              <div
                className={classNames({ [styles.isActive]: newText })}
                onClick={() => setNewText(true)}
                onKeyDown={() => setNewText(true)}
                tabIndex='0'
                role='button'
              >
                new text
              </div>
              <div
                className={classNames({ [styles.isActive]: !newText })}
                onClick={() => setNewText(false)}
                onKeyDown={() => setNewText(false)}
                tabIndex='0'
                role='button'
              >
                source text
              </div>
            </div>
            <div className={styles.content}>
              {newText ? previewContent : htmlToSections(contentValues.input_source)}
            </div>
          </>
        )
        : (
          <div className={styles.content}>
            {previewContent}
          </div>
        )}
    </div>
  );
}

export default ContentPreview;
