import * as types from '../constants/modal';

export interface ModalState {
  name: string;
  data: Record<string, any>;
}

const initialState = {
  name: '',
  data: {},
};

export default function modal(state: ModalState = initialState, action) {
  switch (action.type) {
    case types.OPEN_MODAL:
      return {
        ...state,
        name: action.payload.name,
        data: action.payload.data || {},
      };
    case types.CLOSE_MODAL:
      return {
        ...state,
        name: null,
        data: {},
      };
    default:
      return state;
  }
}
