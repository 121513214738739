import FileDownload from 'js-file-download';
import { filter, uniq } from 'lodash';
import * as types from '../constants/content-creation';
import { openModal } from './modal';
import {
  AI_ERROR_MODAL,
  INTERNET_CONNECTION_ERROR_MODAL,
  NOT_FOUND_ERROR_MODAL,
  PREVIOUS_STEP_WARNING_MODAL,
} from '../constants/modal';
import { client } from '../util/api';
import { checkUserAuthenticated } from './auth';

const url = process.env.REACT_APP_API_URL;

export const toggleLoader = (toggleValue) => ({
  type: types.TOGGLE_LOADER,
  payload: toggleValue,
});

export const setDownloadRequested = () => ({
  type: types.SET_DOWNLOAD_REQUESTED,
});

export const resetReducer = () => ({
  type: types.RESET_REDUCER,
});

export const showError = (name = AI_ERROR_MODAL) => (dispatch) => {
  dispatch(openModal(name));
  dispatch(toggleLoader(false));
};

export const showPreviousStepWarning = (onConfirm) => (dispatch) => {
  dispatch(openModal(PREVIOUS_STEP_WARNING_MODAL, {
    onConfirm,
  }));
};

export function setPreviousProgressLoader(loaderActive) {
  return {
    type: types.SET_PREVIOUS_PROGRESS_LOADER,
    payload: loaderActive,
  };
}

export function setStore(store) {
  return {
    type: types.SET_STORE,
    payload: store,
  };
}

export function resetSections(...sections) {
  return {
    type: types.RESET_SECTIONS,
    payload: sections,
  };
}

export function setActiveStep(step) {
  return {
    type: types.SET_ACTIVE_STEP,
    payload: step,
  };
}

export function setActiveCard(card) {
  return {
    type: types.SET_ACTIVE_CARD,
    payload: card,
  };
}

const handleRequestFailures = (dispatch, error) => {
  if (error.response) {
    if (error.response.status === 404) {
      // show 404 error
      dispatch(showError(NOT_FOUND_ERROR_MODAL));
    } else {
      // show AI error
      dispatch(showError(AI_ERROR_MODAL));
    }
  } else {
    // show internet connection error
    dispatch(showError(INTERNET_CONNECTION_ERROR_MODAL));
  }
};

export function setSerpContext(subject, countryCode) {
  return (dispatch) => {
    const myUrl = `${url}/serp/context/`;

    dispatch(resetSections('serpContext'));

    // const location = `google.${countryCode.toLowerCase()}`;
    const location = countryCode;

    client.post(myUrl, {
      subject, location,
    })
      .then(data => {
        dispatch({
          type: types.SET_SERP_CONTEXT,
          payload: data.data.data.output || '',
        });
      }).catch(() => {
        dispatch({
          type: types.SET_SERP_CONTEXT,
          payload: '',
        });
      });
  };
}

export function generateTitles(languageCode, subject, keywords) {
  return (dispatch) => {
    const myUrl = `${url}/content-creation/generate-titles`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('titles'));

    client.post(myUrl, {
      subject, keywords, target_language: languageCode,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(3));
          dispatch(resetSections('titles'));
          return;
        }

        dispatch({
          type: types.GENERATE_TITLES,
          payload: data.data.data.output,
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);

        dispatch(setActiveCard(3));
        dispatch(resetSections('titles'));
      });
  };
}

export function generateSummaries(languageCode, subject, keywords, title, serpContext = false) {
  return (dispatch) => {
    const myUrl = `${url}/content-creation/generate-summary-ideas`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('summaries'));

    const postData = {
      subject, keywords, title, target_language: languageCode,
    };

    if (serpContext) {
      postData.get_serp_data = true;
      postData.serp_context = serpContext;
    }

    client.post(myUrl, postData)
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(4));
          dispatch(resetSections('summaries'));
          return;
        }

        dispatch({
          type: types.GENERATE_SUMMARIES,
          payload: uniq(filter([
            ...data.data.data.output,
            data.data.data?.serpOutput || null,
          ])),
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveCard(4));
        dispatch(resetSections('summaries'));
      });
  };
}

export function generateOutlines(languageCode, subject, keywords, title, summary, serpContext = false) {
  return (dispatch) => {
    const myUrl = `${url}/content-creation/generate-outline`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('outlines'));

    const postData = {
      subject, keywords, title, summary, target_language: languageCode,
    };

    if (serpContext) {
      postData.get_serp_data = true;
      postData.serp_context = serpContext;
    }

    client.post(myUrl, postData)
      .then(data => {
        dispatch(toggleLoader(false));

        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(5));
          dispatch(resetSections('outlines'));
          return;
        }

        const filteredOutlines = data.data.data.output.filter((outline) => outline.trim() !== '');

        dispatch({
          type: types.GENERATE_OUTLINES,
          payload: {
            outlines: filteredOutlines,
            outlinesSerp: data.data.data.serpOutput || [],
          },
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveStep(1));
        dispatch(setActiveCard(5));
        dispatch(resetSections('outlines'));
      });
  };
}

export function generateKeyMessages(languageCode, title, summary, outlines, serpContext = false) {
  return (dispatch) => {
    const myUrl = `${url}/content-creation/generate-key-messages`;

    dispatch(toggleLoader(true));

    const postData = {
      target_language: languageCode, title, summary, generated_outline: outlines,
    };

    if (serpContext) {
      postData.get_serp_data = true;
      postData.serp_context = serpContext;
    }

    client.post(myUrl, postData)
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(5));
          dispatch(resetSections('outlines'));
          return;
        }

        const keyMessages = data.data.data.output;
        const serpMessages = data.data.data.serpOutput;

        let myArr = [];

        if (serpMessages) {
          myArr = keyMessages.map((x, index) => [...x, ...serpMessages[index]]);
        } else {
          myArr = keyMessages;
        }

        dispatch({
          type: types.GENERATE_KEY_MESSAGES,
          payload: myArr.map(ar => ar.map((x, index) => ({ message: x, deletable: false, selected: index < 5 }))),
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveCard(5));
        dispatch(resetSections('outlines'));
      });
  };
}

export function generateIntroduction(languageCode, subject, keywords, title, summary, outlines) {
  return (dispatch) => {
    const myUrl = `${url}/content-creation/generate-introduction`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('introductions'));

    client.post(myUrl, {
      subject, keywords, title, summary, outlines, target_language: languageCode,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveStep(2));
          dispatch(setActiveCard(2));
          dispatch(resetSections('introductions'));
          return;
        }

        dispatch({
          type: types.GENERATE_INTRODUCTIONS,
          payload: data.data.data.output,
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveStep(2));
        dispatch(setActiveCard(2));
        dispatch(resetSections('introductions'));
      });
  };
}

export function generateParagraph(
  languageCode,
  subject,
  keywords,
  title,
  summary,
  outlines,
  introduction,
  paragraphToWrite,
  outlineContent,
  keyMessages,
) {
  return (dispatch) => {
    const myUrl = `${url}/content-creation/generate-paragraph`;

    dispatch(toggleLoader(true));

    client.post(
      myUrl,
      {
        target_language: languageCode,
        subject,
        keywords,
        title,
        summary,
        outlines,
        introduction,
        paragraph_to_write: paragraphToWrite,
        outline_content: outlineContent,
        key_messages: keyMessages,
      },
    )
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(1));
          dispatch(resetSections('paragraphs'));
          return;
        }

        const filteredParagraphs = data.data.data.output.filter((paragraph) => paragraph.trim() !== '');

        dispatch({
          type: types.GENERATE_PARAGRAPH,
          payload: filteredParagraphs,
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveCard(1));
        dispatch(resetSections('paragraphs'));
      });
  };
}

export function generateAndReplaceParagraph(
  paragraphIndex,
  languageCode,
  subject,
  keywords,
  title,
  summary,
  outlines,
  introduction,
  paragraphToWrite,
  outlineContent,
  keyMessages,
) {
  return (dispatch) => {
    const myUrl = `${url}/content-creation/generate-paragraph`;

    dispatch(toggleLoader(true));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      keywords,
      title,
      summary,
      outlines,
      introduction,
      paragraph_to_write: paragraphToWrite,
      outline_content:
      outlineContent,
      key_messages: keyMessages,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(1));
          dispatch(resetSections('paragraphs'));
          return;
        }

        const filteredParagraphs = data.data.data.output.filter((paragraph) => paragraph.trim() !== '');

        dispatch({
          type: types.GENERATE_AND_REPLACE_PARAGRAPH,
          payload: {
            output: filteredParagraphs,
            paragraphIndex,
          },
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveCard(1));
        dispatch(resetSections('paragraphs'));
      });
  };
}

export function generateConclusion(
  languageCode,
  subject,
  keywords,
  title,
  summary,
  outlines,
  introduction,
  outlineContent,
  keyMessages,
) {
  return (dispatch) => {
    const myUrl = `${url}/content-creation/generate-conclusion`;

    dispatch(toggleLoader(true));

    dispatch(resetSections('conclusions'));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      keywords,
      title,
      summary,
      outlines,
      introduction,
      outline_content: outlineContent,
      key_messages: keyMessages,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(1));
          dispatch(resetSections('conclusions'));
          return;
        }

        dispatch({
          type: types.GENERATE_CONCLUSION,
          payload: data.data.data.output,
        });
        dispatch(toggleLoader(false));
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveCard(1 + outlines.length));
        dispatch(resetSections('conclusions'));
      });
  };
}

export function downloadContentCreationFile(title, introduction, outlines, paragraphs, conclusion, email, continueHash = null) {
  return (dispatch) => {
    const myUrl = `${url}/download-file`;

    const paragraphHeadings = outlines;

    const paragraphsText = paragraphs.map(paragraph => paragraph.body_text);

    dispatch(toggleLoader(true));

    const requestData = {
      title,
      introduction,
      paragraph_headings: paragraphHeadings,
      paragraphs: paragraphsText,
      conclusion,
      email,
      document_type: 'creation',
    };

    if (continueHash) {
      requestData.hash = continueHash;
    }

    client.post(myUrl, requestData, {
      responseType: 'blob',
    })
      .then(data => {
        dispatch(toggleLoader(false));
        FileDownload(data.data, `${title}.docx`);
      });
  };
}

export function saveContentCreationFile(text, continueHash = null) {
  return () => {
    const myUrl = `${url}/finished_documents/`;

    const requestData = {
      text,
      document_type: 'creation',
    };

    if (continueHash) {
      requestData.hash = continueHash;
    }

    return client.post(myUrl, requestData);
  };
}

export function setSectionValue(section, value) {
  return {
    type: types.SET_SECTION_VALUE,
    payload: {
      section,
      value,
    },
  };
}

export const storeProgressAndSendBackendErrorEmail = async (email, store, values, continueHash = '') => {
  const storeProgressUrl = `${url}/continue-flow/store-current-flow`;
  const sendEmailUrl = `${url}/mail/open-ai-error`;

  const data = {
    data: JSON.stringify({
      store,
      values,
    }),
    document_type: 'creation',
  };

  if (continueHash !== '') {
    data.hash = continueHash;
  }

  await client.post(storeProgressUrl, data);

  return client.post(sendEmailUrl, {
    document_type: 'creation',
    email,
  });
};

export const storeProgressAndSendExitEmail = async (email = null, store, values, continueHash = '') => {
  const storeProgressUrl = `${url}/continue-flow/store-current-flow`;
  const sendEmailUrl = `${url}/mail/left-flow`;

  const data = {
    data: JSON.stringify({
      store,
      values,
    }),
    document_type: 'creation',
  };

  if (continueHash !== '') {
    data.hash = continueHash;
  }

  await client.post(storeProgressUrl, data);

  if (email) {
    await client.post(sendEmailUrl, {
      document_type: 'creation',
      email,
    });
  }
};

export const restorePreviousProgress = (continueHash) => {
  const myUrl = `${url}/continue-flow/continue/${continueHash}`;

  return client.get(myUrl);
};

export const deleteAdditionalTopic = (topicIndex) => ({
  type: types.DELETE_ADDITIONAL_TOPIC,
  payload: topicIndex,
});

export const listAdditionalTopics = () => (dispatch) => {
  // const myUrl = `${url}/CHANGE_ME`;
  //
  // client.get(myUrl)
  //   .then(data => {
  //     dispatch({
  //       type: types.LIST_ADDITIONAL_TOPICS,
  //       payload: data.data.topics || [], // TODO: Change this
  //     });
  //   });

  const topics = [
    'Director James Camoron explains the succes of the movies',
    'Exploring the Galaxy with Kate Winslet in Avatar',
    'The total box office revenue worldwide',
    'Why is the movie such a succes?',
    'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
  ];

  dispatch({
    type: types.LIST_ADDITIONAL_TOPICS,
    payload: topics,
  });
};
