import React, { Suspense } from 'react';
import RoutesWrapper from './routesWrapper';
import Loader from '../core/components/Loader/loader';

function MainRoutes() {
  return (
    <Suspense fallback={<Loader isVisible />}>
      <RoutesWrapper />
    </Suspense>
  );
}

export default MainRoutes;
