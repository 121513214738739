import React from 'react';
import { Field, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import KeyMessagesModal from '../../../core/components/KeyMessages/KeyMessagesModal';
import styles from '../../ContentCreation/Steps/styles.module.scss';
import Paragraph from '../../../core/components/Paragraph/Paragraph';
import renderIcon from '../../../../util/renderIcon';
import { GeneratePageProps } from './interfaces';
import EstimationCard from '../../../core/components/EstimationCard/EstimationCard';
import AlternativesModal from '../../../Modals/AlternativesModal';
import FormikInput from '../../../core/components/FormikInput/FormikInput';
import { ALTERNATIVES_MODAL } from '../../../../constants/modal';
import { openModal } from '../../../../actions/modal';

const GeneratePage: React.FC<GeneratePageProps> = ({
  form,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.wrapper}>
      <EstimationCard
        title='Generate'
        description='Our AI generated your blog. In this step you can edit all sections to make it your masterpiece.'
        wordCount={form.values.paragraphs.length * 150 + 400}
        infoBox='Tip: use our pre-engineered prompts for optimal results, which are shown as buttons under each of the sections.'
      />
      <KeyMessagesModal saveChanges={() => console.log('save')} />
      <AlternativesModal />
      <Field
        component={FormikInput}
        name='title'
        label='Title'
        type='text'
        additionalStyle={{
          fontSize: '22px',
          fontFamily: '"GTWalsheimMedium", sans-serif',
        }}
        placeholder='Write your title here'
      />
      <Field
        component={Paragraph}
        name='introduction'
        label='Introduction'
        hasText
        showAlternatives={() => dispatch(openModal(ALTERNATIVES_MODAL, {
          suggestions: ['blabla1', 'blabla2', 'blabla3'],
          fieldName: 'introductionAlternatives',
          fieldLabel: 'Select (and edit) an introduction',
          fieldPlaceholder: 'Type your introduction here',
          modalTitle: 'Introduction',
          modalSubtitle: 'The introduction convinces your audience to keep reading.',
        }))}
      />
      <FieldArray
        name='paragraphs'
        render={arrayHelpers => (
          <div className={styles.paragraphs}>
            {form?.values?.paragraphs.map((paragraph, index) => (
              <Field
                component={Paragraph}
                key={index}
                name={`paragraphs.${index}`}
                label={`Paragraph ${index + 1}`}
                placeholder='Enter your paragraph title here'
                hasTitle
                hasText
                showAlternatives={() => dispatch(openModal(ALTERNATIVES_MODAL, {
                  suggestions: ['blabla1', 'blabla2', 'blabla3'],
                  fieldName: 'paragraphsAlternatives',
                  fieldLabel: `Select (and edit) paragraph`,
                  fieldPlaceholder: 'Type your paragraph here',
                  modalTitle: `Paragraph ${index + 1}`,
                  modalSubtitle: `${form.values.paragraphs[index].title}`,
                }))}
                actions={[
                  {
                    icon: renderIcon('arrowDown'),
                    disabled: index === form.values.paragraphs.length - 1,
                    tooltip: 'Move down',
                    onClick: () => index < form.values.paragraphs.length - 1 && arrayHelpers.swap(index, index + 1),
                  },
                  {
                    icon: renderIcon('arrowUp'),
                    disabled: index === 0,
                    tooltip: 'Move up',
                    onClick: () => index > 0 && arrayHelpers.swap(index, index - 1),
                  },
                  {
                    icon: renderIcon('delete'),
                    disabled: form.values.paragraphs.length === 1,
                    tooltip: 'Remove',
                    onClick: () => {
                      if (form.values.paragraphs.length > 1) {
                        arrayHelpers.remove(index);
                      }
                    },
                  },
                ]}
              />
            ))}
          </div>
        )}
      />
      <Field
        component={Paragraph}
        name='conclusion'
        label='Conclusion'
        hasText
        showAlternatives={() => dispatch(openModal(ALTERNATIVES_MODAL, {
          suggestions: ['blabla1', 'blabla2', 'blabla3'],
          fieldName: 'conclusionAlternatives',
          fieldLabel: 'Select (and edit) a conclusion',
          fieldPlaceholder: 'Type your conclusion here',
          modalTitle: 'Conclusion',
          modalSubtitle: 'The conclusion summarizes the key take-away’s of your article',
        }))}
      />
    </div>
  );
};

export default GeneratePage;
