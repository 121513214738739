const data = [
  {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  }, {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  }, {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  }, {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  }, {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  }, {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  }, {
    id: 1,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'in-progress',
    page: 2,
  },
  {
    id: 2,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'in-progress',
    page: 3,
  },
  {
    id: 3,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'creation',
    type: 'product-item',
    status: 'finished',
  },
  {
    id: 4,
    name: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
    tool: 'creation',
    type: 'blog',
    status: 'finished',
  },
  {
    id: 5,
    name: 'Exploring the Galaxy with Kate Winslet in Avatar',
    tool: 'refresh',
    type: 'landing-page',
    status: 'finished',
  },
  {
    id: 6,
    name: 'A First Look: Initial Reactions to the Avatar 2 Sequel',
    tool: 'refresh',
    type: 'product-item',
    status: 'finished',
  },
];

const tools = [
  {
    label: 'Refresh',
    value: 'refresh',
  },
  {
    label: 'Creation',
    value: 'creation',
  },
];
export default { data, tools };
