import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import styles from './styles.module.scss';
import renderIcon from '../../../../util/renderIcon';
import Input from '../../../core/components/InputAI/Input';
import { Button, SecondaryButton } from '../../../core/components/Button/button';
import AuthFooter from '../../../Footer/AuthFooter/AuthFooter';
import AuthPage from '../index';
import Separator from '../../../core/components/Separator/Separator';
import { forgotPassword } from '../../../../actions/auth';

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const { validationErrors, errorMessage } = useSelector(state => state.auth);

  return (
    <AuthPage>
      <div className={styles.smallLogo}>
        {renderIcon('logo')}
      </div>
      <div className={styles.largeLogo}>
        {renderIcon('contentooLogo')}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Forgot your password?
        </div>
        <div className={styles.subtitle}>
          No problem! Just enter your email and we will send you a link to reset your password.
        </div>
        <div className={styles.form}>
          <Input
            label='Email'
            placeholder='Enter your email'
            type='email'
            onChange={e => setEmail(e.target.value)}
            error={validationErrors?.email}
          />
          {(errorMessage && isEmpty(validationErrors)) && <span className={styles.error}>{errorMessage}</span>}
          <Separator margin='20px 0' />
          <Button
            actionName='Send'
            className={styles.sendButton}
            action={() => dispatch(forgotPassword(email, navigate))}
          />
          <div className={styles.loginWrapper}>
            <span>Found your password?</span>
            <SecondaryButton
              name='Login'
              className={styles.loginButton}
              onClick={() => navigate('/login')}
            />
          </div>
        </div>
      </div>
      <AuthFooter />
    </AuthPage>
  );
}

export default ForgotPassword;
