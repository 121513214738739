import React from 'react';
import classNames from 'classnames';
import nl2br from 'react-newline-to-break';
import styles from './Input.module.scss';
import { InputProps } from './interfaces';
import renderIcon from '../../../../util/renderIcon';

const Input: React.FC<InputProps> = ({
  label,
  value,
  placeholder,
  type,
  focus,
  onChange,
  message,
  error,
  showCheckIcon,
  onClickOnShowIcon,
  checkButtonDisabled,
}) => (
  <div className={styles.wrapper}>
    {label && <div className={styles.label}>{label}</div>}
    <input
      type={type}
      placeholder={placeholder}
      className={classNames(styles.inputContainer, { [styles.focus]: focus }, { [styles.errorBorder]: error })}
      onChange={onChange}
      value={value}
    />
    {showCheckIcon && onClickOnShowIcon && (
      <button
        type='button'
        onClick={() => { onClickOnShowIcon(); }}
        className={classNames(styles.checkButton, { [styles.disabled]: checkButtonDisabled })}
      >
        {renderIcon('check')}
      </button>
    )}
    {message && <div className={styles.message}>{message}</div>}
    {error && <div className={styles.error}>{Array.isArray(error) ? nl2br(error.join('\n')) : error}</div>}
  </div>
);

export default Input;
