import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import renderIcon from '../../../../util/renderIcon';
import styles from './DataInput.module.scss';
import TextEditor from '../TextEditorAI/TextEditor';
import {
  extractUrl, resetSections, setInputSourceType, setInputSourceUrl,
} from '../../../../actions/content-refresh';
import { validateUrl } from '../../../../util/helpers';
import Mixpanel from '../../../../util/Mixpanel';

function DataInput({
  form, field,
}) {
  const dispatch = useDispatch();
  const [placeholder, setPlaceholder] = useState('Enter URL');
  const [label, setLabel] = useState('Enter the URL of the input article');

  const informationBox = useSelector(state => state.contentRefresh.informationBox);
  const preloadedInputSourceContent = useSelector(state => state.contentRefresh.preloadedInputSourceContent);
  const inputSourceUrl = useSelector(state => state.contentRefresh.inputSourceUrl);
  const inputSourceType = useSelector(state => state.contentRefresh.inputSourceType);
  // const uploadedFile = useSelector(state => state.contentRefresh.uploadedFile);

  const setInputData = (activeType) => {
    dispatch(setInputSourceType(activeType));
    switch (activeType) {
      case 'web':
        setPlaceholder('Enter URL');
        setLabel('Enter the URL of the input article');
        Mixpanel.track('Content Refresh - Input - Selected URL input');
        break;
      case 'doc':
        setPlaceholder('Add file');
        setLabel('Upload the document you like to use as input');
        Mixpanel.track('Content Refresh - Input - Selected file input');
        break;
      case 'text':
        setLabel('Paste your own text');
        Mixpanel.track('Content Refresh - Input - Selected copy/paste');
        break;
      default:
        break;
    }
  };

  const handleOnBlur = (isText) => {
    if (!informationBox && validateUrl(inputSourceUrl) && !isText && inputSourceUrl.length > 6) {
      dispatch(extractUrl({ url: inputSourceUrl.trim() }));
    }

    isText && field.value.length > 20 && dispatch(extractUrl({ text: field.value }));
  };

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>What source of text would you like to use?</span>
      <div className={styles.inputSelector}>
        <div
          tabIndex='0'
          role='button'
          className={classNames(
            styles.button,
            { [styles.isActive]: inputSourceType === 'web' },
          )}
          onKeyDown={() => setInputData('web')}
          onClick={() => {
            setInputData('web');
            form.setFieldValue('input_source', null);
            dispatch(resetSections('preloadedInputSourceContent', 'informationBox', 'inputSourceUrl', 'uploadedFile'));
          }}
        >
          {renderIcon('url')}
          Web URL
        </div>
        {/* <div */}
        {/*  tabIndex='0' */}
        {/*  role='button' */}
        {/*  className={classNames( */}
        {/*    styles.button, */}
        {/*    { [styles.isActive]: inputSourceType === 'doc' }, */}
        {/*    styles.disabled, */}
        {/*  )} */}
        {/*  onKeyDown={() => setInputData('doc')} */}
        {/*  onClick={() => { */}
        {/*    setInputData('doc'); */}
        {/*    form.setFieldValue('input_source', null); */}
        {/*    dispatch(resetSection('preloadedInputSourceContent')); */}
        {/*    dispatch(resetSection('informationBox')); */}
        {/*    dispatch(resetSection('inputSourceUrl')); */}
        {/*    dispatch(resetSection('uploadedFile')); */}
        {/*  }} */}
        {/* > */}
        {/*  {renderIcon('brochure')} */}
        {/*  Upload document */}
        {/* </div> */}
        <div
          tabIndex='0'
          role='button'
          className={classNames(
            styles.button,
            { [styles.isActive]: inputSourceType === 'text' },
          )}
          onKeyDown={() => setInputData('text')}
          onClick={() => {
            setInputData('text');
            form.setFieldValue('input_source', null);
            dispatch(resetSections('preloadedInputSourceContent', 'informationBox', 'inputSourceUrl', 'uploadedFile'));
          }}
        >
          {renderIcon('pen')}
          Copy/Paste text
        </div>
      </div>
      <span className={styles.label}>{label}</span>
      <div className={styles.input}>
        {inputSourceType === 'web'
          && (
          <>
            <input
              value={inputSourceUrl}
              onChange={event => {
                dispatch(setInputSourceUrl(event.target.value));
                form.setFieldValue('input_source', '');
              }}
              type='text'
              placeholder={placeholder}
              onBlur={() => { handleOnBlur(false); }}
              onKeyUp={e => e.key === 'Enter' && handleOnBlur(false)}
            />
            {/* {informationBox && (
              <div className={styles.box}>
                <div className={styles.infoIcon}>
                  i
                </div>
                <p>
                  Make sure the input content looks good and has no typing errors.
                  You can always use the copy/paste functionality to manually enter the input text.
                </p>
                <Button
                  actionName='show content'
                  className={styles.darkButton}
                  action={handleShowEditor}
                />
              </div>
            )} */}
            {(preloadedInputSourceContent && !informationBox) && (
            <TextEditor
              title='Input component'
              initialText={form.values.input_source || preloadedInputSourceContent.section.map(section => {
                if (!Array.isArray(section.txt)) {
                  return '<p>&zwnj;</p>';
                }

                let html = `<h3>${section.txt[0]}</h3>`;

                if (section.txt.length > 1) {
                  section.txt.slice(1).forEach(paragraph => {
                    html += `<p>${paragraph}</p>`;
                  });
                }

                return html;
              }).join('<p>&zwnj;</p>')}
              onChange={(html) => form.setFieldValue(field.name, html)}
              noWrapper={false}
              blockType
            />
            )}
            {/* {inputSourceUrl.length > 0 && !validateUrl(inputSourceUrl) && <div className={styles.invalidURL}>URL is not valid</div>} */}
          </>
          )}
        {/* {inputSourceType === 'doc' */}
        {/*  && ( */}
        {/*    <> */}
        {/*      <div className={styles.upload}> */}
        {/*        <label */}
        {/*          htmlFor='add-file' */}
        {/*          className={styles.addFile} */}
        {/*        > */}
        {/*          { uploadedFile ? renderIcon('documentRounded') : renderIcon('add') } */}
        {/*          {' '} */}
        {/*          {uploadedFile ? uploadedFile.name : placeholder} */}
        {/*          <input */}
        {/*            id='add-file' */}
        {/*            type='file' */}
        {/*            key={uploadedFile?.name} */}
        {/*            onChangeCapture={(e) => setTimeout(() => dispatch(setUploadedFile(e.target.files[0])), 500)} */}
        {/*            hidden */}
        {/*          /> */}
        {/*        </label> */}
        {/*        {uploadedFile && <span className={styles.crossIcon}
                      onClick={() => dispatch(setUploadedFile(null))}>{renderIcon('cross')}</span>} */}
        {/*      </div> */}
        {/*      {uploadedFile && ( */}
        {/*        <TextEditor */}
        {/*          title='Input component' */}
        {/*          initialText={form.values.input_source || '<p>&zwnj;</p>'} */}
        {/*          onChange={(html) => form.setFieldValue(field.name, html)} */}
        {/*        /> */}
        {/*      )} */}
        {/*    </> */}
        {/*  )} */}
        {inputSourceType === 'text'
          && (
          <TextEditor
            title='Input component'
            initialText={form.values.input_source || '<p>&zwnj;</p>'}
            noWrapper
            onChange={(html) => form.setFieldValue(field.name, html)}
            blockType
            onBlur={() => { handleOnBlur(true); }}
          />
          )}
      </div>
    </div>
  );
}

export default DataInput;
