import React, { useMemo } from 'react';
import { ReactSortable } from 'react-sortablejs';
import classNames from 'classnames';
import { sortBy, uniqueId } from 'lodash';
import styles from './Outlines.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { Button } from '../Button/button';
import QuestionMark from '../QuestionMark/QuestionMark';

function Outlines({ form, field }) {
  const outlines = field?.value || [];

  // Function to update the outlines in the Formik
  const setOutlines = (newOutlines) => {
    // Sort the new outlines based on the 'checked' property
    const sortedOutlines = sortBy(newOutlines, outline => !outline.checked);

    form.setFieldValue(field.name, sortedOutlines);
  };

  const handleSentenceChange = (index, sentence) => {
    // Create a new array of outlines with the existing outlines
    const newOutlines = [
      ...outlines,
    ];

    // Update the sentence at the specified index
    newOutlines[index].sentence = sentence;

    // Update the outlines with the modified array
    setOutlines(newOutlines);
  };

  const toggleCheckbox = (index) => {
    // Create a new array of outlines with the existing outlines
    const newOutlines = [
      ...outlines,
    ];

    // Toggle the 'checked' property at the specified index
    newOutlines[index].checked = !newOutlines[index].checked;

    // Update the outlines with the sorted array
    setOutlines(newOutlines);
  };

  // Memoized value of the checked items in the outlines
  const checkedItems = useMemo(() => outlines.filter(outline => outline.checked), [outlines]);

  // Memoized value of the word count based on the checked items
  const wordcount = useMemo(() => 200 + checkedItems.length * 150, [checkedItems]);

  const handleAddOutline = () => {
    // Create a new outline with a unique ID, unchecked state, and an empty sentence
    const newOutline = { id: uniqueId(), checked: false, sentence: '' };

    // Update the outlines by adding the new outline to the existing array
    setOutlines([...outlines, newOutline]);

    // Scroll to the bottom of the outlines wrapper element
    setTimeout(() => {
      // Get the outlines wrapper element by its ID
      const wrapper = document.getElementById('outlines-wrapper');

      // Scroll to the bottom of the wrapper by setting the scrollTop to the scrollHeight
      wrapper.scrollTop = wrapper.scrollHeight;
    }, 50);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.leftSide}>
          Select your paragraphs
        </div>
        <div className={styles.rightSide}>
          <span>
            Estimated word count
            {' '}
            <span className={styles.count}>{wordcount}</span>
          </span>
          <QuestionMark tooltipContent='This is the estimated word count, based on the number of chosen paragraphs.' />
        </div>
      </div>
      {/* <div className={styles.blueInformationBox}> */}
      {/*  <div className={styles.infoIcon}>i</div> */}
      {/*  <span>Based on a ‘medium’ text length we suggest you use 5 paragraphs. Using more paragraphs
        will affect the word count of your article.</span> */}
      {/* </div> */}
      <div className={styles.separator} />
      <ReactSortable
        list={outlines}
        setList={setOutlines}
        className={styles.outlinesWrapper}
        id='outlines-wrapper'
        handle={`.${styles.dragIcon}`}
        animation={200}
        delayOnTouchStart
      >
        {outlines.map((outline, index) => {
          const outlineActive = outline.checked && outline.sentence !== '';

          return (
            <div
              className={styles.outlineItem}
              key={outline.id}
            >
              <span className={classNames(styles.number, { [styles.active]: outlineActive })}>{index + 1}</span>
              <div
                role='button'
                tabIndex={0}
                className={classNames(styles.checkbox, { [styles.active]: outlineActive })}
                onClick={() => toggleCheckbox(index)}
                onKeyDown={() => toggleCheckbox(index)}
                aria-label={outline.sentence}
              />
              <input
                placeholder='Enter paragraph title'
                className={classNames({ [styles.active]: outlineActive })}
                value={outline.sentence}
                onChange={(e) => handleSentenceChange(index, e.target.value)}
                maxLength={255}
              />
              <span className={styles.dragIcon}>{renderIcon('drag')}</span>
            </div>
          );
        })}
      </ReactSortable>
      <Button
        className={styles.button}
        actionName='+ Add own paragraph'
        action={handleAddOutline}
      />
    </div>
  );
}

export default Outlines;
