import * as types from '../constants/dropdown';

export interface DropdownState {
  opened: boolean;
}

const initialState = {
  opened: false,
};

export default function dropdown(state: DropdownState = initialState, action) {
  switch (action.type) {
    case types.TOGGLE_DROPDOWN:
      return {
        ...state,
        opened: action.payload,
      };
    default:
      return state;
  }
}
