import React from 'react';
import styles from './styles.module.scss';

function Footer({ children }) {
  return (
    <div className={styles.container}>
      { children }
    </div>
  );
}

export default Footer;
