import React, { useEffect, useMemo } from 'react';
import { Field } from 'formik';
import { animateScroll, Element } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import Card from '../../../core/components/Card/Card';
import { NextButton, PreviousButton } from '../../../core/components/Button/button';
import RadioGroup from '../../../core/components/RadioGroup/RadioGroup';
import {
  extractOutline,
  extractSummary,
  resetSections,
  setActiveCard,
  setActiveStep, setSectionValue,
  showPreviousStepWarning,
} from '../../../../actions/content-refresh';
import languages from '../../../../assets/json/languages.json';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import Footer from '../../../Footer';
import Mixpanel from '../../../../util/Mixpanel';

function SecondStep({ form }) {
  const dispatch = useDispatch();

  const {
    titles,
    serpContext,
    loader,
    summaries,
    activeCard,
  } = useSelector(state => state.contentRefresh);

  // Retrieve the crowdin code for the selected language from the languages array based on the form's language value
  const languageCode = useMemo(() => {
    const value = languages.find((language) => form.values.language === language.id)?.crowdin_code;
    return value;
  }, [languages, form.values.language]);

  // Preview scroll handling
  useEffect(() => {
    animateScroll.scrollToTop({
      containerId: 'previewScroller',
      duration: 0,
      smooth: false,
    });
  }, [form.values.title]);

  // Reset the form sections and values based on the given card number
  const resetToCard = cardNumber => {
    dispatch(setActiveCard(cardNumber));

    if (cardNumber <= 1) {
      dispatch(resetSections('summaries'));
      form.setFieldValue('summary', '');
    }
  };

  return (
    <>
      <Element
        name='step2_card1'
        className={styles.element}
      >
        <Card
          isLoading={activeCard === 1 && loader}
          completed={activeCard > 1}
          editAction={() => resetToCard(1)}
          title='Title'
          subtitle='Let us help you or write your own title.'
          content={(activeCard === 1 && titles.length > 0) ? (
            <Field
              name='title'
              component={RadioGroup}
              label='Select (and edit) a title'
              suggestions={titles}
              placeholder='Enter your own title'
              onUpdateValue={(radioIndex, newValue) => {
                const newTitles = [...titles];

                newTitles[radioIndex] = newValue;

                dispatch(setSectionValue('titles', newTitles));
              }}
            />
          ) : (
            form.values.title ? (<span>View your title in the preview panel on the right</span>) : ''
          )}
          actionBlock={activeCard === 1 && (
            <NextButton
              disabled={!form.values.title}
              action={() => {
                dispatch(setActiveCard(2));
                dispatch(extractSummary(languageCode, form.values.input_source));
                Mixpanel.track('Content Refresh - Briefing - Select Title');
              }}
            />
          )}
        />
      </Element>
      <Element
        name='step2_card2'
        className={styles.element}
      >
        <Card
          isLoading={activeCard === 2 && loader}
          disabled={activeCard < 2}
          completed={activeCard > 2}
          editAction={() => resetToCard(2)}
          title='Summary'
          subtitle='Before creating an outline, it is useful to generate a summary first.'
          content={(activeCard === 2 && summaries.length > 0) ? (
            <Field
              name='summary'
              component={RadioGroup}
              label='Select (and edit) a summary'
              suggestions={summaries}
              placeholder='Enter your own summary'
              onUpdateValue={(radioIndex, newValue) => {
                const newSummaries = [...summaries];

                newSummaries[radioIndex] = newValue;

                dispatch(setSectionValue('summaries', newSummaries));
              }}
            />
          ) : (
            form.values.summary
          )}
          actionBlock={
            activeCard === 2
            && (
              <PreviousButton
                action={() => {
                  dispatch(setActiveCard(1));
                  dispatch(resetSections('summaries'));
                  form.setFieldValue('summary', '');
                }}
              />
            )
          }
        />
      </Element>
      <Footer>
        <StepperFooter
          nextDisabled={(activeCard !== 3 && !form.values.summary)}
          nextStep={() => {
            dispatch(extractOutline(languageCode, form.values.subject, form.values.input_source, serpContext));
            dispatch(setActiveStep(3));
            dispatch(setActiveCard(1));
            Mixpanel.track('Content Refresh - Briefing - Select Summary');
          }}
          previousDisabled={false}
          previousStep={() => {
            dispatch(showPreviousStepWarning(() => {
              dispatch(setActiveStep(1));
              dispatch(setActiveCard(4));
              dispatch(resetSections('titles', 'summaries'));
              form.setFieldValue('title', undefined);
              form.setFieldValue('summary', '');
            }));
          }}
          nextStepNumber={3}
          actionName='generate outlines'
        />
      </Footer>
    </>
  );
}

export default SecondStep;
