import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import styles from './TooltipProvider.module.scss';

function TooltipProvider() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Tooltip
      id='tooltip'
      className={classNames(styles.wrapper, { [styles.hidden]: isMobile })}
    />
  );
}

export default TooltipProvider;
