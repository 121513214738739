import React from 'react';
import styles from './ContentPreviewToolbar.module.scss';
import renderIcon from '../../../../util/renderIcon';

function ContentPreviewToolbar({
  preview, previewAction, previewValue,
}) {
  return (
    <div className={styles.wrapper}>
      {preview && (
      <button
        className={styles.button}
        onClick={previewAction}
        type='button'
      >
        {previewValue ? renderIcon('arrowUp') : renderIcon('arrowDown')}
        {previewValue ? 'close' : 'preview'}
      </button>
      )}
    </div>
  );
}

export default ContentPreviewToolbar;
