import React from 'react';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import Modal from '../core/components/Modal';
import { PARAGRAPHS_ERROR_MODAL } from '../../constants/modal';

function ParagraphsErrorModal() {
  return (
    <Modal name={PARAGRAPHS_ERROR_MODAL}>
      <div className={styles.modal}>
        <div className={styles.imageLarge}>
          {renderIcon('writingIllustration')}
        </div>
        <span className={styles.title}>
          Just a quick tip
        </span>
        <span className={styles.text}>
          We&apos;re excited to help you craft content, but before we dive into the magic,
          we need to ensure your masterpiece flows smoothly.
          <br />
          <br />
          Make sure you have at least one paragraph.
          <br />
          <br />
          This will help maintain a natural rhythm and keep your readers engaged from start to finish.
          <br />
          After all, a well-structured piece is like a journey – it&apos;s the stops along the way that make the adventure memorable!
        </span>
      </div>
    </Modal>
  );
}

export default ParagraphsErrorModal;
