import * as types from '../constants/auth';

export interface AuthState {
  loggedIn: string;
  user: Record<string, any>;
  validationErrors: Record<string, any>;
  errorMessage: string;
}

const initialState = {
  loggedIn: types.PENDING_AUTH,
  user: {},
  validationErrors: {},
  errorMessage: '',
};

export default function auth(state: AuthState = initialState, action) {
  switch (action.type) {
    case types.LOGGED_IN:
      return {
        ...state,
        loggedIn: types.LOGGED_IN,
        user: action.payload,
      };
    case types.NOT_LOGGED_IN:
      return {
        ...state,
        loggedIn: types.NOT_LOGGED_IN,
        user: {},
      };
    case types.SET_ERRORS:
      return {
        ...state,
        loggedIn: types.NOT_LOGGED_IN,
        user: {},
        ...action.payload,
      };
    case types.RESET_ERRORS:
      return {
        ...state,
        validationErrors: initialState.validationErrors,
        errorMessage: initialState.errorMessage,
      };
    default:
      return state;
  }
}
