import React from 'react';
import styles from './styles.module.scss';
import AnimatedLoader from '../AnimatedLoader/AnimatedLoader';
import { LoaderProps } from './interfaces';
import renderIcon from '../../../../util/renderIcon';

const Loader: React.FC<LoaderProps> = ({
  pageLoader, componentLoader, pageLoaderMainText, pageLoaderSecondaryText, componentLoaderText,
}) => (
  <>
    {pageLoader ? (
      <div className={styles.pageLoaderWrapper}>
        <div className={styles.pageLoaderContent}>
          <AnimatedLoader
            loaderWidth='26px'
            rowGap='4px'
            barHeight='2px'
          />
          <span className={styles.pageLoaderMainText}>{pageLoaderMainText}</span>
          {pageLoaderSecondaryText && (
          <div className={styles.pageLoaderSecondaryText}>
            {renderIcon('clock')}
            <span>{pageLoaderSecondaryText}</span>
          </div>
          )}
        </div>
      </div>
    ) : null}
    {componentLoader ? (
      <div className={styles.componentLoaderWrapper}>
        <AnimatedLoader
          loaderWidth='16px'
          rowGap='2.5px'
          barHeight='1.5px'
        />
        <span>{componentLoaderText}</span>
      </div>
    ) : null}
  </>
);

export default Loader;
