import React, { useEffect } from 'react';
import { Field, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../ContentCreation/Steps/styles.module.scss';
import Paragraph from '../../../core/components/Paragraph/Paragraph';
import renderIcon from '../../../../util/renderIcon';
import { ParagraphsPageProps } from './interfaces';
import EstimationCard from '../../../core/components/EstimationCard/EstimationCard';
import AdditionalTopics from '../../../core/components/AdditionalTopics/AdditionalTopics';
import { RootState } from '../../../../reducers';
import { deleteAdditionalTopic, listAdditionalTopics } from '../../../../actions/content-creation';
import FormikInput from '../../../core/components/FormikInput/FormikInput';
import { openModal } from '../../../../actions/modal';
import { ALTERNATIVES_MODAL } from '../../../../constants/modal';

const ParagraphsPage: React.FC<ParagraphsPageProps> = ({
  form,
}) => {
  const dispatch = useDispatch();

  type TextLength = 'short' | 'medium' | 'long';

  // TODO: CHANGE THIS LOGIC, HARDCODED !!!
  const textLength: TextLength = 'short';

  const numOfParagraphs = {
    short: 3,
    medium: 5,
    long: 7,
  };

  const topics = useSelector((state: RootState) => state.contentCreation.additionalTopics);

  useEffect(() => {
    dispatch(listAdditionalTopics());
  }, []);

  return (
    <div className={styles.wrapper}>
      <EstimationCard
        title='Paragraphs'
        description='In this step you can choose your paragraphs.
      See our suggestions on the right.
      On the bottom you can add additional paragraphs based on trending topics.
      Move paragraphs up and down to get them in the right order.'
        wordCount={form.values.paragraphs.length * 150 + 400}
        infoBox={`Tip: based on a ${textLength} text length we advice you to use ${numOfParagraphs[textLength]} paragraphs.`}
      />
      <Field
        component={FormikInput}
        name='title'
        label='Title'
        type='text'
        additionalStyle={{
          fontSize: '22px',
          fontFamily: '"GTWalsheimMedium", sans-serif',
        }}
        placeholder='Write your title here'
      />
      <FieldArray
        name='paragraphs'
        render={arrayHelpers => (
          <>
            <div className={styles.paragraphs}>
              {form?.values?.paragraphs.map((paragraph, index) => (
                <Field
                  component={Paragraph}
                  key={index}
                  name={`paragraphs.${index}`}
                  label={`Paragraph ${index + 1}`}
                  placeholder='Enter your paragraph title here'
                  hasTitle
                  actions={[
                    {
                      icon: renderIcon('arrowDown'),
                      disabled: index === form.values.paragraphs.length - 1,
                      tooltip: 'Move down',
                      onClick: () => index < form.values.paragraphs.length - 1 && arrayHelpers.swap(index, index + 1),
                    },
                    {
                      icon: renderIcon('arrowUp'),
                      disabled: index === 0,
                      tooltip: 'Move up',
                      onClick: () => index > 0 && arrayHelpers.swap(index, index - 1),
                    },
                    {
                      icon: renderIcon('delete'),
                      disabled: form.values.paragraphs.length === 1,
                      tooltip: 'Remove',
                      onClick: () => {
                        if (form.values.paragraphs.length > 1) {
                          arrayHelpers.remove(index);
                        }
                      },
                    },
                  ]}
                />
              ))}
              <div
                tabIndex={0}
                role='button'
                className={styles.addParagraphButton}
                onClick={() => {
                  arrayHelpers.push({
                    title: '',
                    text: '<p>&zwnj;</p>',
                  });
                }}
                onKeyDown={() => {
                  arrayHelpers.push({
                    title: '',
                    text: '<p>&zwnj;</p>',
                  });
                }}
              >
                + add own paragraph
              </div>
            </div>
            {topics.length > 0 && (
            <AdditionalTopics
              topics={topics}
              addTopic={(topic, topicIndex) => {
                arrayHelpers.push({
                  title: topic,
                });
                dispatch(deleteAdditionalTopic(topicIndex));
              }}
            />
            )}
          </>
        )}
      />
    </div>
  );
};

export default ParagraphsPage;
