import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import { Button, SecondaryButton } from '../core/components/Button/button';
import Input from '../core/components/Input';
import { validateEmail } from '../../util/helpers';
import Modal from '../core/components/Modal';
import { EXIT_FLOW_WARNING_MODAL } from '../../constants/modal';
import { LOGGED_IN } from '../../constants';

function ExitFlowModal({
  showMessage,
  saveAction,
  cardLoader,
  exitWithoutSaveAction,
}) {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [emailForSaveAction, setEmailForSaveAction] = useState(false);

  const loggedIn = useSelector(state => state.auth.loggedIn) === LOGGED_IN;
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (loggedIn) {
      showMessage && navigate('/');
    } else {
      showMessage && setLoader(false);
    }
  }, [showMessage, loggedIn]);

  useEffect(() => {
    // If we are waiting for the loader to finish, and the card loader has just finished
    if ((emailForSaveAction !== false) && !cardLoader) {
      // If there's no card loader anymore, trigger the save action immediately
      saveAction(emailForSaveAction);
    }
  }, [cardLoader, emailForSaveAction]);

  const handleContinueLater = (email) => {
    setLoader(true);

    if (cardLoader) {
      // If there's a card loader, wait for it to finish, and then trigger the save action
      setEmailForSaveAction(email);
    } else {
      // If there's no card loader, trigger the save action immediately
      saveAction(email);
    }
  };

  return (
    <Modal name={EXIT_FLOW_WARNING_MODAL}>
      {loggedIn ? (
        <div className={styles.modal}>
          <span
            className={styles.title}
            style={{ marginTop: '20px' }}
          >
            Do you wish to save your progress?
          </span>
          {loader ? (
            <div className={styles.loader}>
              <div className={styles.spinner} />
              <span>
                Please wait a second. Would you like some fries while waiting? &#127839;
              </span>
            </div>
          ) : (
            <div className={styles.buttons}>
              <Button
                actionName='no, thanks'
                className={styles.greyButton}
                action={() => {
                  exitWithoutSaveAction();
                  navigate('/');
                }}
              />
              <Button
                actionName='yes, continue later'
                className={styles.confirmButton}
                action={() => handleContinueLater(user.user.email)}
              />
            </div>
          )}
        </div>
      ) : (
        <Formik
          initialValues={{}}
        >
          {({ values }) => (
            <div className={styles.modal}>
              <div className={styles.image}>
                {renderIcon('noData')}
              </div>
              <span className={styles.title}>
                Don’t lose your progress
              </span>
              <span className={styles.text}>
                Before you leave, make sure you don’t lose your progress.
                <br />
                Enter your email and we’ll send you a link so you can pick it up where you left off.
              </span>
              {showMessage
                ? (
                  <div className={styles.message}>
                    <div className={styles.row}>
                      {renderIcon('check')}
                      <span>
                        {`We’ve sent a link to ${values.email}`}
                      </span>
                    </div>
                    <Button
                      actionName='close'
                      className={styles.closeButton}
                      action={() => navigate('/')}
                    />
                  </div>
                )
                : (
                  <>
                    <Field
                      component={Input}
                      placeholder='Enter email'
                      name='email'
                      validate={(value) => {
                        if (!validateEmail(value || '')) {
                          return 'Invalid email address';
                        }
                      }}
                    />
                    {loader ? (
                      <div className={styles.loader}>
                        <div className={styles.spinner} />
                        <span>
                          We’re sending you the email, would you like some fries while waiting? &#127839;
                        </span>
                      </div>
                    ) : (
                      <>
                        <Button
                          disabled={!validateEmail(values.email || '')}
                          actionName='continue later'
                          className={styles.continueLaterButton}
                          action={() => handleContinueLater(values.email)}
                        />
                        <SecondaryButton
                          name='no, thanks'
                          onClick={() => {
                            exitWithoutSaveAction();
                            navigate('/');
                          }}
                        />
                      </>
                    )}
                  </>
                )}
            </div>
          )}
        </Formik>
      )}
    </Modal>
  );
}

export default ExitFlowModal;
