import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import styles from './styles.module.scss';
import renderIcon from '../../../util/renderIcon';
import Dropdown from '../../core/components/Dropdown/Dropdown';
import { logout } from '../../../actions/auth';

function AppHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname: activeRoute } = useLocation();

  const user = useSelector(state => state.auth.user);

  const menuItems = [
    {
      name: 'tools',
      route: '/tools',
    },
    {
      name: 'documents',
      route: '/documents',
    },
  ];

  const logOut = () => {
    dispatch(logout());
    navigate('/home');
  };

  return (
    <div className={styles.container}>
      <div className={styles.widthContainer}>
        <div className={styles.logo}>
          {renderIcon('logo')}
        </div>
        <div className={styles.navigation}>
          {menuItems.map((menuItem) => (
            <span
              tabIndex={0}
              role='button'
              className={classNames(styles.navigationItem, { [styles.active]: activeRoute === menuItem.route })}
              onKeyDown={() => {
                navigate(menuItem.route);
              }}
              onClick={() => {
                navigate(menuItem.route);
              }}
            >
              {menuItem.name}
            </span>
          ))}
        </div>
        <div className={styles.rightSide}>
          <Dropdown
            buttonContent={(
              <span
                className={styles.user}
              >
                {(user.user.first_name && user.user.last_name)
                  ? `${user.user.first_name.charAt(0).toUpperCase()}${user.user.last_name.charAt(0).toUpperCase()}`
                  : ''}
              </span>
            )}
            position='right'
            minWidth='200'
            content={(
              <>
                {/* <div className={styles.option}> */}
                {/*  Invite colleagues */}
                {/* </div> */}
                {/* <Separator margin='10px' /> */}
                <div
                  className={classNames(styles.option, styles.logout)}
                  onClick={() => logOut()}
                  onKeyDown={() => logOut()}
                  tabIndex='0'
                  role='button'
                >
                  Log out
                </div>
              </>
            )}
          />
        </div>
      </div>
      <div className={styles.tabs}>
        <div className={styles.tabName}>
          {' '}
          {activeRoute === '/tools' ? 'Tools' : 'My documents'}
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
