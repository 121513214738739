import React from 'react';
import styles from './styles.module.scss';
import { SeparatorProps } from './interfaces';

const Separator: React.FC<SeparatorProps> = ({
  margin,
}) => (
  <div
    className={styles.separator}
    style={{ margin }}
  />
);

export default Separator;
