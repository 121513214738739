import React, { useState, useEffect } from 'react';
import { fill } from 'lodash';
import styles from './AnimatedLoader.module.scss';

const AnimatedLoader = ({ loaderWidth, rowGap, barHeight }) => {
  const numberOfBars = 4;
  const [bars, setBars] = useState(fill(Array(numberOfBars), 0));

  useEffect(() => {
    let intervalId = null;
    const animateBars = () => {
      const newBars = fill(Array(numberOfBars), 0);
      setBars(newBars);

      let currentIndex = 0;
      intervalId = setInterval(() => {
        newBars[currentIndex] = 100;
        setBars([...newBars]);
        // eslint-disable-next-line no-plusplus
        currentIndex++;

        if (currentIndex >= numberOfBars) {
          clearInterval(intervalId);
          setTimeout(() => {
            animateBars();
          }, 500);
        }
      }, 250);
    };

    animateBars();

    return () => intervalId && clearInterval(intervalId);
  }, []);

  return (
    <div
      className={styles.wrapper}
      style={{ width: loaderWidth, rowGap }}
    >
      {bars.map((width, index) => (
        <div
          key={index}
          className={styles.grayBar}
          style={{ width: index === numberOfBars - 1 ? '60%' : '100%', height: barHeight }}
        >
          <div
            className={styles.blueBar}
            style={{
              opacity: bars[0] === 0 ? 0 : 1,
              width: `${width}%`,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default AnimatedLoader;
