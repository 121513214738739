import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import styles from './Select.module.scss';
import renderIcon from '../../../../util/renderIcon';
import { SelectProps } from './interfaces';

const Select: React.FC<SelectProps> = ({
  label,
  selectLabel,
  field,
  form,
  options,
  width = '270px',
  outline = false,
  searchable = true,
  dropdownPosition,
}) => {
  const [active, setActive] = useState(false);
  const [query, setQuery] = useState('');

  // Filter the options based on the query value (case-insensitive)
  const filteredOptions = options?.filter(option => option.label.toLowerCase().includes(query.trim().toLowerCase())) || [];

  // Determine the label of the selected option
  const selectedLabel = options?.find(option => field.value === option.value)?.label || selectLabel;

  // Determine the icon of the selected option
  const selectedIcon = options?.find(option => field.value === option.value)?.icon || '';

  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      <div
        role='button'
        tabIndex={0}
        className={classNames(styles.select, { [styles.outline]: outline })}
        style={{ width }}
        onClick={() => setActive(true)}
        onKeyDown={() => setActive(true)}
      >
        {selectedIcon}
        <span>{selectedLabel}</span>
        <span>{renderIcon('arrowDown')}</span>
      </div>
      <OutsideClickHandler
        disabled={!active}
        onOutsideClick={() => setActive(false)}
      >
        {active && (
        <div
          className={styles.dropdown}
          style={dropdownPosition === 'left' ? { left: 0 } : { right: 0 }}
        >
          {(searchable && options?.length > 0) && (
            <div className={styles.search}>
              <input
                placeholder='search'
                onChange={e => setQuery(e.currentTarget.value)}
              />
            </div>
          )}
          <div className={styles.options}>
            {filteredOptions.map((option) => (
              <div
                role='button'
                tabIndex={0}
                className={classNames(styles.option, {
                  [styles.selected]: field.value === option.value,
                })}
                onClick={() => {
                  form.setFieldValue(field.name, option.value);
                  setActive(false);
                  setQuery('');
                }}
                onKeyDown={() => {
                  form.setFieldValue(field.name, option.value);
                  setActive(false);
                  setQuery('');
                }}
                key={option.value}
              >
                {option.icon && (
                  <span className={styles.icon}>{option.icon}</span>
                )}
                {option.label}
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div className={styles.noResults}>
                No results found
              </div>
            )}
          </div>
        </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default Select;
