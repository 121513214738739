import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { scroller } from 'react-scroll';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import ParagraphsPage from './Steps/ParagraphsPage';
import GeneratePage from './Steps/GeneratePage';
import SettingsPage from './Steps/SettingsPage';
import BriefingPage from './Steps/BriefingPage';
import { RootState } from '../../../reducers';
import EditWarningModal from '../../Modals/EditWarningModal';

const Index = () => {
  const [formVals, setFormVals] = useState({});

  const store = useSelector((state: RootState) => state.contentCreation);

  const {
    activeStep,
    activeCard,
  } = store;

  // Scroll to a specific card within a step.
  const scrollToCard = (stepNumber, cardNumber) => {
    scroller.scrollTo(`step${stepNumber}_card${cardNumber}`, {
      offset: -90,
      duration: 1500,
      smooth: true,
      containerId: 'scrollerContainer2',
    });
  };

  // Scrolling to a specific card when the 'activeCard' or 'activeStep' dependencies change.
  useEffect(() => {
    setTimeout(() => scrollToCard(activeStep, activeCard), 50);
  }, [activeCard, activeStep, scrollToCard]);

  return (
    <div
      id='scrollerContainer2'
      className={styles.scroller}
    >
      <EditWarningModal />
      <div className={styles.container}>
        <Formik
          initialValues={{
            language: 10,
            textLength: 1,
            subject: '',
            title: 'First impression of Avatar 2: brilliant visuals, questionable plot',
            introduction: {
              text: 'The highly anticipated sequel will not appear in theaters with us until December 16, but a few privileged souls were allowed to attend the premiere. And their reactions to Avatar: The Way of Water are now in. Will the film be an epic blockbuster or not?',
            },
            paragraphs: [
              {
                title: 'A Reveal of Pandora\'s Secrets: Initial Reactions to Avatar 2',
                text: 'Sagittis congue tristique sem euismod faucibus donec risus facilisis dapibus nisi lacinia dignissim volutpat eleifend, penatibus, sapien congue. Ante, et vel feugiat hymenaeos lobortis dolor mus blandit porttitor. Nunc. In sociosqu. Taciti felis ultricies dignissim integer nisl magnis tellus. Amet nulla rhoncus est sodales. Ornare semper sagittis aptent varius praesent cum sed conubia lacus leo quis sit. \n'
                  + '\n'
                  + 'Neque penatibus platea ad ligula non potenti facilisi in luctus eleifend lobortis tellus habitant pulvinar velit elit nibh dignissim Venenatis fringilla volutpat. Tortor in amet neque nisl pede id bibendum proin consectetuer maecenas non euismod dis elit ornare metus duis at odio phasellus lacinia elementum praesent porta netus maecenas condimentum consectetuer vehicula. Donec morbi habitasse dui ornare. Sem aliquet nisi varius dapibus suscipit, in orci. Posuere adipiscing curae; nascetur torquent etiam. Torquent tristique aliquam nulla nec. Leo vel. Aliquet consequat lobortis. Primis adipiscing montes orci leo libero velit non aliquet. Lacinia, morbi quam tempor dapibus porttitor ullamcorper at tellus elementum.',
              },
              {
                title: 'Exploring the Galaxy with Kate Winslet in Avatar',
                text: 'Tortor in amet neque nisl pede id bibendum proin consectetuer maecenas non euismod dis elit ornare metus duis at odio phasellus lacinia elementum praesent porta netus maecenas condimentum consectetuer vehicula. Donec morbi habitasse dui ornare. Sem aliquet nisi varius dapibus suscipit, in orci. Posuere adipiscing curae; nascetur torquent etiam. Torquent tristique aliquam nulla nec. Leo vel. Aliquet consequat lobortis. Primis adipiscing montes orci leo libero velit non aliquet. Lacinia, morbi quam tempor dapibus porttitor ullamcorper at tellus elementum.',
              },
            ],
            conclusion: {
              text: 'Tortor in amet neque nisl pede id bibendum proin consectetuer maecenas non euismod dis elit ornare metus duis at odio phasellus lacinia elementum praesent porta netus maecenas condimentum consectetuer vehicula. Donec morbi habitasse dui ornare. Sem aliquet nisi varius dapibus suscipit, in orci. Posuere adipiscing curae; nascetur torquent etiam. Torquent tristique aliquam nulla nec. Leo vel. Aliquet consequat lobortis. Primis adipiscing montes orci leo libero velit non aliquet. Lacinia, morbi quam tempor dapibus porttitor ullamcorper at tellus elementum.',
            },
          }}
          onSubmit={() => console.log('submit')}
        >
          {form => {
            setFormVals(form.values);
            return (
              <>
                {activeStep === 1 && (
                  <SettingsPage
                    form={form}
                  />
                )}
                {activeStep === 2 && (
                  <BriefingPage
                    form={form}
                  />
                )}
                {activeStep === 3 && (
                  <ParagraphsPage
                    form={form}
                  />
                )}
                {activeStep === 4 && (
                  <GeneratePage
                    form={form}
                  />
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Index;
