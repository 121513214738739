import * as types from '../constants/documents';

export interface DocumentsState {
  // TODO: change type of data
  data: any[];
  total: number | null;
  currentPage: number;
}

const initialState = {
  data: [],
  total: null,
  currentPage: 1,
};

export default function documents(state: DocumentsState = initialState, action) {
  switch (action.type) {
    case types.LIST_FINISHED_DOCUMENTS:
      return {
        ...state,
        // if data is an object, transform it to an array
        data: Object.values(action.payload.data) || [],
        total: action.payload.total || null,
      };
    case types.UPDATE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
}
