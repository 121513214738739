import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import { Button } from '../core/components/Button/button';
import { closeModal } from '../../actions/modal';
import Modal from '../core/components/Modal';
import { CHARACTERS_WARNING_MODAL } from '../../constants/modal';

function CharactersWarningModal() {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal);

  return (
    <Modal name={CHARACTERS_WARNING_MODAL}>
      <div className={styles.modal}>
        <div className={styles.image}>
          {renderIcon('charactersWarning')}
        </div>
        <span className={styles.title}>
          Are you sure you want to continue?
        </span>
        <span className={styles.text}>
          Please note that processing very long texts may require a lot of tokens, which could exhaust your token balance quickly.
          <br />
          <br />
          {/* eslint-disable-next-line max-len */}
          Additionally, there may be limitations on the length of the text that can be processed, which could affect the accuracy of the results.
        </span>
        <div className={styles.buttonsRow}>
          <Button
            actionName='edit content'
            action={() => dispatch(closeModal())}
          />
          <Button
            actionName='continue'
            action={() => {
              modal?.data?.continueAction && modal.data.continueAction();

              dispatch(closeModal());
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CharactersWarningModal;
