import React, { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Landing from '../pages/Landing';
import TooltipProvider from '../core/components/TooltipProvider/TooltipProvider';
import ContentRefresh from '../pages/ContentRefresh';
import { closeModal } from '../../actions/modal';
import Main from '../Main/main';
import Tools from '../pages/Tools';
import Documents from '../pages/Documents';
import Login from '../pages/AuthPages/Login';
import Registration from '../pages/AuthPages/Registration';
import Welcome from '../pages/AuthPages/Welcome';
import { setToken } from '../../util/api';
import { LOGGED_IN, NOT_LOGGED_IN, PENDING_AUTH } from '../../constants';
import { resetErrors, setLoggedOut, whoami } from '../../actions/auth';
import 'react-toastify/dist/ReactToastify.css';
import Toast from '../core/components/Toast/Toast';
import ForgotPassword from '../pages/AuthPages/ForgotPassword';
import ResetPassword from '../pages/AuthPages/ResetPassword';
import CheckResetEmail from '../pages/AuthPages/CheckResetEmail';
import ContentCreationV3 from '../pages/ContentCreationV3';
import ContentCreationNew from '../pages/ContentCreationNew';
import DocumentsIFrame from '../pages/IFramePageTest';
import IFrameTools from '../pages/IFrameTools';
import ContentRefreshNew from '../pages/ContentRefreshNew';

function RoutesWrapper() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  // Close modal and reset errors, on every path change
  useEffect(() => {
    dispatch(closeModal());
    dispatch(resetErrors());
  }, [pathname]);

  // Handle auth reducer after page load
  useEffect(() => {
    const authToken = localStorage.getItem('token');

    if (authToken?.length > 0) {
      // Set token in Axios headers
      setToken(authToken);

      // Send whoami request
      dispatch(whoami());
    } else {
      dispatch(setLoggedOut());
    }
  }, []);

  // Determine current logged in state
  const authState = useSelector(state => state.auth.loggedIn);
  const loggedIn = useMemo(() => authState === LOGGED_IN, [authState]);
  const loggedOut = useMemo(() => authState === NOT_LOGGED_IN, [authState]);
  const pendingAuth = useMemo(() => authState === PENDING_AUTH, [authState]);

  return (
    <Main>
      <TooltipProvider />
      <Toast />
      <Routes>
        {!pendingAuth ? (
          <>
            { /* All users */ }
            <Route
              index
              path='welcome'
              element={<Welcome />}
            />
            <Route
              index
              path='iframe'
              element={<DocumentsIFrame />}
            />
            <Route
              index
              path='iframe-tools'
              element={<IFrameTools />}
            />
            <Route
              index
              path='home'
              element={<Landing />}
            />
            <Route
              index
              path='content-creation'
              element={<ContentCreationNew />}
            />
            <Route
              index
              path='test'
              element={<ContentCreationV3 />}
            />
            {/* <Route */}
            {/*  index */}
            {/*  path='content-refresh' */}
            {/*  element={<ContentRefresh />} */}
            {/* /> */}
            <Route
              index
              path='content-refresh'
              element={<ContentRefreshNew />}
            />
            { /* Guest users */ }
            {loggedOut && (
            <Route
              index
              path='login'
              element={<Login />}
            />
            )}
            {loggedOut && (
            <Route
              index
              path='forgot-password'
              element={<ForgotPassword />}
            />
            )}
            {loggedOut && (
            <Route
              index
              path='password_reset/:token'
              element={<ResetPassword />}
            />
            )}
            {loggedOut && (
            <Route
              index
              path='check-reset-email'
              element={<CheckResetEmail />}
            />
            )}
            {loggedOut && (
            <Route
              index
              path='sign-up'
              element={<Registration />}
            />
            )}

            { /* Logged-in users */ }
            {loggedIn && (
            <Route
              index
              path='tools'
              element={<Tools />}
            />
            )}
            {loggedIn && (
            <Route
              index
              path='documents'
              element={<Documents />}
            />
            )}

            { /* Default redirection route */ }
            <Route
              path='*'
              element={(
                <Navigate
                  to='/home'
                  replace
                />
              )}
            />
          </>
        ) : ''}
      </Routes>
    </Main>
  );
}

export default RoutesWrapper;
