import React, { useCallback } from 'react';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Element } from 'react-scroll';
import classNames from 'classnames';
import Card from '../../../core/components/Card/Card';
import FormikInput from '../../../core/components/FormikInput/FormikInput';
import styles from './styles.module.scss';
import Tags from '../../../core/components/Tags/Tags';
import { FooterButton, NextButton, PreviousButton } from '../../../core/components/Button/button';
import {
  setActiveCard, setActiveStep, setSectionValue,
} from '../../../../actions/content-creation';
import { RootState } from '../../../../reducers';
import RadioGroup from '../../../core/components/RadioGroup/RadioGroup';
import RadioButtonGroup from '../../../core/components/RadioButtonGroup/RadioButtonGroup';
import renderIcon from '../../../../util/renderIcon';
import { Option } from '../../../core/components/RadioButtonGroup/interfaces';
import { resetSections } from '../../../../actions/content-refresh';
import Separator from '../../../core/components/Separator/Separator';

const BriefingPage = ({ form }) => {
  const { titles, activeCard } = useSelector((state:RootState) => state.contentCreation);
  const dispatch = useDispatch();

  const blogGenerationOptions = [
    {
      id: 1,
      icon: renderIcon('thunder'),
      label: 'Turbo',
      description: 'We use our AI magic to automatically genereate your content.',
      caption: 'Takes only 1 min.',
    },
    {
      id: 2,
      icon: renderIcon('mediumLengthText'),
      label: 'Advanced',
      description: 'Manually control the content and order of your paragraphs',
      caption: 'Takes only 3 min.',
    },
  ];

  const blogGenerationButtonContent = useCallback((option: Option) => (
    <div className={styles.radioButtonWrapper}>
      <div className={styles.radioButtonHeader}>
        {option.icon}
        {option.label}
      </div>
      <div className={styles.radioButtonContent}>
        {option.description}
        <div className={styles.radioButtonCaption}>
          {option.caption}
        </div>
      </div>
    </div>
  ), []);

  // Reset the form sections and values based on the given card number
  const resetToCard = cardNumber => {
    dispatch(setActiveCard(cardNumber));

    if (cardNumber <= 2) {
      dispatch(resetSections('blogGeneration'));
      form.setFieldValue('blogGeneration', '');
    }

    if (cardNumber <= 1) {
      dispatch(resetSections('title'));
      form.setFieldValue('title', '');
    }
  };

  return (
    <div className={styles.container}>
      <Element
        name='step2_card1'
        className={styles.element}
      >
        <Card
          title='What is your blog about?'
          completed={activeCard > 1}
          editAction={() => resetToCard(1)}
          content={(
            <>
              {activeCard === 1 && (
                <>
                  <div className={styles.title}>Tell us more about the subject</div>
                  <Field
                    name='subject'
                    component={FormikInput}
                    label='Please describe it in one sentence'
                    placeholder='Enter main subject'
                    type='text'
                  />
                  <div className={styles.title}>What is the primary (SEO) keyword?</div>
                  <Field
                    name='primarySEO'
                    component={Tags}
                    limit={1}
                    label='We will use this to optimise your blog'
                    placeholder='Enter main keyword'
                  />
                  <div className={styles.title}>Do you have any secondary (SEO) keywords that you want to cover?</div>
                  <Field
                    name='secondarySEO'
                    component={Tags}
                    limit={5}
                    label='This helps us to further shape your blog'
                    placeholder='Enter secondary keywords'
                  />
                </>
              )}
              {activeCard > 1 && (
                <div className={styles.completed}>
                  <div className={styles.fieldTitle}>Subject</div>
                  <div className={styles.value}>{form.values.subject}</div>
                  <div className={styles.fieldTitle}>Primary (SEO) keyword</div>
                  <div className={styles.value}>
                    {form.values.primarySEO?.map((tag) => (
                      <span className={styles.pill}>{tag}</span>
                    ))}
                  </div>
                  {(form.values.secondarySEO?.length > 0) && <div className={styles.fieldTitle}>Secondary (SEO) keywords</div>}
                  <div className={styles.value}>
                    {form.values.secondarySEO?.map((tag) => (
                      <span className={styles.pill}>{tag}</span>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
          actionBlock={(activeCard === 1) && (
            <NextButton
              disabled={activeCard !== 1 || (!form.values.subject || !form.values.primarySEO)}
              action={() => {
                dispatch(setActiveCard(2));
              }}
            />
          )}
        />
      </Element>
      <Element
        name='step2_card2'
        className={styles.element}
      >
        <Card
          completed={activeCard > 2}
          disabled={activeCard < 2}
          title='Choose your title'
          subtitle='Choose one of the generated title’s or write your own.'
          editAction={() => resetToCard(2)}
          content={(
            <>
              {(activeCard === 2 && titles.length > 0) && (
              <Field
                name='title'
                component={RadioGroup}
                label='Select (and edit) a title'
                suggestions={titles}
                placeholder='Enter your own title'
                onUpdateValue={(radioIndex, newValue) => {
                  const newTitles = [...titles];

                  newTitles[radioIndex] = newValue;

                  dispatch(setSectionValue('titles', newTitles));
                }}
              />
              )}
              {activeCard > 2 && (<div>{form.values.title}</div>)}
            </>
          )}
          actionBlock={
            activeCard === 2
            && (
              <>
                <PreviousButton
                  action={() => {
                    dispatch(setActiveCard(1));
                  }}
                />
                <NextButton
                  disabled={!(activeCard === 2 && form.values.title)}
                  action={() => {
                    dispatch(setActiveCard(3));
                  }}
                />
              </>
            )
          }
        />
      </Element>
      <Element
        name='step2_card3'
        className={styles.element}
      >
        <Card
          disabled={activeCard < 3}
          title='How would you like to generate your blog?'
          content={activeCard === 3 && (
            <div className={styles.content}>
              <Field
                name='blogGeneration'
                component={RadioButtonGroup}
                options={blogGenerationOptions}
                buttonContent={blogGenerationButtonContent}
                buttonWidth='329px'
              />
            </div>
          )}
          actionBlock={
            activeCard === 3
            && (
              <PreviousButton
                action={() => {
                  dispatch(setActiveCard(2));
                }}
              />
            )
          }
        />
      </Element>
      <Separator margin='40px 0' />
      <FooterButton
        action={() => dispatch(setActiveStep(3))}
        actionName='generate blog'
      />
    </div>
  );
};

export default BriefingPage;
