/* eslint-disable import/no-import-module-exports */
import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import i18next from 'i18next';
import TagManager from 'react-gtm-module';
import App from './components/App';
import commonEn from './i18n/en.json';
import './index.scss';
import configureStore from './store';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: commonEn,
  },
});

const store = configureStore();

// Configure Google Tag Manager, if present in .env
if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}

const renderApp = () => render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./components/App', renderApp);
}

renderApp();
