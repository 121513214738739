import { combineReducers } from 'redux';
import loader, { LoaderState } from './loader';
import contentCreation, { ContentCreationState } from './content-creation';
import contentRefresh, { ContentRefreshState } from './content-refresh';
import modal, { ModalState } from './modal';
import documents, { DocumentsState } from './documents';
import auth, { AuthState } from './auth';
import dropdown, { DropdownState } from './dropdown';
import contentCreationNew, { ContentCreationStateNew } from './content-creation-new';

export interface RootState {
  loader: LoaderState;
  contentCreation: ContentCreationState;
  contentCreationNew: ContentCreationStateNew;
  contentRefresh: ContentRefreshState;
  auth: AuthState;
  documents: DocumentsState;
  dropdown: DropdownState;
  modal: ModalState;
}

export default combineReducers<RootState>({
  loader,
  contentCreation,
  contentCreationNew,
  contentRefresh,
  modal,
  documents,
  auth,
  dropdown,
});
