import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';
import styles from './RadioGroup.module.scss';

const RadioGroup = ({
  label, placeholder, suggestions, form, field, onUpdateValue,
}) => {
  // Find the first selected index from suggestion that matches the text from formik
  const selectedIndex = useMemo(() => suggestions.indexOf(field.value), [suggestions, field.value]);

  // Set Formik value based on the selected radio option
  const handleOnClick = useCallback((index) => {
    form.setFieldValue(field.name, suggestions[index]);
  }, [field.name, suggestions]);

  const handleOnChange = useCallback((newValue: string, changeIndex: number) => {
    // edit single radio option in Redux
    if (onUpdateValue) {
      onUpdateValue(changeIndex, newValue);
    }

    if (selectedIndex === changeIndex) {
      // update Formik value
      form.setFieldValue(field.name, newValue);
    }
  }, [field.name, onUpdateValue, selectedIndex]);

  useEffect(() => {
    if (!field.value || selectedIndex === -1) {
      form.setFieldValue(field.name, suggestions[0]);

      if (onUpdateValue) {
        onUpdateValue(0, suggestions[0]);
      }
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>{label}</div>
      {suggestions.map((suggestion, index) => (
        <div
          className={classNames(styles.radioItem, { [styles.active]: index === selectedIndex })}
        // key={suggestion}
        >
          <div
            role='button'
            tabIndex={0}
            aria-label={suggestion}
            className={styles.radio}
            onClick={() => handleOnClick(index)}
            onKeyDown={() => handleOnClick(index)}
          />
          <TextareaAutosize
            className={classNames(styles.textarea, { [styles.active]: index === selectedIndex })}
            style={{ minHeight: 24 }}
            placeholder={placeholder}
            value={suggestion}
            // Part of code which should select field on click of the input
            // onClick={() => handleOnClick(index)}
            onChange={(e: any) => {
              handleOnChange(e.target.value, index);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
