import classNames from 'classnames';
import React from 'react';
import renderIcon from '../../../../util/renderIcon';
import styles from './button.module.scss';
import {
  ButtonProps,
  CloseButtonProps,
  SecondaryButtonProps,
  NextButtonProps,
  PreviousButtonProps,
  FooterButtonProps, OptionButtonProps,
} from './interfaces';

export const Button: React.FC<ButtonProps> = ({
  actionName,
  action,
  rightIcon,
  leftIcon,
  className = '',
  disabled,
}) => (
  <button
    className={classNames(className, styles['action-button'])}
    onClick={action}
    type='button'
    disabled={disabled}
  >
    {leftIcon && <span className={styles.actionButton__arrow}>{renderIcon(leftIcon)}</span>}
    {actionName}
    {rightIcon && <span className={styles.actionButton__arrow}>{renderIcon(rightIcon)}</span>}
  </button>
);

export const CloseButton: React.FC<CloseButtonProps> = ({
  onCloseClick,
}) => (
  <button
    type='button'
    className={styles.exitButton}
    onClick={onCloseClick}
  >
    close
    <span>{renderIcon('crossBold')}</span>
  </button>
);

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  onClick,
  name,
  isDisabled,
  className,
}) => (
  <button
    type='button'
    className={classNames(styles.secondaryButton, className)}
    onClick={onClick}
    disabled={isDisabled}
  >
    {name}
  </button>
);

export const NextButton: React.FC<NextButtonProps> = ({
  action,
  disabled,
}) => (
  <Button
    disabled={disabled}
    className={styles.nextButton}
    actionName='next'
    action={action}
    rightIcon='arrowDownButton'
  />
);

export const PreviousButton: React.FC<PreviousButtonProps> = ({
  action,
}) => (
  <Button
    className={styles.previousButton}
    actionName='previous'
    action={action}
    rightIcon='arrowUpButton'
  />
);

export const FooterButton: React.FC<FooterButtonProps> = ({
  action,
  disabled,
  actionName,
  nextStepNumber,
  rightIcon,
  leftIcon,
}) => (
  <Button
    disabled={disabled}
    className={styles.footerButton}
    actionName={(
      <>
        {nextStepNumber && <span className={styles.number}>{nextStepNumber}</span>}
        {actionName}
      </>
        )}
    action={action}
    rightIcon={rightIcon}
    leftIcon={leftIcon}
  />
);

export const OptionButton: React.FC<OptionButtonProps> = ({
  content,
  selected,
  onSelect,
  value,
  width,
}) => (
  <div
    tabIndex={0}
    role='button'
    className={classNames(styles.optionButton, { [styles.focus]: selected })}
    style={{ width }}
    onClick={() => onSelect(value)}
    onKeyDown={() => onSelect(value)}
  >
    {content}
  </div>
);
