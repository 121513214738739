import * as types from '../constants/modal';

export const openModal = (name, data = {}) => ({
  type: types.OPEN_MODAL,
  payload: {
    name,
    data,
  },
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});
