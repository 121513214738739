import React from 'react';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import Modal from '../core/components/Modal';
import { INTERNET_CONNECTION_ERROR_MODAL } from '../../constants/modal';

function InternetConnectionError() {
  return (
    <Modal name={INTERNET_CONNECTION_ERROR_MODAL}>
      <div className={styles.modal}>
        <div className={styles.imageLarge}>
          {renderIcon('connectionError')}
        </div>
        <span className={styles.title}>
          Oops, something went wrong
        </span>
        <span className={styles.text}>
          Sorry, it seems like there&apos;s a problem with your internet connection.
          Please check your network connection and try again later.
          <br />
          <br />
          If the issue persists, please contact your internet service provider for assistance. Thank you for your understanding.
        </span>
      </div>
    </Modal>
  );
}

export default InternetConnectionError;
