import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { animateScroll } from 'react-scroll';
import classNames from 'classnames';
import styles from './DetailPage.module.scss';
import { Button } from '../../../core/components/Button/button';
import Separator from '../../../core/components/Separator/Separator';
import { closeModal, openModal } from '../../../../actions/modal';
import { DOWNLOAD_FILE_MODAL } from '../../../../constants/modal';
import { RootState } from '../../../../reducers';
import DownloadFileModal from '../../../Modals/DownloadFileModal';
import {
  downloadContentCreationFile,
  saveContentCreationFile,
  setDownloadRequested,
} from '../../../../actions/content-creation-new';
import { LOGGED_IN } from '../../../../constants';
import Mixpanel from '../../../../util/Mixpanel';
import { revertParagraphs } from '../../../../util/helpers';

export const DetailPage = ({ form }) => {
  const {
    paragraphs,
  } = useSelector((state: RootState) => state.contentCreationNew);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn) === LOGGED_IN;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Scroll to the top of the page.
  useEffect(() => {
    animateScroll.scrollToTop({
      containerId: 'scrollerContainer',
      duration: 0,
      smooth: false,
    });
  }, []);

  const text = `
  <h1>${form.values.title}</h1>
  ${paragraphs
    ? paragraphs
      .map((paragraph, index) => {
        if (index === 0 || index === paragraphs.length - 1) {
          return `<p>${paragraph.text}</p>`;
        } else {
          return `<h2>${paragraph.title}</h2><p>${paragraph.text}</p>`;
        }
      })
      .join('')
    : ''}
`;

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const continueHash: any = searchParams.get('continue_hash');

  const originalParagraphsStructure = revertParagraphs(paragraphs);

  const handleDownloadFileNotLoggedIn = (email) => {
    dispatch(
      downloadContentCreationFile(
        form.values.title,
        originalParagraphsStructure,
        email,
        continueHash,
      ),
    );

    dispatch(setDownloadRequested());
    dispatch(closeModal());
  };

  const handleDownload = () => {
    if (!loggedIn) {
      dispatch(openModal(DOWNLOAD_FILE_MODAL));
      Mixpanel.track('Content Creation - Finalize - Press download');
      return;
    }

    saveContentCreationFile(text, continueHash).then(() => {
      navigate('/documents');
    });
    Mixpanel.track('Content Creation - Finalize - Press save article');
  };

  const getFontClass = (paragraphType) => {
    switch (paragraphType) {
      case 'h2':
        return styles.h2;
      case 'h3':
        return styles.h3;
      case 'h4':
        return styles.h4;
      default:
        return undefined;
    }
  };

  return (
    <>
      <DownloadFileModal
        action={handleDownloadFileNotLoggedIn}
      />
      <div className={styles.mainWrapper}>
        <div className={styles.buttonWrapper}>
          {!loggedIn ? (
            <Button
              className={styles.blackButton}
              actionName='download'
              rightIcon='download'
              action={handleDownload}
            />
          ) : (
            <Button
              className={styles.blackButton}
              actionName='save article'
              action={handleDownload}
            />
          )}
        </div>
        <Separator margin='40px 0' />
        <div className={styles.title}>
          {form.values.title}
        </div>
        {paragraphs.map((paragraph, index) => (
          <div className={styles.paragraph}>
            <div className={classNames(styles.pTitle, getFontClass(paragraph.type))}>
              {(index !== 0 && index !== paragraphs.length - 1) && paragraph.title}
            </div>
            <div className={styles.content}>
              <div dangerouslySetInnerHTML={{ __html: paragraph.text }} />
            </div>
          </div>
        ))}
        <Separator margin='40px 0' />
        <div className={styles.buttonWrapper}>
          {!loggedIn ? (
            <Button
              className={styles.blackButton}
              actionName='download'
              rightIcon='download'
              action={handleDownload}
            />
          ) : (
            <Button
              className={styles.blackButton}
              actionName='save article'
              action={handleDownload}
            />
          )}
        </div>
      </div>
    </>
  );
};
