import React from 'react';
import styles from './loader.module.scss';
import renderIcon from '../../../../util/renderIcon';
import Index from '../Modal';

function Loader({ isVisible, useAnimation = false }) {
  return (
    <Index
      isVisible={isVisible}
      withoutContentBackground
    >
      <div className={useAnimation && styles.logo}>
        {renderIcon('loading')}
      </div>
    </Index>
  );
}

export default Loader;
