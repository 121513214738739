import * as types from '../constants/loader';

export interface LoaderState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

export default function loader(state: LoaderState = initialState, action) {
  switch (action.type) {
    case types.SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case types.HIDE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
