import * as loaderTypes from '../constants/loader';

export function displayLoader() {
  return (dispatch) => {
    dispatch({
      type: loaderTypes.SHOW_LOADER,
    });
  };
}

export function hideLoader() {
  return (dispatch) => {
    dispatch({
      type: loaderTypes.HIDE_LOADER,
    });
  };
}
