import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import i18next from 'i18next';
import MainRoutes from './Routes';

export const AVAILABLE_LANGUAGES = ['en', 'ru', 'de'];

function App() {
  return (
    <div className='App'>
      <React.StrictMode>
        <RecoilRoot>
          <I18nextProvider i18n={i18next}>
            <Router>
              <Routes>
                <Route
                  path='/*'
                  element={<MainRoutes />}
                />
              </Routes>
            </Router>
          </I18nextProvider>
        </RecoilRoot>
      </React.StrictMode>
    </div>
  );
}

export default App;
