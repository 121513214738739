import React from 'react';
import { Field, Formik } from 'formik';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import Input from '../core/components/Input';
import { Button } from '../core/components/Button/button';
import { validateEmail } from '../../util/helpers';
import Modal from '../core/components/Modal';
import { AI_ERROR_MODAL } from '../../constants/modal';

function AIErrorModal({
  action, showMessage,
}) {
  return (
    <Modal name={AI_ERROR_MODAL}>
      <Formik
        initialValues={{}}
      >
        {({ values }) => (
          <div className={styles.modal}>
            <div className={styles.image}>
              {renderIcon('multitasking')}
            </div>
            <span className={styles.title}>
              It’s a little busy today
            </span>
            <span className={styles.text}>
              Looks like the server of OpenAI is not responding, this happens sometimes when there are too many requests.
              <br />
              <br />
              Please try again in a bit, as it usually takes 15 minutes for the server to respond again.
            </span>
            {showMessage
              ? (
                <div className={styles.message}>
                  <div className={styles.row}>
                    {renderIcon('check')}
                    <span>
                      {`We’ve sent a link to ${values.email}`}
                    </span>
                  </div>
                </div>
              )
              : (
                <>
                  <Field
                    component={Input}
                    placeholder='Enter email'
                    name='email'
                    validate={(value) => {
                      if (!validateEmail(value || '')) {
                        return 'Invalid email address';
                      }
                    }}
                  />
                  <Button
                    disabled={!validateEmail(values.email || '')}
                    actionName='continue later'
                    className={styles.continueLaterButton}
                    action={() => action(values)}
                  />
                </>
              )}
          </div>
        )}
      </Formik>
    </Modal>
  );
}

export default AIErrorModal;
