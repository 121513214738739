import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'formik';
import { animateScroll } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Card from '../../core/components/Card/Card';
import styles from './styles.module.scss';
import { Button } from '../../core/components/Button/button';
import renderIcon from '../../../util/renderIcon';
import Input from '../../core/components/Input';
import {
  downloadContentCreationFile,
  setDownloadRequested as setCreationDownloadRequested,
  setActiveCard as setActiveCardCreation,
  setActiveStep as setActiveStepCreation, saveContentCreationFile,
} from '../../../actions/content-creation';
import {
  downloadContentRefreshFile,
  saveContentRefreshFile,
  setActiveCard as setActiveCardRefresh,
  setActiveStep as setActiveStepRefresh,
  setDownloadRequested as setRefreshDownloadRequested,
} from '../../../actions/content-refresh';
import { validateEmail } from '../../../util/helpers';
import GroupOfPeopleImage from '../../../assets/images/group-of-people.png';
import SwapfietsLogo from '../../../assets/svg/swapfiets-logo-vector.svg';
import MollieLogo from '../../../assets/svg/mollie-logo.svg';
import BookingLogo from '../../../assets/svg/booking-logo.svg';
import MarleySpoonsLogo from '../../../assets/svg/marley-spoon-logo.svg';
import StudytubeLogo from '../../../assets/svg/studytube-logo.svg';
import PropertyguruLogo from '../../../assets/svg/propertyguru-logo.svg';
import Mixpanel from '../../../util/Mixpanel';
import FeedbackModal from '../../Modals/FeedbackModal';
import { FEEDBACK_MODAL } from '../../../constants/modal';
import { openModal } from '../../../actions/modal';
import { LOGGED_IN } from '../../../constants';
import TextEditor from '../../core/components/TextEditorAI/TextEditor';
import StepperFooter from '../../Footer/StepperFooter/StepperFooter';
import Footer from '../../Footer';

function Index({ documentType, form }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let loader;
  let downloadRequested;

  // Select loader and downloadRequested values based on the documentType.
  if (documentType === 'creation') {
    loader = useSelector((state) => state.contentCreation.loader);
    downloadRequested = useSelector((state) => state.contentCreation.downloadRequested);
  } else {
    loader = useSelector((state) => state.contentRefresh.loader);
    downloadRequested = useSelector((state) => state.contentRefresh.downloadRequested);
  }

  // Scroll to the top of the page.
  useEffect(() => {
    animateScroll.scrollToTop({
      containerId: 'scrollerContainer',
      duration: 0,
      smooth: false,
    });
  }, []);

  // Retrieve the checked outlines from the form values and extract the corresponding sentences
  const checkedOutlines = useMemo(() => {
    const value = form.values.outlines?.filter(outline => outline.checked).map((outline) => outline.sentence);
    return value;
  }, [form.values.outlines]);

  // Get the search parameters from the current URL
  // eslint-disable-next-line no-restricted-globals
  const searchParams = new URLSearchParams(location.search);

  // Extract the continue_hash value from the search parameters
  const continueHash = searchParams.get('continue_hash');

  // Initiating the file download based on the documentType.
  const downloadFile = () => {
    if (documentType === 'creation') {
      dispatch(downloadContentCreationFile(
        form.values.title,
        form.values.introduction,
        checkedOutlines,
        form.values.paragraphs,
        form.values.conclusion,
        form.values.email,
        continueHash,
      ));
      dispatch(setCreationDownloadRequested());
      Mixpanel.track('Content Creation - Finalize - Press download');
    } else {
      dispatch(downloadContentRefreshFile(
        form.values.title,
        form.values.introduction,
        checkedOutlines,
        form.values.paragraphs,
        form.values.conclusion,
        form.values.email,
        continueHash,
      ));
      dispatch(setRefreshDownloadRequested());
      Mixpanel.track('Content Refresh - Finalize - Press download');
    }
    dispatch(openModal(FEEDBACK_MODAL));
  };

  const [text, setText] = useState('');

  const warnIcon = {
    icon: renderIcon('warning'),
  };

  const saveToLibrary = () => {
    if (documentType === 'creation') {
      dispatch(saveContentCreationFile(text, continueHash)).then((data) => {
        if (data.data.success === false) {
          toast.warn(`${(data.data.message.text).join(' ')}`, warnIcon);
          return;
        }

        if (data.data.success) {
          navigate('/documents');
        }
      });
    } else {
      dispatch(saveContentRefreshFile(text, continueHash)).then((data) => {
        if (data.data.success === false) {
          toast.warn(`${(data.data.message.text).join(' ')}`, warnIcon);
          return;
        }

        if (data.data.success) {
          navigate('/documents');
        }
      });
    }
  };

  // Generating the appropriate Typeform URL based on the documentType.
  const typeformUrl = useMemo(() => (documentType === 'creation' ? 'https://form.typeform.com/to/QyZ8LgyD' : 'https://form.typeform.com/to/FrQ1G9VQ'), [documentType]);

  const loggedIn = useSelector(state => state.auth.loggedIn) === LOGGED_IN;

  const html = useMemo(() => {
    if (!form.values?.title) {
      return null;
    }

    let articleHtml = '';

    articleHtml += `<h2>${form.values.title}</h2>`;
    articleHtml += `<p>${form.values.introduction}</p>`;

    checkedOutlines?.forEach((heading, i) => {
      articleHtml += `<h3>${heading}</h3>`;
      articleHtml += `<p>${form.values.paragraphs[i].body_text}</p>`;
    });

    articleHtml += `<p>${form.values.conclusion}</p>`;

    return articleHtml;
  }, [form.values]);

  return (
    <>
      <div className={styles.finalContainer}>
        {loggedIn ? (
          <>
            <div className={styles.title}>Your article is ready!</div>
            <div className={styles.subtitle}>Review and edit if needed, before you save your article</div>
            {html && (
            <TextEditor
              title='Input component'
              initialText={html}
              noWrapper
              onChange={(newHtml) => setText(newHtml)}
              blockType
            />
            )}
          </>
        ) : (
          <>
            <FeedbackModal typeformUrl={typeformUrl} />
            <div className={styles.containerRow}>
              {(downloadRequested && !loader) ? (
                <Card
                  content={(
                    <div className={styles.downloadSuccess}>
                      {renderIcon('enthusiastic')}
                      <span className={styles.title}>
                        Hooray!
                      </span>
                      <span className={styles.text}>
                        Your file has been successfully downloaded and sent to your email.
                      </span>
                    </div>
                  )}
                  actionBlock={(
                    <a
                      href={typeformUrl}
                      target='_blank'
                      rel='noreferrer'
                      className={styles.buttonWrapper}
                    >
                      <Button
                        actionName='Provide feedback'
                      />
                    </a>
                  )}
                />
              ) : (
                <Card
                  title='Download article'
                  isLoading={loader}
                  loaderText={(
                    <>
                      Download will begin shortly, would you like some fries while waiting? &#127839;
                    </>
                  )}
                  content={(
                    <Field
                      component={Input}
                      label='Your business e-mail address'
                      placeholder='Enter your e-mail address'
                      name='email'
                      validate={(value) => {
                        if (!validateEmail(value || '')) {
                          return 'Invalid email address';
                        }
                      }}
                    />
                  )}
                  actionBlock={(
                    <Button
                      disabled={!validateEmail(form.values.email || '')}
                      className={styles.blackButton}
                      actionName='download'
                      rightIcon='download'
                      action={downloadFile}
                    />
                  )}
                />
              )}
              <Card
                className={styles.blueCard}
                title={(
                  <div className={styles.blueTitle}>
                    {renderIcon('upgradeCheck')}
                    <span>Need more articles</span>
                  </div>
                )}
                content={(
                  <div className={styles.blueContent}>
                    <div className={styles.rowChecked}>
                      <div className={styles.greenCircle}>{renderIcon('check')}</div>
                      <span>Get unlimited descriptions and access to all functionalities</span>
                    </div>
                    <div className={styles.rowChecked}>
                      <div className={styles.greenCircle}>{renderIcon('check')}</div>
                      <span>Add your own tone of voice and target audience</span>
                    </div>
                    <div className={styles.rowChecked}>
                      <div className={styles.greenCircle}>{renderIcon('check')}</div>
                      <span>Generate high-quality content with our top copywriters and translators</span>
                    </div>
                  </div>
                )}
                actionBlock={(
                  <a
                    href='https://contentoo.com/book-a-demo/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button
                      className={styles.blueButton}
                      actionName='upgrade to premium'
                    />
                  </a>
                )}
              />
            </div>
            <div className={styles.grayCard}>
              <div className={styles.row}>
                <div className={styles.image}>
                  <img
                    src={GroupOfPeopleImage}
                    alt='Group of people'
                  />
                </div>
                <div className={styles.content}>
                  <h2>Optimize your article</h2>
                  <p>
                    We have thousands of expert editors who can help you optimize your descriptions.
                    Make sure your descriptions are optimized for search engines and written to appeal
                    to your target audience as good as possible.
                  </p>
                  <a
                    href='https://contentoo.com/book-a-demo/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button actionName='book a demo' />
                  </a>
                </div>
              </div>
              <div className={styles.trustedBy}>
                <span>Trusted by</span>
                <img
                  src={SwapfietsLogo}
                  alt='Swapfiets Logo'
                  style={{ width: '63px' }}
                />
                <img
                  src={MollieLogo}
                  alt='Mollie Logo'
                  style={{ width: '58px' }}
                />
                <img
                  src={BookingLogo}
                  alt='Booking Logo'
                  style={{ width: '96px' }}
                />
                <img
                  src={MarleySpoonsLogo}
                  alt='Marley Spoons Logo'
                  style={{ width: '94px' }}
                />
                <img
                  src={StudytubeLogo}
                  alt='Studytube Logo'
                  style={{ width: '89px' }}
                />
                <img
                  src={PropertyguruLogo}
                  alt='Propertyguru Logo'
                  style={{ width: '102px' }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {loggedIn
        && (
        <Footer>
          <StepperFooter
            nextStep={saveToLibrary}
            previousStep={() => {
              if (documentType === 'creation') {
                dispatch(setActiveStepCreation(3));
                dispatch(setActiveCardCreation(checkedOutlines.length + 2));
              } else if (documentType === 'refresh') {
                dispatch(setActiveStepRefresh(4));
                dispatch(setActiveCardRefresh(checkedOutlines.length + 2));
              }
            }}
            actionName='save to library'
          />
        </Footer>
        )}
    </>
  );
}

export default Index;
