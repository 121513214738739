import React, { useState } from 'react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import { OutlineProps } from './interfaces';
import styles from './Outline.module.scss';
import renderIcon from '../../../../util/renderIcon';
import Input from '../InputAI/Input';
import { TooltipSettings } from '../TooltipSettings/TooltipSettings';

export const Outline: React.FC<OutlineProps> = ({
  title, tag, onClickOnEditIcon, upFunction, downFunction, deleteFunction,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentTitle, setCurrentTitle] = useState<string>(title);
  const onClickForInput = () => {
    setEditMode(!editMode);
    onClickOnEditIcon && onClickOnEditIcon(currentTitle);
  };
  return (
    <div
      tabIndex={0}
      role='button'
      className={classNames(styles.outlineWrapper, styles[tag], { [styles.inputWrapperActive]: editMode })}
      onClick={() => {
        if (!editMode) {
          setEditMode(true);
        }
      }}
      onKeyDown={() => {
        if (!editMode) {
          setEditMode(true);
        }
      }}
    >
      <div className={styles.toolTipWrapper}>
        <TooltipSettings
          upFunction={upFunction}
          downFunction={downFunction}
          deleteFunction={deleteFunction}
          tag={tag}
        />
      </div>
      <div className={styles.tag}>{tag}</div>
      {!editMode && (
        <>
          <div className={styles.title}>{currentTitle}</div>
          <button
            type='button'
            className={styles.editIcon}
          >
            {renderIcon('edit')}
          </button>
        </>
      )}
      {editMode && (
      <div className={styles.inputWrapper}>
        <OutsideClickHandler onOutsideClick={() => {
          if (currentTitle.length > 0) {
            setEditMode(false);
          }
          setCurrentTitle(title);
        }}
        >
          <Input
            value={currentTitle}
            showCheckIcon={editMode}
            onClickOnShowIcon={onClickForInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentTitle(event.target.value);
            }}
            checkButtonDisabled={currentTitle?.length <= 0}
          />
        </OutsideClickHandler>
      </div>
      )}
    </div>
  );
};
