import FileDownload from 'js-file-download';
import { useMemo } from 'react';
import { client } from '../util/api';
import * as types from '../constants/content-creation-new';
import { AI_ERROR_MODAL, INTERNET_CONNECTION_ERROR_MODAL, NOT_FOUND_ERROR_MODAL } from '../constants/modal';
import { openModal } from './modal';
import {
  CONCLUSION_IN_DEPTH_LOADER,
  GENERATE_FULL_ARTICLE_LOADER,
  GENERATE_OUTLINES_LOADER,
  INTRODUCTION_IN_DEPTH_LOADER, PARAGRAPH_IN_DEPTH_LOADER, PARAGRAPH_REGENERATE_LOADER,
} from '../constants/loaders-new';
import { toggleLoader } from './content-creation';

export const apiUrl = process.env.REACT_APP_API_URL;

export const showError = (name = AI_ERROR_MODAL) => (dispatch) => {
  dispatch(openModal(name));
};

export const showLoader = (name) => ({
  type: types.SHOW_LOADER,
  payload: name,
});

export const hideLoader = () => ({
  type: types.HIDE_LOADER,
});

const handleRequestFailures = (dispatch, error) => {
  if (error.response) {
    if (error.response.status === 404) {
      // show 404 error
      dispatch(showError(NOT_FOUND_ERROR_MODAL));
    } else {
      // show AI error
      dispatch(showError(AI_ERROR_MODAL));
    }
  } else {
    // show internet connection error
    dispatch(showError(INTERNET_CONNECTION_ERROR_MODAL));
  }
};

export function setPreviousProgressLoader(loaderActive) {
  return {
    type: types.SET_PREVIOUS_PROGRESS_LOADER,
    payload: loaderActive,
  };
}

export const storeProgressAndSendBackendErrorEmail = async (email, store, values, continueHash = '') => {
  const storeProgressUrl = `${apiUrl}/continue-flow/store-current-flow`;
  const sendEmailUrl = `${apiUrl}/mail/open-ai-error`;

  const data = {
    data: JSON.stringify({
      store,
      values,
    }),
    document_type: 'creation',
  };

  if (continueHash !== '') {
    data.hash = continueHash;
  }

  await client.post(storeProgressUrl, data);

  return client.post(sendEmailUrl, {
    document_type: 'creation',
    email,
  });
};

// export const storeProgressAndSendExitEmail = async (email = null, store, values, continueHash = '') => {
//   const storeProgressUrl = `${apiUrl}/continue-flow/store-current-flow`;
//   const sendEmailUrl = `${apiUrl}/mail/left-flow`;
//
//   const data = {
//     data: JSON.stringify({
//       store,
//       values,
//     }),
//     document_type: 'creation',
//   };
//
//   if (continueHash !== '') {
//     data.hash = continueHash;
//   }
//
//   await client.post(storeProgressUrl, data);
//
//   if (email) {
//     await client.post(sendEmailUrl, {
//       document_type: 'creation',
//       email,
//       continueHash,
//     });
//   }
// };
export const leftFlow = async (email, continueHash) => {
  const myUrl = `${apiUrl}/mail/left-flow`;

  if (continueHash !== '') {
    await client.post(myUrl, {
      document_type: 'creation',
      email,
      hash: continueHash,
    });
  }
};

export const storeCurrentFlow = async (store, values, email, providedHash) => {
  const myUrl = `${apiUrl}/continue-flow/store-current-flow`;

  const myData = {
    data: JSON.stringify({
      store,
      values,
    }),
    document_type: 'creation',
    hash: providedHash,
  };

  const response = await client.post(myUrl, myData);

  const hashToUse = providedHash || response.data.hash;

  return leftFlow(email, hashToUse);
};

export const restorePreviousProgress = (continueHash) => {
  const myUrl = `${apiUrl}/continue-flow/continue/${continueHash}`;

  return client.get(myUrl);
};

export const setDownloadRequested = () => ({
  type: types.SET_DOWNLOAD_REQUESTED,
});

export const resetReducer = () => ({
  type: types.RESET_REDUCER,
});

export function resetSections(...sections) {
  return {
    type: types.RESET_SECTIONS,
    payload: sections,
  };
}

export function setActiveCard(card) {
  return {
    type: types.SET_ACTIVE_CARD,
    payload: card,
  };
}

export function setActiveStep(step) {
  return {
    type: types.SET_ACTIVE_STEP,
    payload: step,
  };
}

export function setStore(store) {
  return {
    type: types.SET_STORE,
    payload: store,
  };
}

export function setSectionValue(section, value) {
  return {
    type: types.SET_SECTION_VALUE,
    payload: {
      section,
      value,
    },
  };
}

export function setParagraph(title, text, index) {
  return {
    type: types.SET_PARAGRAPH,
    payload: {
      title,
      text,
      index,
    },
  };
}

export const addParagraph = (index, type) => ({
  type: types.ADD_PARAGRAPH,
  payload: {
    paragraph: {
      type,
      title: 'New paragraph title',
      text: 'New paragraph text',
    },
    index,
  },
});

export function deleteParagraphs(indexes) {
  return {
    type: types.DELETE_PARAGRAPHS,
    payload: indexes,
  };
}

export function changeParagraphTypes(indexes) {
  return {
    type: types.CHANGE_PARAGRAPH_TYPES,
    payload: indexes,
  };
}

export function setIntroduction(text) {
  return {
    type: types.SET_INTRODUCTION,
    payload: text,
  };
}

export function setConclusion(text) {
  return {
    type: types.SET_CONCLUSION,
    payload: text,
  };
}

export function generateTitles(languageCode, subject, keywords, mainKeyword, setLoader) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/content-creation/generate-titles`;

    dispatch(resetSections('titles'));

    client.post(myUrl, {
      subject, keywords, main_keyword: mainKeyword, target_language: languageCode,
    })
      .then(data => {
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveCard(1));
          dispatch(resetSections('titles'));
          return;
        }

        setLoader(false);

        dispatch({
          type: types.GENERATE_TITLES,
          payload: data.data.data.output,
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);

        setLoader(false);

        dispatch(setActiveCard(1));
        dispatch(resetSections('titles'));
      });
  };
}

// export function generateOutlines(languageCode, subject, mainKeyword, keywords, title, articleLength, serpContext = false) {
//   return (dispatch) => {
//     const myUrl = `${apiUrl}/content-creation/generate-outline`;
//
//     dispatch(resetSections('outlines'));
//
//     const postData = {
//       subject, keywords, main_keyword: mainKeyword, title, target_language: languageCode, article_length: articleLength,
//     };
//
//     if (serpContext) {
//       postData.get_serp_data = true;
//       postData.serp_context = serpContext;
//     }
//
//     client.post(myUrl, postData)
//       .then(data => {
//         dispatch(hideLoader());
//
//         if (data.data.data.success === false) {
//           dispatch(showError());
//           dispatch(setActiveStep(2));
//           dispatch(setActiveCard(3));
//           dispatch(resetSections('outlines'));
//           return;
//         }
//
//         // Filter out empty or whitespace-only outlines from the output array
//         const filteredOutlines = data.data.data.output.filter((outline) => outline.trim() !== '');
//
//         dispatch({
//           type: types.GENERATE_OUTLINES,
//           payload: {
//             outlines: filteredOutlines,
//             outlinesSerp: data.data.data.serpOutput || [],
//           },
//         });
//       }).catch((error) => {
//         handleRequestFailures(dispatch, error);
//         dispatch(setActiveStep(2));
//         dispatch(setActiveCard(3));
//         dispatch(resetSections('outlines'));
//       });
//   };
// }

export function generateOutlines(
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  articleLength,
) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/content-creation/generate-h3h4-outlines`;

    dispatch(resetSections('outlines'));

    const postData = {
      target_language: languageCode, subject, main_keyword: mainKeyword, keywords, title, article_length: articleLength,
    };

    client.post(myUrl, postData)
      .then(data => {
        dispatch(hideLoader());

        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveStep(2));
          dispatch(setActiveCard(3));
          dispatch(resetSections('outlines'));
          return;
        }

        // Transforming outlines from response to match desired structure
        const modifiedOutlines = data.data.data.output.outlines.map((h2) => ({
          title: h2.h2_title,
          subheadings: h2.h3_subheadings.map((h3) => ({
            title: h3.h3_title,
            subheadings: h3.h4_subheadings.map((h4) => ({
              title: h4.h4_title,
            })),
          })),
        }));

        dispatch({
          type: types.GENERATE_OUTLINES,
          payload: modifiedOutlines,
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveStep(2));
        dispatch(setActiveCard(3));
        dispatch(resetSections('outlines'));
      });
  };
}

export function setSerpContext(subject, countryCode, generateOutlinesData) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/serp/context/`;

    dispatch(resetSections('serpContext'));

    // const location = `google.${countryCode.toLowerCase()}`;
    const location = countryCode;

    client.post(myUrl, {
      subject, location,
    })
      .then(data => {
        dispatch({
          type: types.SET_SERP_CONTEXT,
          payload: data.data.data.output || '',
        });

        // After we get the SERP context, generate outlines using `generateOutlinesData`, and optionally SERP context
        dispatch(generateOutlines(
          ...generateOutlinesData,
          data.data.data.output || '',
        ));
      }).catch(() => {
        dispatch({
          type: types.SET_SERP_CONTEXT,
          payload: '',
        });

        // After we get the SERP context, generate outlines using `generateOutlinesData`, and optionally SERP context
        dispatch(generateOutlines(
          ...generateOutlinesData,
          '',
        ));
      });
  };
}

// OLD ADVANCED FLOW
// export function generateFullArticleAdvanced(languageCode, subject, keywords, mainKeyword, title, outlines, toneOfVoice) {
//   return (dispatch) => {
//     const myUrl = `${apiUrl}/content-creation/generate-full-article`;
//
//     dispatch(showLoader(GENERATE_FULL_ARTICLE_LOADER));
//
//     client.post(myUrl, {
//       subject, keywords, main_keyword: mainKeyword, target_language: languageCode, title, outlines, tov: toneOfVoice,
//     })
//       .then(data => {
//         dispatch(hideLoader());
//
//         if (data.data.data.success === false) {
//           dispatch(showError());
//           dispatch(setActiveCard(3));
//           return;
//         }
//
//         const { output } = data.data.data;
//
//         // Get all keys from the object, and remove keys which value is blank
//         const objectKeys = Object.keys(output).filter(key => output[key] !== '');
//
//         // Get value from by first object key
//         const introduction = output[objectKeys[0]];
//
//         // Get value from by last object key
//         const conclusion = output[objectKeys[objectKeys.length - 1]];
//
//         // Get an array of objects (avoiding first and last key)
//         const paragraphs = objectKeys.slice(1, objectKeys.length - 1).map(key => ({
//           title: key,
//           text: output[key],
//         }));
//
//         dispatch({
//           type: types.GENERATE_KEY_MESSAGES,
//           payload: data.data.data.key_messages,
//         });
//
//         dispatch({
//           type: types.GENERATE_SUMMARY,
//           payload: data.data.data.summary,
//         });
//
//         dispatch({
//           type: types.GENERATE_FULL_ARTICLE,
//           payload: {
//             introduction,
//             conclusion,
//             paragraphs,
//           },
//         });
//       }).catch((error) => {
//         handleRequestFailures(dispatch, error);
//
//         dispatch(setActiveStep(3));
//       });
//   };
// }

// OLD TURBO FLOW
// export function generateFullArticleTurbo(languageCode, subject, keywords, mainKeyword, title, toneOfVoice, articleLength, outlines) {
//   return (dispatch) => {
//     const myUrl = `${apiUrl}/content-creation/generate-full-article`;
//
//     dispatch(showLoader(GENERATE_FULL_ARTICLE_LOADER));
//
//     client.post(myUrl, {
//       subject,
//       keywords,
//       main_keyword: mainKeyword,
//       target_language: languageCode,
//       title,
//       tov: toneOfVoice,
//       is_turbo: true,
//       article_length: articleLength,
//       outlines,
//     })
//       .then(data => {
//         dispatch(hideLoader());
//         if (data.data.data.success === false) {
//           dispatch(showError());
//           dispatch(setActiveStep(2));
//           dispatch(setActiveCard(3));
//           dispatch(resetSections('outlines'));
//           return;
//         }
//
//         const { output } = data.data.data;
//
//         // Get all keys from the object, and remove keys which value is blank
//         const objectKeys = Object.keys(output).filter(key => output[key] !== '');
//
//         // Get value from by first object key
//         const introduction = output[objectKeys[0]];
//
//         // Get value from by last object key
//         const conclusion = output[objectKeys[objectKeys.length - 1]];
//
//         // Get an array of objects (avoiding first and last key)
//         const paragraphs = objectKeys.slice(1, objectKeys.length - 1).map(key => ({
//           title: key,
//           text: output[key],
//         }));
//
//         dispatch({
//           type: types.GENERATE_KEY_MESSAGES,
//           payload: data.data.data.key_messages,
//         });
//
//         dispatch({
//           type: types.GENERATE_SUMMARY,
//           payload: data.data.data.summary,
//         });
//
//         dispatch({
//           type: types.GENERATE_FULL_ARTICLE,
//           payload: {
//             introduction,
//             conclusion,
//             paragraphs,
//           },
//         });
//       }).catch((error) => {
//         handleRequestFailures(dispatch, error);
//         dispatch(setActiveStep(2));
//         dispatch(setActiveCard(3));
//         dispatch(resetSections('outlines'));
//       });
//   };
// }

export function generateFullArticleTurbo(languageCode, subject, mainKeyword, keywords, title, toneOfVoice, articleLength) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/content-creation/generate-h3h4-whole-article`;

    dispatch(showLoader(GENERATE_FULL_ARTICLE_LOADER));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      main_keyword: mainKeyword,
      keywords,
      title,
      tov: toneOfVoice,
      article_length: articleLength,
      is_turbo: true,
    })
      .then(data => {
        dispatch(hideLoader());
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveStep(2));
          dispatch(setActiveCard(3));
          dispatch(resetSections('outlines'));
          return;
        }

        const modifiedArticle = data.data.data.output.outlines.reduce((result, item) => {
          if (item.h2_title) {
            result.push({
              type: 'h2',
              title: item.h2_title,
              text: item.h2_content.trim(),
            });
          }

          if (item.h3_subheadings) {
            item.h3_subheadings.forEach((h3Item) => {
              if (h3Item.h3_title) {
                result.push({
                  type: 'h3',
                  title: h3Item.h3_title,
                  text: h3Item.h3_content.trim(),
                });
              }

              if (h3Item.h4_subheadings) {
                h3Item.h4_subheadings.forEach((h4Item) => {
                  if (h4Item.h4_title) {
                    result.push({
                      type: 'h4',
                      title: h4Item.h4_title,
                      text: h4Item.h4_content.trim(),
                    });
                  }
                });
              }
            });
          }

          return result;
        }, []);

        const introduction = modifiedArticle[0].text;
        const conclusion = modifiedArticle[modifiedArticle.length - 1].text;
        const paragraphs = modifiedArticle;

        dispatch({
          type: types.GENERATE_FULL_ARTICLE,
          payload: {
            introduction,
            conclusion,
            paragraphs,
          },
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveStep(2));
        dispatch(setActiveCard(3));
        dispatch(resetSections('outlines'));
      });
  };
}

export function generateFullArticleAdvanced(languageCode, subject, mainKeyword, keywords, title, toneOfVoice, outlines) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/content-creation/generate-h3h4-whole-article`;

    dispatch(showLoader(GENERATE_FULL_ARTICLE_LOADER));

    client.post(myUrl, {
      subject,
      main_keyword: mainKeyword,
      keywords,
      target_language: languageCode,
      title,
      tov: toneOfVoice,
      outlines,
    })
      .then(data => {
        dispatch(hideLoader());
        if (data.data.data.success === false) {
          dispatch(showError());
          dispatch(setActiveStep(2));
          dispatch(setActiveCard(3));
          dispatch(resetSections('outlines'));
          return;
        }

        const modifiedArticle = data.data.data.output.outlines.reduce((result, item) => {
          if (item.h2_title) {
            result.push({
              type: 'h2',
              title: item.h2_title,
              text: item.h2_content.trim(),
            });
          }

          if (item.h3_subheadings) {
            item.h3_subheadings.forEach((h3Item) => {
              if (h3Item.h3_title) {
                result.push({
                  type: 'h3',
                  title: h3Item.h3_title,
                  text: h3Item.h3_content.trim(),
                });
              }

              if (h3Item.h4_subheadings) {
                h3Item.h4_subheadings.forEach((h4Item) => {
                  if (h4Item.h4_title) {
                    result.push({
                      type: 'h4',
                      title: h4Item.h4_title,
                      text: h4Item.h4_content.trim(),
                    });
                  }
                });
              }
            });
          }

          return result;
        }, []);

        const introduction = modifiedArticle[0].text;
        const conclusion = modifiedArticle[modifiedArticle.length - 1].text;
        const paragraphs = modifiedArticle;

        dispatch({
          type: types.GENERATE_FULL_ARTICLE,
          payload: {
            introduction,
            conclusion,
            paragraphs,
          },
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
        dispatch(setActiveStep(2));
        dispatch(setActiveCard(3));
        dispatch(resetSections('outlines'));
      });
  };
}

export const generateIntroductions = async (subject, mainKeyword, keywords, title, outlines, languageCode) => {
  const url = `${apiUrl}/content-creation/generate-introduction`;

  const data = await client.post(url, {
    subject, main_keyword: mainKeyword, keywords, title, outlines, target_language: languageCode,
  });

  return data.data.data.output.map(introduction => introduction.replace(/^\s+|\n/g, ''));
};

export const generateParagraphs = async (
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  toneOfVoice,
  outlines,
  paragraphToWrite,
  keyMessages = [],
) => {
  const url = `${apiUrl}/content-creation/generate-h3h4-paragraph`;

  const data = await client.post(url, {
    subject,
    main_keyword: mainKeyword,
    keywords,
    title,
    tov: toneOfVoice,
    outlines,
    target_language: languageCode,
    paragraph_to_write: paragraphToWrite,
    key_messages: keyMessages,
  });

  return data.data.data.output.map(paragraph => paragraph.replace(/^\s+|\n/g, ''));
};

export const generateConclusions = async (
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  toneOfVoice,
  outlines,
  introduction,
  outlineContent,
) => {
  const url = `${apiUrl}/content-creation/generate-conclusion`;

  const data = await client.post(url, {
    subject,
    main_keyword: mainKeyword,
    keywords,
    title,
    tov: toneOfVoice,
    outlines,
    target_language: languageCode,
    introduction,
    outline_content: outlineContent,
    key_messages: [],
  });

  return data.data.data.output.map(conclusion => conclusion.replace(/^\s+|\n/g, ''));
};

export function generateIntroductionInDepth(
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  paragraphContent,
  keyMessages = [],
) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/content-creation/generate-paragraph-in-depth`;

    dispatch(showLoader(INTRODUCTION_IN_DEPTH_LOADER));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      main_keyword: mainKeyword,
      keywords,
      title,
      paragraph_content: paragraphContent,
      paragraph_to_write: 'Introduction',
      key_messages: keyMessages,
    })
      .then(data => {
        dispatch(hideLoader());

        if (data.data.data.success === false) {
          dispatch(showError());
          return;
        }

        dispatch({
          type: types.GENERATE_IN_DEPTH_INTRODUCTION,
          payload: data.data.data.output,
        });
      }).catch((error) => {
        dispatch(hideLoader());

        handleRequestFailures(dispatch, error);
      });
  };
}

export function generateParagraphInDepth(
  index,
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  paragraphToWrite,
  paragraphContent,
  keyMessages = [],
) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/content-creation/generate-paragraph-in-depth`;

    dispatch(showLoader(`${PARAGRAPH_IN_DEPTH_LOADER}_${index}`));

    client.post(myUrl, {
      subject,
      main_keyword: mainKeyword,
      keywords,
      title,
      target_language: languageCode,
      paragraph_to_write: paragraphToWrite,
      paragraph_content: paragraphContent,
      key_messages: keyMessages,
    })
      .then(data => {
        dispatch(hideLoader());

        if (data.data.data.success === false) {
          dispatch(showError());
          return;
        }

        dispatch({
          type: types.GENERATE_IN_DEPTH_PARAGRAPH,
          payload: {
            text: data.data.data.output,
            index,
          },
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
      });
  };
}

export function generateConclusionInDepth(
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  paragraphContent,
  keyMessages = [],
) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/content-creation/generate-paragraph-in-depth`;

    dispatch(showLoader(CONCLUSION_IN_DEPTH_LOADER));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      main_keyword: mainKeyword,
      keywords,
      title,
      paragraph_content: paragraphContent,
      paragraph_to_write: 'Conclusion',
      key_messages: keyMessages,
    })
      .then(data => {
        dispatch(hideLoader());

        if (data.data.data.success === false) {
          dispatch(showError());
          return;
        }

        dispatch({
          type: types.GENERATE_IN_DEPTH_CONCLUSION,
          payload: data.data.data.output,
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
      });
  };
}

export function regenerateParagraph(
  index,
  languageCode,
  subject,
  mainKeyword,
  keywords,
  title,
  toneOfVoice,
  outlines,
  introduction,
  paragraphToWrite,
  outlineContent,
  keyMessages = [],
) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/content-creation/generate-paragraph`;

    dispatch(showLoader(`${PARAGRAPH_REGENERATE_LOADER}_${index}`));

    client.post(myUrl, {
      target_language: languageCode,
      subject,
      main_keyword: mainKeyword,
      keywords,
      title,
      tov: toneOfVoice,
      outlines,
      introduction,
      paragraph_to_write: paragraphToWrite,
      outline_content: outlineContent,
      key_messages: keyMessages,
    })
      .then(data => {
        dispatch(hideLoader());

        if (data.data.data.success === false) {
          dispatch(showError());
          return;
        }

        const filteredParagraphs = data.data.data.output.map(paragraph => paragraph.replace(/^\s+|\n/g, ''));

        dispatch({
          type: types.REGENERATE_PARAGRAPH,
          payload: {
            output: filteredParagraphs[0],
            index,
          },
        });
      }).catch((error) => {
        handleRequestFailures(dispatch, error);
      });
  };
}

export function downloadContentCreationFile(title, paragraphs, email, continueHash = null) {
  return (dispatch) => {
    const myUrl = `${apiUrl}/download-h3h4-file`;

    const paragraphHeadings = paragraphs.map(paragraph => paragraph.title);

    const paragraphsText = paragraphs.map(paragraph => paragraph.text);

    const requestData = {
      title,
      paragraphs,
      email,
      document_type: 'creation',
    };

    if (continueHash) {
      requestData.hash = continueHash;
    }

    client.post(myUrl, requestData, {
      responseType: 'blob',
    })
      .then(data => {
        FileDownload(data.data, `${title}.docx`);
      });
  };
}

export function saveContentCreationFile(text, continueHash = null) {
  const myUrl = `${apiUrl}/finished_documents/`;

  const requestData = {
    text,
    document_type: 'creation',
  };

  if (continueHash) {
    requestData.hash = continueHash;
  }

  return client.post(myUrl, requestData);
}
