import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import { Button } from '../core/components/Button/button';
import { closeModal } from '../../actions/modal';
import Modal from '../core/components/Modal';
import { CONFIRM_DELETING_DOCUMENT_MODAL } from '../../constants/modal';
import { deleteDocument } from '../../actions/documents';
import { toggleDropdown } from '../../actions/dropdown';
import { checkUserAuthenticated } from '../../actions/auth';

function ConfirmDeletingDocument() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { row, onDelete } = useSelector(state => state.modal.data);

  const successIcon = {
    icon: renderIcon('greenCheckMark'),
  };

  const warnIcon = {
    icon: renderIcon('warning'),
  };

  return (
    <Modal name={CONFIRM_DELETING_DOCUMENT_MODAL}>
      <div className={styles.modal}>
        <span
          className={styles.title}
          style={{ textAlign: 'center' }}
        >
          Are you sure you want to delete this project?
        </span>
        <div className={styles.buttons}>
          <Button
            actionName='no'
            action={() => dispatch(closeModal())}
            className={styles.greyButton}
          />
          <Button
            actionName='yes'
            className={styles.confirmButton}
            action={() => {
              dispatch(deleteDocument(row.id, row.document_status)).then((data) => {
                if (!checkUserAuthenticated(data, navigate, dispatch)) {
                  return;
                }

                onDelete();

                dispatch(toggleDropdown(false));
                dispatch(closeModal());

                toast.success('Document successfully deleted!', successIcon);
              }).catch(() => {
                toast.warn('An error has occurred.', warnIcon);
              });
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmDeletingDocument;
