import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { Button } from '../core/components/Button/button';
import { closeModal } from '../../actions/modal';
import Modal from '../core/components/Modal';
import { CONFIRM_DELETING_PARAGRAPH_MODAL } from '../../constants/modal';

function ConfirmDeletingParagraph() {
  const dispatch = useDispatch();

  const { onConfirmDelete } = useSelector(state => state.modal.data);

  return (
    <Modal name={CONFIRM_DELETING_PARAGRAPH_MODAL}>
      <div className={styles.modal}>
        <span
          className={styles.title}
          style={{ textAlign: 'center' }}
        >
          Are you sure you want to delete this paragraph?
        </span>
        <div className={styles.buttons}>
          <Button
            actionName='no'
            action={() => dispatch(closeModal())}
            className={styles.greyButton}
          />
          <Button
            actionName='yes'
            className={styles.confirmButton}
            action={() => {
              onConfirmDelete();

              dispatch(closeModal());
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmDeletingParagraph;
