import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button } from '../../../core/components/Button/button';

function ToolCard({
  subtitle,
  title,
  disabled,
  primaryButtonName,
  primaryAction,
  icon,
}) {
  return (
    <div className={classNames(styles.card, { [styles.disabled]: disabled })}>
      <div className={styles.icon}>
        {icon}
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      <Button
        className={styles.actionButton}
        actionName={primaryButtonName}
        action={primaryAction}
        disabled={disabled}
      />
    </div>
  );
}

export default ToolCard;
