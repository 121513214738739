import React, { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import styles from './QuestionMark.module.scss';
import { QuestionMarkProps } from './interfaces';

const QuestionMark: React.FC<QuestionMarkProps> = ({
  tooltipContent,
}) => {
  const [moreInfoActive, setMoreInfoActive] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <button
        type='button'
        className={styles.questionMark}
        onClick={() => setMoreInfoActive(!moreInfoActive)}
        data-tooltip-id='tooltip'
        data-tooltip-content={tooltipContent}
      >
        ?
      </button>
      {(isMobile && moreInfoActive) && (
        <div className={styles.moreInfoMobile}>
          {tooltipContent}
        </div>
      )}
    </>
  );
};

export default QuestionMark;
