import React, { useEffect } from 'react';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Layout from '../../ContentCreationNew/Steps/Layout';
import Card from '../../../core/components/Card/Card';
import DataInput from '../../../core/components/DataInput/DataInput';
import Separator from '../../../core/components/Separator/Separator';
import {
  setActiveCard, setActiveStep, restorePreviousProgress, setPreviousProgressLoader, setStore,
} from '../../../../actions/content-refresh';
import Mixpanel from '../../../../util/Mixpanel';
import StepperFooter from '../../../Footer/StepperFooter/StepperFooter';
import { RootState } from '../../../../reducers';

const FirstStep = ({ form }) => {
  const dispatch = useDispatch();

  const {
    loader, article,
  } = useSelector((state: RootState) => state.contentRefresh);

  const location = useLocation();

  // Restore previous progress based on the continue_hash from the URL search params
  useEffect(() => {
    // Get the search parameters from the current URL
    const searchParams = new URLSearchParams(location.search);
    // Extract the continue_hash value from the search parameters
    const continueHash = searchParams.get('continue_hash');

    // If continueHash exists and isn't empty
    if (continueHash?.length) {
      // Restore the previous progress using the continueHash
      restorePreviousProgress(continueHash).then(response => {
        // Retrieve the progress data from the backend response
        const progress = response.data.data;

        // If there is either no Redux or Formik values in backend response, throw an error
        if (!progress.store || !progress.values) {
          throw new Error();
        }

        // Restore Redux from backend response
        dispatch(setStore(progress.store));

        // Restore Formik from backend response
        form.setValues(progress.values);
      }).catch(() => {
        // Add some error alert modal that will notify user
        // that the restore process did not complete, because of backend is down
      }).finally(() => {
        dispatch(setPreviousProgressLoader(false));
      });
    }
  }, []);

  return (
    <Layout columns={1}>
      <div>
        <Card
          isLoading={loader}
          loaderText='We&apos;re processing your data. This won&apos;t take long, so hang tight!'
          title='Add your input'
          content={(
            <Field
              name='input_source'
              component={DataInput}
            />
          )}
        />
        <Separator margin='40px 0' />
        <StepperFooter
          nextDisabled={
            !article
          }
          nextStep={() => {
            dispatch(setActiveStep(2));
            dispatch(setActiveCard(1));
            Mixpanel.track('Content Refresh - Add your input - Getting started');
          }}
          previousDisabled
          actionName='next step'
          footerRightIcon='arrowNext'
        />
      </div>
    </Layout>
  );
};

export default FirstStep;
