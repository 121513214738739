import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const clientDefault = axios.create({
  baseURL,
});

const client = {
  ...clientDefault,
  get: (endPoint, configs) => clientDefault.get(endPoint, configs),
  delete: (endPoint, configs) => clientDefault.delete(endPoint, configs),
  head: (endPoint, configs) => clientDefault.head(endPoint, configs),
  options: (endPoint, configs) => clientDefault.options(endPoint, configs),
  post: (endPoint, data, configs) => clientDefault.post(endPoint, data, configs),
  put: (endPoint, data, configs) => clientDefault.put(endPoint, data, configs),
  patch: (endPoint, data, configs) => clientDefault.patch(endPoint, data, configs),
};

const setToken = (authToken) => {
  client.defaults.headers.common.Authorization = `Bearer ${authToken}`;
};

const removeToken = () => {
  delete client.defaults.headers.common.Authorization;
};

export {
  client,
  setToken,
  removeToken,
};
