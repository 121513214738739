import React from 'react';
import styles from './styles.module.scss';
import renderIcon from '../../util/renderIcon';
import Modal from '../core/components/Modal';
import { SECTIONS_ERROR_MODAL } from '../../constants/modal';

function SectionsErrorModal() {
  return (
    <Modal name={SECTIONS_ERROR_MODAL}>
      <div className={styles.modal}>
        <div className={styles.image}>
          {renderIcon('sectionsError')}
        </div>
        <span className={styles.title}>
          Oops, something went wrong
        </span>
        <span className={styles.text}>
          We apologize for the inconvenience.
          <br />
          <br />
          To proceed, please add at least one heading to your text, so we can continue working together on your content.
        </span>
      </div>
    </Modal>
  );
}

export default SectionsErrorModal;
